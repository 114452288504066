import React from 'react';
import {createSlice} from '@reduxjs/toolkit'

interface forgeState {
    forgeModalState: number,
    forgeProcessModalState: number,
    forgeCongratsModalState: number,
    ownedNFTs: any[],
    approvePartStatus: boolean,
    approvePartStop: boolean,
    forgeStatus: boolean,
    forgeStop: boolean,
    fetchNFTStatus: boolean,
    transactionHash: string,
    selectedItems: any[],
    selectedItemsUri: any[],
    getForgeData: any[],
    forgeEmailModalStatus: boolean,
    forgeEmailConfirmStatus: boolean,
    getForgeFactoryData: any[],
    discountPassBalance: number
}

const initialState = {
    forgeModalState: 0,
    forgeProcessModalState: 0,
    forgeCongratsModalState: 0,
    ownedNFTs: [],
    approvePartStatus: false,
    approvePartStop: false,
    forgeStatus: false,
    forgeStop: false,
    fetchNFTStatus: false,
    transactionHash: '',
    selectedItems: [],
    selectedItemsUri: [],
    getForgeData: [],
    forgeEmailModalStatus: false,
    forgeEmailConfirmStatus: false,
    getForgeFactoryData: [],
    discountPassBalance: -1
} as forgeState

const forgeSlice = createSlice({
    name: 'forge page',
    initialState,
    reducers: {
        setModalState(state, action) {
            state.forgeModalState = action.payload;
        },
        setForgeProcessModalState(state, action) {
            state.forgeProcessModalState = action.payload;
        },
        setForgeCongratsModalState(state, action) {
            state.forgeCongratsModalState = action.payload;
        },
        setOwnedNFTs(state, action) {
            state.ownedNFTs = action.payload;
        },
        setApprovePartStatus(state, action) {
            state.approvePartStatus = action.payload;
        },
        setApprovePartStop(state, action) {
            state.approvePartStop = action.payload;
        },
        setForgeStatus(state, action) {
            state.forgeStatus = action.payload;
        },
        setForgeStop(state, action) {
            state.forgeStop = action.payload;
        },
        setFetchNFTStatus(state, action) {
            state.fetchNFTStatus = action.payload;
        },
        setTransactionHash(state, action) {
            state.transactionHash = action.payload;
        },
        setSelectedItems(state, action) {
            state.selectedItems = action.payload;
        },
        setSelectedItemsUri(state, action) {
            state.selectedItemsUri = action.payload;
        },
        setForgeData(state, action) {
            state.getForgeData = action.payload;
        },
        setForgeEmailModalStatus(state, action) {
            state.forgeEmailModalStatus = action.payload;
        },
        setForgeEmailConfirmStatus(state, action) {
            state.forgeEmailConfirmStatus = action.payload;
        },
        setForgeFactoryData(state, action) {
            state.getForgeFactoryData = action.payload;
        },
        setDiscountPassBalance(state, action) {
            state.discountPassBalance = action.payload;
        }
    }
})

export const {
    setModalState,
    setForgeProcessModalState,
    setForgeCongratsModalState,
    setOwnedNFTs,
    setApprovePartStatus,
    setApprovePartStop,
    setForgeStatus,
    setForgeStop,
    setFetchNFTStatus,
    setTransactionHash,
    setSelectedItems,
    setSelectedItemsUri,
    setForgeData,
    setForgeEmailModalStatus,
    setForgeEmailConfirmStatus,
    setForgeFactoryData,
    setDiscountPassBalance
} = forgeSlice.actions

export default forgeSlice.reducer