import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

export const ProgressSBC = (props: any) => {
    const progress = (sold: number, total: number) => {
        if (sold < total) {
            return Number(((sold / total) * 100).toFixed(2));
        } else {
            return 100;
        }
    }
    return (
        <div className="mt-4 w-full">
            <div className="flex items-center justify-between mb-1">
                <span className="simple-text-3 font-semibold">Progress</span>
            </div>
            <ProgressBar
                completed={progress(Number(props.promiseData[1]), Number(props.promiseData[0])) < 100 ? progress(Number(props.promiseData[1]), Number(props.promiseData[0])) : 100}
                bgColor={"#3674DD"}
                labelAlignment={"right"}
                baseBgColor={"rgba(54, 116, 221, 0.2)"}
            />
            <div className="flex items-center justify-between mt-1">
                <span
                    className=" text-white font-semibold">${(Number(props.promiseData[1]) / Number(props.promiseData[6])).toLocaleString()} USDC</span>
                <span
                    className="simple-text-3"><b
                    className="text-white">{Number(props.promiseData[1]).toLocaleString()}</b> / {Number(props.promiseData[0]).toLocaleString()} SBC</span>
            </div>
        </div>
    )
}