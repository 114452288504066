import React, {useEffect, useState} from 'react'
import "./..//AngelRound.css";

export const StatusButton = (props: any) => {

    return (<>
            {(props.presaleStart) > props.currentTime ?
                <span className="absolute top-6 right-6 px-6 uppercase nulshock-text status-btn">
                    Registration is open
                </span> : (Math.floor(new Date(props.promiseData[3]).getTime())> props.currentTime ?
                    <span className="absolute top-6 right-6 px-6 uppercase nulshock-text live-btn">
                        Live
                    </span> :
                    <span className="absolute top-6 right-6 px-6 uppercase nulshock-text end-btn">
                        Finished
                    </span>)
            }
        </>
    );
}