import React, {useEffect, useRef, useState} from "react";
import useOutsideClick from "../Drops/SubComponents/useOutsideClick";
import forgeImg from "../../assets/forge-mintpass.svg";
import mintPassImg from '../../assets/mintpass-gif.gif';
import freeClaimImg from '../../assets/freeclaim-gif.gif';
import discountPassImg from '../../assets/Discount_Pass.gif';
import forgeImgDisabled from "../../assets/forge-mintpass-disabled.svg";
import arrowLeftIcon from "../../assets/Arrow - Left 3.svg";
import {
    setForgeProcessModalState,
    setModalState,
    setSelectedItems,
    setSelectedItemsUri
} from "../../redux/reducer/forge";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {ForgeNFT} from "./ForgeNFT";
import {show} from "../../redux/reducer/notification";
import {useWeb3Functions} from "../../services/web3Functions";
import {HashLoader, ScaleLoader} from "react-spinners";
import {mintPass_addr} from "../contractAddress";
import {PassCart} from "./PassCart";

export const ForgeModal = () => {
    const dispatch = useAppDispatch();
    const refArea: any = useRef();
    const modalState = useAppSelector(state => state.forge.forgeModalState);
    const ownedNFTs = useAppSelector(state => state.forge.ownedNFTs);
    const fetchNFTStatus = useAppSelector(state => state.forge.fetchNFTStatus);
    const mintAddr = (process.env.REACT_APP_ERC721_ADDR as string);
    const mintPassAddr = mintPass_addr;
    // const mintAddr = '0x3ee6de45492c6559d92371343feea86d28c2f5a4';
    const selectedItems = useAppSelector(state => state.forge.selectedItems);
    const selectedItemsUri = useAppSelector(state => state.forge.selectedItemsUri);
    const getForgeData = useAppSelector(state => state.forge.getForgeData);
    const mintPassAmount = getForgeData[0] ? getForgeData[0] : 0;
    const freeClaimAmount = getForgeData[1] ? getForgeData[1] : 0;
    const getForgeFactoryData = useAppSelector(state => state.forge.getForgeFactoryData);
    const burnTokenId = getForgeFactoryData[0] ? getForgeFactoryData[0] : 0;
    const burnTokenAmount = getForgeFactoryData[1] ? getForgeFactoryData[1] : 5;
    const mintTokenId = getForgeFactoryData[2] ? getForgeFactoryData[2] : 2;
    const mintTokenAmount = getForgeFactoryData[3] ? getForgeFactoryData[3] : 1;
    const processModalState = useAppSelector(state => state.forge.forgeProcessModalState);
    const forgeCongratsModalState = useAppSelector(state => state.forge.forgeCongratsModalState);
    // const [selectedItems, setSelectedItems] = useState<any>([]);
    // const [selectedItemsUri, setSelectedItemsUri] = useState<any>([]);
    const [partStatus, setPartStatus] = useState(-1);
    const [passCards, setPassCards] = useState<any>([]);
    const limitItems = [mintPassAmount, freeClaimAmount, burnTokenAmount];
    const web3Functions = useWeb3Functions();
    // console.log(ownedNFTs);
    const nftsByCollection = ownedNFTs.filter((nft: any) => {
        const collectionAddr = nft.contract.address;
        return mintAddr.toUpperCase() === collectionAddr.toUpperCase();
    }).map((nft: any) => {
        // console.log(nft);
        return {
            tokenId: nft.tokenId ? nft.tokenId : '',
            tokenType: nft.tokenType ? nft.tokenType : '',
            tokenName: nft.rawMetadata.name ? nft.rawMetadata.name : '',
            tokenImage: nft.rawMetadata.image ? nft.rawMetadata.image : '',
            tokenTitle: nft.title ? nft.title : '',
            tokenUri: nft.tokenUri ? nft.tokenUri.raw : '',
            tokenTraitType: nft.rawMetadata.attributes[0] ? nft.rawMetadata.attributes[0].trait_type : '',
            tokenTraitValue: nft.rawMetadata.attributes[0] ? nft.rawMetadata.attributes[0].value : ''
        }
    }).sort((a: any, b: any) => a.tokenId < b.tokenId ? -1 : a.tokenId > b.tokenId ? 1 : 0);

    const nftsOfmintPass = ownedNFTs.filter((nft: any) => {
        const collectionAddr = nft.contract.address;
        return mintPassAddr.toUpperCase() === collectionAddr.toUpperCase();
    }).filter((nft: any) => {
        const tokenId = nft.tokenId;
        return burnTokenId == tokenId;
    }).sort((a: any, b: any) => a.tokenId < b.tokenId ? -1 : a.tokenId > b.tokenId ? 1 : 0);


    const closeEvent = () => {
        dispatch(setModalState(0));
        dispatch(setSelectedItems([]));
        dispatch(setSelectedItemsUri([]));
    }

    const ForgeButtonStatus = () => {

        if (selectedItems.length == limitItems[modalState - 1]) {
            return false;
        } else {
            return true;
        }
    }

    const addItem = (tokenId: number, tokenType: string, tokenName: string) => {
        if (selectedItems.length >= limitItems[modalState - 1]) {
            if (selectedItems.includes(tokenId)) {
                dispatch(setSelectedItems(selectedItems.filter((item: string) => item !== tokenId.toString())));
                dispatch(setSelectedItemsUri(selectedItemsUri.filter((item: string) => item != tokenName)));
            } else {
                dispatch(show({
                    title: "You can not select anymore!",
                    type: 'error'
                }))
            }
        } else {
            if (selectedItems.includes(tokenId)) {
                dispatch(setSelectedItems(selectedItems.filter((item: string) => item !== tokenId.toString())));
                dispatch(setSelectedItemsUri(selectedItemsUri.filter((item: string) => item != tokenName)));
            } else {
                if (modalState === 2 && selectedItemsUri.includes(tokenName)) {
                    dispatch(show({
                        title: "You can not select same Type!",
                        type: 'error'
                    }))
                } else {
                    dispatch(setSelectedItemsUri([...selectedItemsUri, tokenName]));
                    dispatch(setSelectedItems([...selectedItems, tokenId]));
                }
            }
        }
    }

    const addCard = (index: number) => {
        if (selectedItems.length >= limitItems[modalState - 1]) {
            if (selectedItems.includes(index)) {
                dispatch(setSelectedItems(selectedItems.filter((item: string) => item != index.toString())));
            } else {
                dispatch(show({
                    title: "You can not select anymore!",
                    type: 'error'
                }))
            }
        } else {
            if (selectedItems.includes(index)) {
                dispatch(setSelectedItems(selectedItems.filter((item: string) => item != index.toString())));
            } else {
                dispatch(setSelectedItems([...selectedItems, index]));
            }
        }
    }

    const overPart = (tokenId: number) => {
        setPartStatus(tokenId);
    }

    const leavePart = () => {
        setPartStatus(-1);
    }

    const clearAll = () => {
        dispatch(setSelectedItems([]));
        dispatch(setSelectedItemsUri([]));
    }
    const forgeProcess = async (forgeState: number) => {
        dispatch(setForgeProcessModalState(forgeState));
        if (forgeState == 1) {
            await web3Functions.partsApproveFunc();
            web3Functions.forgeMintPass(selectedItems);
        } else if (forgeState == 2) {
            await web3Functions.partsApproveFunc();
            web3Functions.forgeFreeClaim(selectedItems);
        } else {
            await web3Functions.approvePassFunc();
            web3Functions.forgeDiscount(burnTokenId, selectedItems.length, mintTokenId, mintTokenAmount);
        }
    }
    const passItems = (balance: number, nft: any) => {
        let passItem = [];
        for (let i = 0; i < balance; i++) {
            passItem.push(nft)
        }
        setPassCards(passItem);
    }

    useOutsideClick(refArea, () => {
        if (modalState !== 0) {
            dispatch(setModalState(0));
        }
    });

    useEffect(() => {
        if (modalState === 3) {
            web3Functions.getForgeFactoryData();
            if (Object.keys(nftsOfmintPass).length > 0) {
                passItems(nftsOfmintPass[0].balance, nftsOfmintPass[0]);
            }
        }

    }, [modalState, ownedNFTs])


    return (
        <div
            className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none ${((forgeCongratsModalState !== 0) || (processModalState != 0)) && 'blur-sm'}`}>
            <div
                className="relative sm:mx-auto max-w-4xl mx-2 my-6 max-h-[80vh] sm:h-[50rem] h-[46rem] rounded-lg p-4 ">
                {/*border-2 border-[#1F1822]*/}
                <div className="flex flex-col items-start text-center p-3 pt-0 rounded-t relative">
                    <div className={'flex flex-start'}>
                        <button
                            className="p-1 ml-auto mt-0 border-0 text-white float-right font-bold text-xl items-center flex flex-row justify-start outline-none focus:outline-none"
                            onClick={() => {
                                closeEvent();
                            }}
                        >
                            <img src={arrowLeftIcon} width={'w-4'} alt={'arrow-left'}/>
                            Back
                        </button>
                    </div>
                    <div className="w-full text-left mt-3">
                        <h3 className="sm:text-xl text-lg poppin-text text-white text-center gap-x-2 flex justify-start">
                            <img src={forgeImg} alt={'forge image'} className={'w-7'}/> Select to Forge
                            <img src={modalState === 1 ? mintPassImg : modalState === 2 ? freeClaimImg : discountPassImg}
                                 alt={'pass icon'}
                                 className={'w-7'}/>
                            <span
                                className={'text-zinc-400 '}> ({selectedItems.length}/{modalState === 1 ? mintPassAmount : modalState === 2 ? freeClaimAmount : burnTokenAmount})</span>
                        </h3>
                        <span className={`sm:text-[14px] text-[12px] text-[#767575] `}>
                            {modalState === 1 ? 'Please select ' + mintPassAmount + ' Stickers you currently own to forge a Mint Pass. (Same type of Stickers are allowed.)' : modalState === 2 ? 'Please select ' + freeClaimAmount + ' Stickers you currently own to forge a Free Claim Pass. (Same type of Stickers are NOT allowed. Each Sticker must belong to a unique category.)' : ''}
                        </span>
                    </div>
                </div>
                <div className={'p-1 px-3 flex justify-end border-t-2 border-[#A60C53]'}>
                    <button className={'text-[#767575] font-semibold '} onClick={() => clearAll()}>Clear All</button>
                </div>
                <div
                    className="relative px-3 py-1 pt-0 flex-auto poppin-text sm:w-[54rem] sm:h-[65%] h-[60%] overflow-auto relative">
                    {fetchNFTStatus ?
                        <div className="flex items-center justify-center mt-10 absolute top-[35%] right-[46%]">
                            {/*<HashLoader color={'#AD0F5B'} loading={true} size={50}/>*/}
                            <ScaleLoader color={'#AD0F5B'} height={50} width={5} radius={10} margin={4}
                                         speedMultiplier={1.3}/>
                        </div>
                        : modalState == 3 ?
                            Object.keys(nftsOfmintPass).length > 0 ?
                                <div
                                    className={'grid xl:grid-cols-7 md:grid-cols-6 sm:grid-cols-4 grid-cols-2 gap-3 '}
                                    id={'mint-pass-items'}>
                                    {passCards.map((nft: any, index: number) => (
                                        <PassCart nft={nft} addCard={addCard} key={index} index={index}
                                                  overPart={overPart} leavePart={leavePart} partStatus={partStatus}/>
                                    ))}
                                </div>
                                :
                                <div className="poppin-text w-full mt-5 text-[#767575] text-center">You don't have any
                                    Spinblade Pass for forging.</div>
                            :
                            Object.keys(nftsByCollection).length > 0 ?
                                <div
                                    className={'grid xl:grid-cols-7 md:grid-cols-6 sm:grid-cols-4 grid-cols-2 gap-3 '}>
                                    {nftsByCollection.map((nft: any, index: any) => (
                                        <ForgeNFT key={index} nft={nft} addItem={addItem}
                                                  overPart={overPart} leavePart={leavePart} partStatus={partStatus}/>
                                    ))}
                                </div> :
                                <div className="poppin-text w-full mt-5 text-[#767575] text-center">You don't have any
                                    Spinblade Pass for forging.</div>
                    }
                </div>
                <div className="mt-1 p-2 poppin-text justify-end flex">
                    <button
                        className={`poppin-text py-2 flex items-center text-2xl forge-btn px-20 ${ForgeButtonStatus() && 'disabled'}`}
                        onClick={() => forgeProcess(modalState)} disabled={ForgeButtonStatus()}>
                        <img src={ForgeButtonStatus() ? forgeImgDisabled : forgeImg} alt={'forge image'}
                             className={'w-10'}/> Forge
                    </button>
                </div>
            </div>
        </div>
    );
}