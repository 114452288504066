import React, {useState, useEffect} from "react";
import {AngelRound} from "../Components/AngelRound/AngelRound";
import {Navbar} from "../Components/Navbar/Navbar";

export const Presale = (props: any) => {

    return (
        <div>
            <Navbar currentAccount={props.currentAccount} connectWallet={props.connectWallet} setCurrentAccount={props.setCurrentAccount}
                    switchNetwork={props.switchNetwork} getContractData={props.getContractData} setCheckNetwork={props.setCheckNetwork}
                    promiseData={props.promiseData} checkNetwork={props.checkNetwork} noMetamask={props.noMetamask}
                    disconnectWallet={props.disconnectWallet}></Navbar>
            <AngelRound currentAccount={props.currentAccount} promiseData={props.promiseData} whitelistStatus={props.whitelistStatus}
                        presaleStart={props.presaleStart} orderStatus={props.orderStatus} approveStatus={props.approveStatus}
                        isEnded={props.isEnded} add_whitelist={props.add_whitelist} buy_SBC={props.buy_SBC}
                        approve_USDC={props.approve_USDC} getContractData={props.getContractData}
                        approveCheck={props.approveCheck} checkNetwork={props.checkNetwork} checkWhitelist={props.checkWhitelist}
                        switchNetwork={props.switchNetwork} SBC_Purchase_Func={props.SBC_Purchase_Func}
                        sbcPurchaseModal={props.sbcPurchaseModal} setSbcPurchaseModal={props.setSbcPurchaseModal}
                        sbcCongratsModal={props.sbcCongratsModal} setSbcCongratsModal={props.setSbcCongratsModal}
                        rejectStatus={props.rejectStatus} purchaseSensor={props.purchaseSensor} transactionHash={props.transactionHash} noMetamask={props.noMetamask}
            ></AngelRound>
        </div>
    )
}