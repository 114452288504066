import React, {useRef} from "react";
import firstEditionGif from "../../../assets/first-edition-gif.gif";
import useOutsideClick from "./useOutsideClick";
import Confetti from "react-confetti";
import {chainID} from "../../contractAddress";
import {useWeb3Functions} from "../../../services/web3Functions";

export const CongratsModal = (props: any) => {
    const refArea: any = useRef();
    const closeEvent = () => {
        props.setCongratsModal(false);
        props.setNftAmounts(1);
        props.setMintType(-1);
    }

    useOutsideClick(refArea, () => {
        if (props.congratsModal) {
            props.setCongratsModal(false);
            props.setNftAmounts(1);
            props.setMintType(-1);
        }
    });

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative relative 2xl:w-auto 2xl:mx-auto mx-2 my-6 max-w-md my-6 mx-auto ">
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none bg-modal rounded-2xl"
                        ref={refArea}>
                        {/*header*/}
                        <div
                            className="flex flex-col items-start text-center p-3 pt-0 rounded-t">
                            <button
                                className="p-1 ml-auto mt-0 border-0 text-black float-right text-2xl outline-none focus:outline-none"
                                onClick={() => {
                                    closeEvent();
                                }}
                            >
                                <span className="simple-text font-bold h-6 w-6 text-2xl">×</span>
                            </button>
                            <div className="w-full text-center">
                                <h3 className="2xl:text-4xl text-3xl poppin-text text-white text-center uppercase">
                                    Congratulations
                                </h3>
                            </div>
                        </div>
                        {/*body*/}
                        <div className="relative p-8 pt-4 flex-auto poppin-text">
                            <div className={"simple-text 2xl:text-base text-sm text-center mb-4"}>
                                You successfully bought {props.nftAmounts} First Edition Boxes
                            </div>
                            <div className={"w-full flex justify-center"}>
                                <img src={firstEditionGif} alt={"First Edition Box"} className={"w-[320px] 2xl:w-full rounded-md 2xl:rounded-xl"}/>
                            </div>
                            <div className={"flex flex-row justify-center poppin-text"}>
                                <a
                                    className="text-white poppin-text view-item-btn w-7/12 mt-5 py-2 flex items-center"
                                    href={"/assets"}>View Item</a>
                            </div>
                            <div className={"flex flex-col justify-center poppin-text text-center gap-3 mt-3"}>
                                <p className={"simple-text 2xl:text-base text-sm"}>You can check the transaction on
                                    {chainID == '0x89' ? 'Polygonscan' : 'etherscan'}</p>
                                <a className={"text-white 2xl:text-base text-sm text-center underline decoration-1"}
                                   href={`${chainID == '0x89' ? 'https://polygonscan.com/tx/' + props.transactionHash : 'https://goerli.etherscan.io/tx/' + props.transactionHash}`}
                                   target={'_blank'}>{props.transactionHash.slice(0, 10) + "..." + props.transactionHash.slice(-10)}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Confetti recycle={false} numberOfPieces={500}/>
            </div>
            <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}