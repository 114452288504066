import {initializeApp} from "firebase/app";
import {getDatabase, ref, set, get, child} from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCdbxz4MsSbKfMcf-Jvs4DzMUgeVJ99ASo",
    authDomain: "spinblade-vesting.firebaseapp.com",
    databaseURL: "https://spinblade-vesting-default-rtdb.firebaseio.com",
    projectId: "spinblade-vesting",
    storageBucket: "spinblade-vesting.appspot.com",
    messagingSenderId: "51073722877",
    appId: "1:51073722877:web:9f15816980eaa91930d194",
    measurementId: "G-HRGJFBEK2P"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const writeData = (address: string, amount: number, hash: string) => {
    set(ref(database, `Vesting-${Date.now()}/`), {
        address,
        amount,
        hash,
        timestamp: Date.now()
    }).then(() => {
        console.log('data saved')
    }).catch(e => console.log('write-ex', e.message))
}

const readData = async (address: string) => {
    return new Promise(resolve => {
        const dbRef = ref(database)
        let historyData:any = [];
        get(child(dbRef, `/`))
            .then(snapshot => {
                if (snapshot.exists()) {
                    snapshot.forEach(child => {

                        if (child.val().address.toLowerCase() == address.toLowerCase()) {
                            historyData.push(child.val())
                        }
                    })
                    resolve(historyData);

                } else {
                    console.log("No data available")
                    resolve(null)
                }
            })
            .catch(e => {
                console.log("read-ex", e.message)
                resolve(undefined)
            })
    })
}

export default {
    writeData,
    readData
}