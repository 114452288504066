import {Navbar} from "../Components/Navbar/Navbar";
import {NotFoundComponent} from "../Components/404/notFound";

export const NotFound = (props) => {
    return <>
        <Navbar currentAccount={props.currentAccount}
                setCurrentAccount={props.setCurrentAccount}
                connectWallet={props.connectWallet}
                setCheckNetwork={props.setCheckNetwork}
                promiseData={props.promiseData}
                switchNetwork={props.switchNetwork} checkNetwork={props.checkNetwork}
                disconnectWallet={props.disconnectWallet}/>
        <NotFoundComponent />
    </>
}