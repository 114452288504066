import React, {useEffect, useState} from 'react'
import NumberFormat from 'react-number-format';
import "../Drops.css"
import maticIcon from "../../../assets/Polygon.png";
import freeClaimCard from '../../../assets/freeclaim-gif.gif';
import mintPassCard from '../../../assets/mintpass-gif.gif';
import {PurchaseModal} from "./PurchaseModal";
import {CongratsModal} from "./CongratsModal";
import {useAppSelector} from "../../../hooks";
import {firstEdition_addr, mintPass_addr} from "../../contractAddress";
import {ethers} from "ethers";
import firstEdition_abi from "../../../utils/firstEditionAbi.json";
import BigNumber from "bignumber.js";
import {NotifyComponent} from "./NotifyComponent";
import {Timer} from "./Timer";

let firstEditionBox: any;

export const LeftSide = (props: any) => {
    let [loading, setLoading] = useState(true);
    const [nftAmounts, setNftAmounts] = useState(1);
    const [nftPrice, setNFTPrice] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [plusButton, setPlusButton] = useState('+');
    const [mintMaxAmount, setMintMaxAmount] = useState(0);
    const [NFTPriceLength, setNFTPriceLength] = useState(0);
    const [discountPercent, setDiscountPercent] = useState<number>(100);

    const [availableCheck, setAvailableCheck] = useState(false);

    const ownedNFTs = useAppSelector(state => state.forge.ownedNFTs);
    const mintAddr = mintPass_addr;

    const nftsByCollection = ownedNFTs.filter((nft: any) => {
        const collectionAddr = nft.contract.address;
        return mintAddr.toUpperCase() === collectionAddr.toUpperCase();
    }).map((nft: any) => {
        return {nft}
    }).sort((a: any, b: any) => a.tokenId < b.tokenId ? -1 : a.tokenId > b.tokenId ? 1 : 0);

    const calcDiscountPercent = async () => {
        const {ethereum} = (window as any);
        if (props.mintType !== -1) {
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                firstEditionBox = new ethers.Contract(firstEdition_addr, firstEdition_abi, signer);

                // let discountByMintType = new BigNumber(Number(await firstEditionBox.mintTypes(props.mintType))).dividedBy(100).toFixed(0);
                let discountByMintType = [80,0, 50];
                setDiscountPercent(Number(discountByMintType[props.mintType]));
                setNFTPrice(props.mintData[3]);
                setDiscountedPrice(props.mintData[3] * Number(discountByMintType[props.mintType]) / 100);
            }
        } else {
            setDiscountPercent(100);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (Date.parse(props.mintData[6]) > 0) {
                if (props.publicPeriod) {
                    if (Number(props.mintData[2]) !== Number(props.mintData[12])) {
                        setAvailableCheck(true);
                    }
                }
                if (props.privatePeriod) {
                    if (props.mintType !== -1) {
                        if (Object.keys(nftsByCollection).length > 0) {
                            setAvailableCheck(true);
                        }
                    } else {
                        setAvailableCheck(false);
                    }
                }
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [props.mintData, props.mintType])

    const decreaseNFTAmount = () => {
        const onePrice = props.mintData[3];

        if (nftAmounts > 1) {
            setPlusButton('+');
            if (props.mintType !== -1) {
                if (discountPercent > 0) {
                    setNFTPriceLength((onePrice * discountPercent * (nftAmounts - 1) / 100).toString().length);
                } else {
                    setNFTPriceLength(1);
                }
            } else {
                setNFTPriceLength((onePrice * (nftAmounts - 1)).toString().length);
            }

            setNFTPrice(onePrice * (nftAmounts - 1));
            setDiscountedPrice(onePrice * (nftAmounts - 1) * discountPercent / 100);
            setNftAmounts(nftAmounts - 1);
        }
    }

    const increaseNFTAmount = () => {

        const onePrice = props.mintData[3];
        if (nftAmounts + 1 <= mintMaxAmount) {
            if (props.mintType !== -1) {
                if (discountPercent > 0) {
                    console.log(nftAmounts)
                    setNFTPriceLength((onePrice * discountPercent * (nftAmounts + 1) / 100).toString().length);
                } else {
                    setNFTPriceLength(1);
                }
            } else {
                setNFTPriceLength((onePrice * (nftAmounts + 1)).toString().length);
            }
            // switch (props.mintType) {
            //     case 0:
            //         setNFTPriceLength((onePrice * (nftAmounts + 1)).toString().length);
            //         break;
            //     case 1:
            //         setNFTPriceLength(1);
            //         break;
            //     case 2:
            //         setNFTPriceLength((onePrice * 0.8 * (nftAmounts + 1)).toString().length);
            //         break;
            // }

            setNFTPrice(onePrice * (nftAmounts + 1));
            setDiscountedPrice(onePrice * (nftAmounts + 1) * discountPercent / 100);
            setNftAmounts(nftAmounts + 1);
        }
        if (nftAmounts + 1 >= mintMaxAmount) {
            setPlusButton('max');
        }
    }

    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0');
    }

    const leftDays = () => {
        let leftDay: any;
        if (props.privatePeriod) {
            leftDay = (Date.parse(props.mintData[7]) - props.currentTime) / 86400000;
        }
        if (props.publicPeriod) {
            leftDay = (Date.parse(props.mintData[6]) - props.currentTime) / 86400000;
        }
        if (props.beforeStart) {
            if (props.currentTime < Date.parse(props.mintData[7])) {
                leftDay = (Date.parse(props.mintData[7]) - props.currentTime) / 86400000;
            }
            if (props.currentTime > Date.parse(props.mintData[7]) + props.mintData[9] && props.currentTime < Date.parse(props.mintData[6])) {
                leftDay = (Date.parse(props.mintData[6]) - props.currentTime) / 86400000;
            }
        }

        if (leftDay >= 1 && leftDay < 30) {
            return `${leftDay.toFixed(0)} day(s)`;
        } else if (0 < leftDay && leftDay < 1) {

            let milliseconds: any;
            if (props.privatePeriod) {
                milliseconds = (Date.parse(props.mintData[7]) - props.currentTime) / 1000;
            }
            if (props.publicPeriod) {
                milliseconds = (Date.parse(props.mintData[6]) - props.currentTime) / 1000;
            }
            if (props.beforeStart) {
                if (props.currentTime < Date.parse(props.mintData[7])) {
                    milliseconds = (Date.parse(props.mintData[7]) - props.currentTime) / 1000;
                }
                if (props.currentTime > Date.parse(props.mintData[7]) + props.mintData[9] && props.currentTime < Date.parse(props.mintData[6])) {
                    milliseconds = (Date.parse(props.mintData[6]) - props.currentTime) / 1000;
                }
            }

            let seconds = Math.floor(milliseconds);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;
            minutes = minutes % 60;
            hours = hours % 24;
            if (hours >= 1) {
                return `${padTo2Digits(hours)} hour(s)`;
            }
            if (minutes >= 1 && hours < 1) {
                return `${padTo2Digits(minutes)} min(s)`;
            }
            if (seconds > 0 && minutes < 1) {
                return `${padTo2Digits(seconds)} sec(s)`;
            }

        } else {
            return 0;
        }
    }

    useEffect(() => {
        if (props.noMetamask) {
            setLoading(false);
        }
    }, [props.noMetamask])

    useEffect(() => {
        if (props.mintData.length > 0) {
            setNFTPrice(nftAmounts * props.mintData[3]);
            setDiscountedPrice(Number(props.mintData[3]) * nftAmounts * discountPercent / 100);
            // setNftAmounts(1);

            if (props.isEnded) {
                setAvailableCheck(false);
            }

            if (props.currentAccount) {
                if (props.mintType === -1) {
                    if (props.publicPeriod && Number(props.mintData[2]) !== Number(props.mintData[12])) {
                        setAvailableCheck(true);
                    } else {
                        setAvailableCheck(false)
                    }
                    setMintMaxAmount(Number(props.mintData[2]) - Number(props.mintData[12]));
                } else {
                    if (Object.keys(nftsByCollection).length > 0) {
                        const tokens = nftsByCollection.filter((nft: any) => {
                            return nft.nft.tokenId === props.mintType;
                        })
                        if (Object.keys(tokens).length > 0) {
                            setAvailableCheck(true);
                        } else {
                            setAvailableCheck(false)
                        }
                        setMintMaxAmount(tokens[0].nft.balance);
                    } else {
                        setAvailableCheck(false)
                    }
                }

                if (Number(props.mintData[0]) > 0) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000);

                    if (mintMaxAmount === 1) {
                        setPlusButton('max');
                    }
                }

            } else {
                if (Number(props.mintData[0]) > 0) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000);

                    if (mintMaxAmount === 1) {
                        setPlusButton('max');
                    }
                }
            }

        }
    }, [props.mintData, props.publicPeriod, props.privatePeriod]);


    useEffect(() => {
        calcDiscountPercent();
        setPlusButton('+');
        setNftAmounts(1);


        if (props.isEnded) {
            setAvailableCheck(false);
        }

        if (props.mintType === -1) {
            if (props.publicPeriod && Number(props.mintData[2]) !== Number(props.mintData[12])) {
                setAvailableCheck(true);
            } else {
                setAvailableCheck(false)
            }
            setMintMaxAmount(Number(props.mintData[2]) - Number(props.mintData[12]));
        } else {
            if (Object.keys(nftsByCollection).length > 0) {
                const tokens = nftsByCollection.filter((nft: any) => {
                    return nft.nft.tokenId === props.mintType;
                })
                if (props.privatePeriod || props.publicPeriod) {

                    if (Object.keys(tokens).length > 0) {
                        setAvailableCheck(true);
                    } else {
                        setAvailableCheck(false)
                    }
                }
                setMintMaxAmount(tokens[0].nft?.balance);

            } else {
                setAvailableCheck(false)
            }
        }

    }, [props.mintType])

    return (
        <>
            {!loading && (
                <div className={`col-span-12 w-full relative sm:hidden`}>
                    <div
                        className={`col-span-5 bg_block relative flex items-center w-full justify-center h-40 sm:h-48 2xl:h-56 px-4 sm:px-12 2xl:px-20 drops-right-top`}
                    >
                        <Timer mintData={props.mintData} currentTime={props.currentTime}
                               publicPeriod={props.publicPeriod}
                               privatePeriod={props.privatePeriod} beforeStart={props.beforeStart}
                               getMintData={props.getMintData}/>
                    </div>
                    {props.currentTime < Date.parse(props.mintData[7]) && (
                        <NotifyComponent/>
                    )}
                </div>
            )}

            <div
                className={`col-span-12 align-center lg:col-span-3 bg_block 2xl:p-8 xl:p-6 p-4 drops-left max-w-left  mx-auto 2xl:w-full xl:w-11/12 w-full`}
            >
                <>
                    {
                        loading ? (
                            <div
                                className="flex flex-col items-center justify-center drops-loading animation-custom">
                                <div
                                    className={`bg-skeleton w-full ${props.currentAccount && props.checkNetwork === 1 ? 'h-connected' : 'h-disconnected'} rounded-xl `}></div>
                                {props.currentAccount && props.checkNetwork === 1 ?
                                    <>
                                        <div className={`h-1/12 w-full grid grid-cols-5 gap-4 mt-5`}>
                                            <div className=" w-full h-10 bg-skeleton rounded-lg col-span-2"></div>
                                            <div className=" w-full h-10 bg-skeleton rounded-lg col-span-3"></div>
                                        </div>
                                        <div className={`bg-skeleton w-full h-16 rounded-xl mt-5`}></div>
                                    </> :
                                    <div className={`bg-skeleton w-full h-16 rounded-xl mt-5`}></div>
                                }
                            </div>) : (<div className="flex flex-col items-center gap-x-6 pb-3 xl:pb-0 2xl:pb-3">
                            <div
                                className={`${props.currentAccount && props.checkNetwork === 1 && !props.isEnded ? 'first-edition-box-1 ' : 'first-edition-box '} p-4 mb-4 xl:mb-1 2xl:mb-4 flex justify-between flex-row `}>
                                <div className="flex flex-col ">
                                <span
                                    className="nulshock-text text-white font-bold text-3xl xl:text-base 2xl:text-3xl uppercase"> Drop #{props.mintData[5]}</span>
                                    <span
                                        className="poppin-text simple-text text-sm"><b
                                        className="text-white font-bold">{props.mintData[1]}</b> in sale from {props.mintData[4]}</span>
                                </div>
                                <div className="flex flex-col text-right">
                            <span
                                className={`${props.beforeStart ? 'text-pink' : props.isEnded ? 'text-navy' : (Number(props.mintData[0]) > 0 && props.privatePeriod) ? 'text-purple' : (Number(props.mintData[0]) > 0 && props.publicPeriod) ? 'text-orange' : 'text-red'} poppin-text text-sm 2xl:text-lg `}>{props.beforeStart ? 'Starts in' : (Number(props.mintData[0]) > 0 && props.privatePeriod) ? 'Early Access' : (Number(props.mintData[0]) > 0 && props.publicPeriod) ? 'On Sale' : Number(props.mintData[0]) < 1 ? 'Sold Out' : 'Ended'}</span>
                                    <span
                                        className="text-white poppin-text text-sm 2xl:text-lg">{props.beforeStart ? leftDays() : (Number(props.mintData[0]) > 0 ? Number(props.mintData[0]) : 0) + '/' + (Number(props.mintData[1]) > 0 ? Number(props.mintData[1]) : 0)}</span>

                                </div>
                                {props.mintType !== -1 && (
                                    <span className={`flex flex-col clipped-card poppin-text`}>
                                    {nftsByCollection.map((nft: any, index: any) => (
                                        <div className={`${nft.nft.tokenId == props.mintType ? 'block' : 'hidden'}`}
                                             key={index}>
                                            <img src={nft.nft.rawMetadata.image ? nft.nft.rawMetadata.image : ''}
                                                 alt={`mintPass Card`} className={``}/>
                                            <span
                                                className={`simple-text xl:text-[12px] 2xl:text-base text-base truncate`}>&nbsp;{nft.nft.title.split(" - ")[1]}</span>
                                        </div>
                                    ))}
                                        {/*{(props.mintType === 1 && props.mintData[10] > 0) && (*/}
                                        {/*    <>*/}
                                        {/*        <img src={mintPassCard} alt={`mintPass Card`} className={``}/>*/}
                                        {/*        <span className={`simple-text sm:text-base text-sm`}>&nbsp;Mint Pass</span>*/}
                                        {/*    </>*/}
                                        {/*)}*/}
                                        {/*    {(props.mintType === 0 && props.mintData[11] > 0) && (*/}
                                        {/*        <>*/}
                                        {/*            <img src={freeClaimCard} alt={`freeClaim Card`}/>*/}
                                        {/*            <span className={`simple-text sm:text-base text-sm`}>Free Claim</span>*/}
                                        {/*        </>*/}
                                        {/*    )}*/}
                                </span>
                                )}
                                <div
                                    className="flex flex-col box-title bottom text-white poppin-text text-2xl xl:text-xl 2xl:text-2xl">
                                    First Edition Box
                                </div>
                            </div>

                            <div className="simple-text flex flex-row justify-between w-full text-center text-lg">
                                {props.currentAccount && props.checkNetwork === 1 && !props.isEnded && (
                                    Number(props.mintData[0]) > 0 ?
                                        <>
                                            <div
                                                className={`nft-amounts w-2/5 sm:w-4/12 flex justify-between flex-row mt-5 z-10`}>
                                                <button className={`w-1/3 text-sm`} onClick={decreaseNFTAmount}>-
                                                </button>
                                                <input
                                                    type={"number"}
                                                    placeholder="0.0"
                                                    value={nftAmounts > 0 ? nftAmounts : 1} onChange={(e: any) => {
                                                    setNftAmounts(e.target.value);
                                                }}
                                                    className="bg-transparent input w-1/3 outline-none shadow-none text-white p-0 text-center text-xl xl:text-lg 2xl:text-xl font-bold py-1"
                                                    id="nft-input"
                                                />
                                                <button className={`w-1/3 text-sm`}
                                                        onClick={increaseNFTAmount}>{plusButton}</button>
                                            </div>
                                            <div
                                                className={`nft-price text-white flex flex-col ${NFTPriceLength === 4 ? 'w-48' : 'w-40'}`}>
                                                {props.mintType !== -1 && (
                                                    <span className={`original-nft-price simple-text text-right`}>
                                                <NumberFormat value={nftPrice} displayType={'text'}
                                                              decimalScale={2}
                                                              fixedDecimalScale={true}
                                                              thousandSeparator={true}/>
                                                </span>
                                                )}

                                                <div className={`flex flex-row justify-end gap-x-2`}>
                                                    <img src={maticIcon} alt="maticIcon"
                                                         className={`w-10 xl:w-8 2xl:w-10 ${props.mintType === -1 ? 'mt-7' : ''}`}/>

                                                    <span
                                                        className={`discounted-nft-price text-white text-3xl xl:text-2xl 2xl:text-3xl  ${props.mintType === -1 ? 'mt-7' : ''}`}>

                                                    {props.mintType === -1 ? (
                                                        <NumberFormat value={nftPrice} displayType={'text'}
                                                                      decimalScale={2}
                                                                      fixedDecimalScale={true}
                                                                      thousandSeparator={true}/>
                                                    ) : (
                                                        discountPercent > 0 ? (
                                                            <NumberFormat value={discountedPrice} displayType={'text'}
                                                                          decimalScale={2}
                                                                          fixedDecimalScale={true}
                                                                          thousandSeparator={true}/>
                                                        ) : (
                                                            'FREE'
                                                        )
                                                    )}
                                                </span>
                                                </div>
                                            </div>
                                        </> :
                                        <></>
                                )}
                            </div>

                            <div className="flex flex-row w-full">
                                {!props.currentAccount && (
                                    <button
                                        className="text-white nulshock-text vesting-connect-btn text-xl sm:text-base 2xl:text-xl w-full mt-5 py-4 flex items-center"
                                        onClick={props.connectWallet}>
                                        Connect Wallet
                                    </button>
                                )}

                                {props.currentAccount && props.checkNetwork !== 1 && (
                                    <button
                                        className="nulshock-text vesting-switch-btn text-xl sm:text-base 2xl:text-xl w-full mt-3 py-4 flex items-center justify-between flex-col"
                                        onClick={props.switchNetwork}>
                                        Switch Network
                                    </button>
                                )}

                                {props.currentAccount && props.checkNetwork === 1 && (
                                    Number(props.mintData[0]) > 0 ? (
                                            !availableCheck ?
                                                <button
                                                    className="btn-disabled text-3xl sm:text-2xl 2xl:text-3xl w-full poppin-text mt-5 py-3 z-10 flex items-center justify-between flex-col"
                                                    disabled={true}
                                                >Not Available
                                                </button> :
                                                <button
                                                    className="text-white poppin-text purchase-btn text-3xl sm:text-2xl 2xl:text-3xl w-full mt-5 py-3 flex items-center"
                                                    onClick={() => {
                                                        props.mintNFTFunc(nftAmounts, props.mintType)
                                                    }}
                                                    disabled={!availableCheck}
                                                    // onClick={() => setPendingModal(true)}
                                                >
                                                    Purchase
                                                </button>
                                        ) :
                                        (<button
                                                className="text-white text-3xl sm:text-2xl 2xl:text-3xl poppin-text sold-out-btn w-full mt-5 py-3 flex items-center"
                                                disabled={true}>
                                                Sold Out
                                            </button>
                                        )

                                )}
                            </div>

                        </div>)
                    }
                </>
                {props.pendingModal ? (
                    <PurchaseModal pendingModal={props.pendingModal} nftAmounts={nftAmounts}
                                   discountedPrice={discountedPrice}
                                   nftPrice={nftPrice} mintType={props.mintType} setPendingModal={props.setPendingModal}
                                   approveUSDCCheck={props.approveUSDCCheck} approvePassCheck={props.approvePassCheck}
                                   mintStatus={props.mintStatus} approve_MintPass={props.approve_MintPass}
                                   approveUSDCStatus={props.approveUSDCStatus}
                                   approvePassStatus={props.approvePassStatus}
                                   rejectStatus={props.rejectStatus} mintNFTFunc={props.mintNFTFunc}
                                   mintSensor={props.mintSensor} publicPeriod={props.publicPeriod}
                                   nftsByCollection={nftsByCollection}
                                   discountPercent={discountPercent}
                    />
                ) : null}
                {
                    props.congratsModal ? (
                        <CongratsModal setCongratsModal={props.setCongratsModal} nftAmounts={nftAmounts}
                                       transactionHash={props.transactionHash} congratsModal={props.congratsModal}
                                       setNftAmounts={setNftAmounts} setMintType={props.setMintType}/>
                    ) : null
                }
            </div>
        </>


    );
}