import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../hooks";
import {Navbar} from "../Components/Navbar/Navbar";
import {DropsComponent} from "../Components/Drops/DropsComponent";
import {create} from 'apisauce';
import {
    usdc_addr,
    mintPass_addr,
    firstEdition_addr,
    multiSigAdmin_addr,
    rpc_url
} from '../Components/contractAddress';
import {ethers} from "ethers";
import BigNumber from "bignumber.js";
import usdc_abi from "../utils/USDCabi.json";
import mintPass_abi from "../utils/mintPassAbi.json";
import firstEdition_abi from "../utils/firstEditionAbi.json";
import {show} from "../redux/reducer/notification";
import {setWalletAddress} from "../redux/reducer/wallet";
import {EmailConfirm} from "../Components/ForgeComponent/EmailConfirm";
import {MailComponent} from "../Components/ForgeComponent/MailComponent";
import {useWeb3Functions} from "../services/web3Functions";

let USDCPortal: any;
let mintPassPortal: any;
let firstEditionBox: any;

export const Drops = (props: any) => {
    const dispatch = useAppDispatch();
    const web3Functions = useWeb3Functions();

    const [mintData, setMintData] = useState([]);
    const [mintType, setMintType] = useState(-1);
    const [approveUSDCCheck, setApproveUSDCCheck] = useState(1);
    const [approvePassCheck, setApprovePassCheck] = useState(0);
    let [approveUSDCStatus, setApproveUSDCStatus] = useState(false);
    let [approvePassStatus, setApprovePassStatus] = useState(false);
    let [mintStatus, setMintStatus] = useState(false);
    const [publicPeriod, setPublicPeriod] = useState(false);
    const [privatePeriod, setPrivatePeriod] = useState(false);
    const [beforeStart, setBeforeStart] = useState(false);
    const [isEnded, setIsEnded] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date().getTime());
    const [pendingModal, setPendingModal] = useState(false);
    const [congratsModal, setCongratsModal] = useState(false);
    const [rejectStatus, setRejectStatus] = useState(0);
    const [transactionHash, setTransactionHash] = useState('');
    const [mintSensor, setMintSensor] = useState(false);
    const forgeEmailModalStatus = useAppSelector(state => state.forge.forgeEmailModalStatus);
    const forgeEmailConfirmStatus = useAppSelector(state => state.forge.forgeEmailConfirmStatus);
    const MaticBalance = useAppSelector(state => state.wallet.balance);

    const api = create({
        baseURL: 'https://discord.com/api/webhooks/1088398163993448488/ahEs9_B72drUyD45XpfdG_mYkZ4qiagAUYvAP5_l0IGin_X2otEpHGoIoOB3TK_DUFV5',
        headers: {
            'Content-type': 'application/json'
        }
    })

    const embed = (tokenId: number, address: string, amount: number) => {
        return {
            "username": "Spinblade",
            "avatar_url": "https://i.ibb.co/HYWqgGr/1-1.png",
            "embeds": [{
                "color": 16705372,
                "description": "[🎉️ Congrats! " + address.substr(0, 5) + "...." + address.substr(-5) + " minted!](https://opensea.io/assets/MATIC/" + firstEdition_addr + "/" + tokenId + ") \n \n ** First Edition Box 📦x" + amount + "**",
                "image": {
                    "url": 'https://gateway.pinata.cloud/ipfs/QmeYK5gWtacQg6y6rrqnSJE3QK5WfiCoRDfn3c6DXNL8cS'
                }
            }]
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().getTime())
            const current = new Date().getTime() / 1000;
            if (Date.parse(mintData[6]) > 0) {
                if (current > Date.parse(mintData[6]) / 1000 && current <= Date.parse(mintData[6]) / 1000 + mintData[8]) {
                    setPrivatePeriod(false);
                    setBeforeStart(false);
                    setIsEnded(false);
                    setPublicPeriod(true);
                }
                if (current > Date.parse(mintData[7]) / 1000 && current <= Date.parse(mintData[7]) / 1000 + mintData[9]) {
                    setBeforeStart(false);
                    setPublicPeriod(false);
                    setIsEnded(false);
                    setPrivatePeriod(true);
                }
                if (current < Date.parse(mintData[7]) / 1000) {
                    setPrivatePeriod(false);
                    setPublicPeriod(false);
                    setIsEnded(false);
                    setBeforeStart(true);
                }
                if (current > Date.parse(mintData[6]) / 1000 + mintData[8]) {
                    setPrivatePeriod(false);
                    setPublicPeriod(false);
                    setBeforeStart(false);
                    setIsEnded(true);
                }
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [mintData, mintType]);

    useEffect(() => {
        getMintData();
    }, []);

    useEffect(() => {
        const fetchMintData = async () => {
            await getMintData();
        }
        dispatch(setWalletAddress(props.currentAccount));
        fetchMintData().catch(console.error);
    }, [props.currentAccount]);

    const mintTypeUpdate = (type: number) => {
        if (mintType === type) {
            setMintType(-1)
        } else {
            setMintType(type);
        }
    }

    const mintNFTFunc = async (amounts: number, mintType: number) => {

        const {ethereum} = (window as any);
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            firstEditionBox = new ethers.Contract(firstEdition_addr, firstEdition_abi, signer);
            setRejectStatus(0);
            setPendingModal(true);
            if (mintType !== -1) {
                let discountByMintType = new BigNumber(Number(await firstEditionBox.mintTypes(mintType))).dividedBy(100).toFixed(0);
                let discountPercent = Number(discountByMintType);

                if (discountPercent > 0) {
                    // if (approveUSDCCheck === 0) {
                    //     await approve_USDC_Drop();
                    // }
                    if (approvePassCheck === 0) {
                        await approve_MintPass();
                    }
                    if (approvePassCheck !== 0) {
                        // setApprovePassCheck(2);
                        await mintNFT(amounts);
                    }
                } else {
                    if (approvePassCheck === 0) {
                        await approve_MintPass();
                    }
                    if (approvePassCheck !== 0) {
                        // setApprovePassCheck(2);
                        await mintNFT(amounts);
                    }
                }
            } else {
                // if (approveUSDCCheck === 0) {
                //     await approve_USDC_Drop();
                // }
                // if (approveUSDCCheck === 1) {
                await mintNFT(amounts);
                // }
            }

        }
    }

    const mintNFT = async (amounts: number) => {
        const {ethereum} = (window as any);

        if (ethereum) {
            setMintSensor(true);
            setMintStatus(true);
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();

            USDCPortal = new ethers.Contract(usdc_addr, usdc_abi, signer);
            mintPassPortal = new ethers.Contract(mintPass_addr, mintPass_abi, signer);
            firstEditionBox = new ethers.Contract(firstEdition_addr, firstEdition_abi, signer);
            let Amount;

            let balanceUSDC_ = await USDCPortal.balanceOf(props.currentAccount);
            let balanceUSDC = new BigNumber(Number(balanceUSDC_)).dividedBy(10 ** 18).toFixed(0);

            const firstEditionID = 0;
            let mintPassResult;
            try {
                if (mintType === -1) {
                    Amount = Number(mintData[3]) * amounts;
                    if (Number(MaticBalance) < Amount) {
                        dispatch(show({
                            title: "Balance Error!",
                            body: "Inputted amount exceed your USDC balance",
                            type: 'error'
                        }))
                        setMintStatus(false);
                        return;
                    }
                    let overrides = {
                        value: ethers.utils.parseEther(Amount.toString())
                    }
                    let mint = await firstEditionBox.mint(props.currentAccount, amounts, firstEditionID, overrides);
                    mintPassResult = await mint.wait();
                    setTransactionHash(mintPassResult.transactionHash);
                    api.post('', embed(firstEditionID, props.currentAccount, amounts)).then(
                        res => {
                            console.log('notification', res);
                        }
                    );
                } else {

                    let discountByMintType = new BigNumber(Number(await firstEditionBox.mintTypes(mintType))).dividedBy(100).toFixed(0);
                    let discountPercent = Number(discountByMintType);
                    if (discountPercent > 0) {
                        Amount = Number(mintData[3]) * amounts * discountPercent / 100;
                        if (Number(MaticBalance) < Amount) {
                            dispatch(show({
                                title: "Balance Error!",
                                body: "Amount exceed your USDC balance",
                                type: 'error'
                            }))
                            setMintStatus(false);
                            return;
                        }
                        let overrides = {
                            value: ethers.utils.parseEther(Amount.toString())
                        }
                        let mintPass = await firstEditionBox.mintTool(amounts, mintType, firstEditionID, overrides);
                        mintPassResult = await mintPass.wait();
                        api.post('', embed(firstEditionID, props.currentAccount, amounts)).then(
                            res => {
                                console.log('notification', res);
                            }
                        );
                        setTransactionHash(mintPassResult.transactionHash);
                    } else {
                        let freeClaim = await firstEditionBox.mintTool(amounts, mintType, firstEditionID);
                        mintPassResult = await freeClaim.wait();
                        api.post('', embed(firstEditionID, props.currentAccount, amounts)).then(
                            res => {
                                console.log('notification', res);
                            }
                        );
                        setTransactionHash(mintPassResult.transactionHash);
                    }
                }


                if (mintPassResult) {
                    await getMintData();
                    setMintStatus(false);
                    setPendingModal(false);
                    setCongratsModal(true);
                    web3Functions.FetchNFTs();
                    dispatch(show({
                        title: "Order Confirmed!",
                        body: "You bought " + amounts + " First Edition Box",
                        type: 'success'
                    }))
                }

            } catch (e: any) {
                console.log(e);
                dispatch(show({
                    title: "Purchase request rejected!",
                    body: "You can continue by clicking 'Continue' button",
                    type: 'warning'
                }));
                setRejectStatus(3);
            }
        }
    }

    const approve_USDC_Drop = async () => {
        const {ethereum} = (window as any);

        if (ethereum) {
            setApproveUSDCStatus(true);
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();

            USDCPortal = new ethers.Contract(usdc_addr, usdc_abi, signer);
            let maxUint = new BigNumber(Number(ethers.constants.MaxUint256)).dividedBy(10 ** 18).toFixed(0);
            try {
                let approved = await USDCPortal.approve(firstEdition_addr, maxUint);
                await approved.wait();
                if (approved) {
                    await getMintData();
                    setApproveUSDCStatus(false);
                    setPendingModal(true);
                    // dispatch(show({
                    //     title: "USDC Approved!",
                    //     body: "Transaction Hash : " + approved.hash,
                    //     type: 'success'
                    // }))
                }
            } catch (e: any) {
                dispatch(show({
                    title: "Currency Approve Rejected!",
                    body: "You can continue by clicking 'Continue' button",
                    type: 'warning'
                }))
                setRejectStatus(1);
            }
        }
    }

    const approve_MintPass = async () => {
        const {ethereum} = (window as any);

        if (ethereum) {
            setApprovePassStatus(true);
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            mintPassPortal = new ethers.Contract(mintPass_addr, mintPass_abi, signer);
            try {
                let approved = await mintPassPortal.setApprovalForAll(firstEdition_addr, true);
                await approved.wait();
                if (approved) {
                    setApprovePassCheck(2);
                    await getMintData();
                    setApprovePassStatus(false);
                    setPendingModal(true);
                }
            } catch (e: any) {
                dispatch(show({
                    title: "Spinblade Pass Approve Rejected!",
                    body: "You can continue by clicking 'Continue' button",
                    type: 'warning'
                }));
                setRejectStatus(2);
            }
        }
    }

    const getMintData = async () => {
        const {ethereum} = (window as any);
        // if (ethereum) {
        const provider = new ethers.providers.JsonRpcProvider(rpc_url);
        const signer = provider.getSigner(props.currentAccount ? props.currentAccount : multiSigAdmin_addr);

        USDCPortal = new ethers.Contract(usdc_addr, usdc_abi, signer);
        mintPassPortal = new ethers.Contract(mintPass_addr, mintPass_abi, signer);
        firstEditionBox = new ethers.Contract(firstEdition_addr, firstEdition_abi, signer);
        if (props.currentAccount !== "") {

            const allowance = await USDCPortal.allowance(props.currentAccount, firstEdition_addr);
            if (allowance > 0) {
                localStorage.setItem("approveUSDCCheck", "checked");
                setApproveUSDCCheck(1);
            } else {
                setApproveUSDCCheck(0);
                localStorage.setItem("approveUSDCCheck", "");
            }

            const allowancePass = await mintPassPortal.isApprovedForAll(props.currentAccount, firstEdition_addr);
            if (allowancePass) {
                setApprovePassCheck(1);
                localStorage.setItem("approve_pass", "true");
            } else {
                setApprovePassCheck(0);
                localStorage.setItem("approve_pass", "");
            }
        }
        const promises = [];

        promises.push(firstEditionBox.availableNFTs());//0
        promises.push(firstEditionBox.checkCurrentDropSupply());//1
        promises.push(firstEditionBox.nftAmountPerUser());//2
        promises.push(firstEditionBox.cost());//3
        promises.push(firstEditionBox.maxAmount());//4
        promises.push(firstEditionBox.totalDrop());//5
        let totalDrop = await firstEditionBox.totalDrop();
        let checkDrop = await firstEditionBox.checkDropInfo(Number(totalDrop));
        promises.push(checkDrop.publicStartTime);//6
        promises.push(checkDrop.privateStartTime);//7
        promises.push(checkDrop.publicDuration);//publicDuration//8
        promises.push(checkDrop.privateDuration);// privateDuration//9
        if (props.currentAccount !== "") {
            promises.push(mintPassPortal.balanceOf(props.currentAccount, 0));//10
            promises.push(mintPassPortal.balanceOf(props.currentAccount, 1));//11
            promises.push(firstEditionBox.amountsNFTMinted(props.currentAccount));//12
        }
        let temp: any = [];
        Promise.all(promises).then(responses => {
            responses.forEach((response, index) => {
                temp.push(response);
            })
            temp[0] = Number(temp[0].amount);
            temp[1] = Number(temp[1]);
            temp[2] = Number(temp[2]);
            temp[3] = new BigNumber(Number(temp[3])).dividedBy(10 ** 18).toFixed(2);
            temp[4] = Number(temp[4]);
            temp[5] = Number(temp[5]);
            temp[6] = new Date(Number(temp[6]) * 1000).toUTCString().replace('GMT', 'UTC');
            temp[7] = new Date(Number(temp[7]) * 1000).toUTCString().replace('GMT', 'UTC');
            temp[8] = Number(temp[8]);
            temp[9] = Number(temp[9]);
            if (temp.length > 9) {
                temp[10] = Number(temp[10]);
                temp[11] = Number(temp[11]);
                temp[12] = Number(temp[12]);
            }
            setMintData(temp);
        })
        // }
    }
    return (
        <div>
            <div className={`${(forgeEmailModalStatus || forgeEmailConfirmStatus) && 'blur-sm'}`}>
                <Navbar
                    currentAccount={props.currentAccount}
                    setCurrentAccount={props.setCurrentAccount}
                    connectWallet={props.connectWallet}
                    setCheckNetwork={props.setCheckNetwork}
                    switchNetwork={props.switchNetwork}
                    checkNetwork={props.checkNetwork}
                    disconnectWallet={props.disconnectWallet}
                    promiseData={props.promiseData} noMetamask={props.noMetamask}
                />
                <DropsComponent connectWallet={props.connectWallet}
                                currentAccount={props.currentAccount}
                                mintData={mintData}
                                checkNetwork={props.checkNetwork}
                                switchNetwork={props.switchNetwork}
                                mintNFT={mintNFT} mintNFTFunc={mintNFTFunc}
                                approveUSDCStatus={approveUSDCStatus} approve_USDC_Drop={approve_USDC_Drop}
                                approveUSDCCheck={approveUSDCCheck} approvePassCheck={approvePassCheck}
                                approve_MintPass={approve_MintPass} approvePassStatus={approvePassStatus}
                                mintType={mintType} mintTypeUpdate={mintTypeUpdate} mintStatus={mintStatus}
                                publicPeriod={publicPeriod}
                                privatePeriod={privatePeriod} currentTime={currentTime} beforeStart={beforeStart}
                                isEnded={isEnded} pendingModal={pendingModal} setPendingModal={setPendingModal}
                                getMintData={getMintData} setCongratsModal={setCongratsModal}
                                congratsModal={congratsModal}
                                rejectStatus={rejectStatus} transactionHash={transactionHash} mintSensor={mintSensor}
                                setMintType={setMintType} noMetamask={props.noMetamask}
                />
            </div>
            {forgeEmailModalStatus && <MailComponent/>}
            {forgeEmailConfirmStatus && <EmailConfirm/>}
        </div>
    );
};
