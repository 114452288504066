import React from "react";
import {Navbar} from "../Components/Navbar/Navbar";
import {ForgeComponent} from "../Components/ForgeComponent/ForgeComponent";
import {useAppSelector} from "../hooks";
import {ForgeModal} from "../Components/ForgeComponent/ForgeModal";
import {ForgeProcess} from "../Components/ForgeComponent/ForgeProcess";
import {ForgeCongrats} from "../Components/ForgeComponent/ForgeCongrats";
import {MailComponent} from "../Components/ForgeComponent/MailComponent";
import {EmailConfirm} from "../Components/ForgeComponent/EmailConfirm";

export const Forge = (props: any) => {
    const modalState = useAppSelector(state => state.forge.forgeModalState);
    const forgeProcessModalState = useAppSelector(state => state.forge.forgeProcessModalState);
    const forgeCongratsModalState = useAppSelector(state => state.forge.forgeCongratsModalState);
    const forgeEmailModalStatus = useAppSelector(state => state.forge.forgeEmailModalStatus);
    const forgeEmailConfirmStatus = useAppSelector(state => state.forge.forgeEmailConfirmStatus);
    return (
        <>
            <div
                className={`${((forgeProcessModalState !== 0) || (forgeCongratsModalState !== 0) || forgeEmailModalStatus || forgeEmailConfirmStatus) && 'blur-sm'}`}>
                <Navbar currentAccount={props.currentAccount}
                        setCurrentAccount={props.setCurrentAccount}
                        connectWallet={props.connectWallet}
                        setCheckNetwork={props.setCheckNetwork}
                        promiseData={props.promiseData}
                        switchNetwork={props.switchNetwork} checkNetwork={props.checkNetwork}
                        disconnectWallet={props.disconnectWallet}/>

                <ForgeComponent currentAccount={props.currentAccount} connectWallet={props.connectWallet}
                                switchNetwork={props.switchNetwork} disconnectWallet={props.disconnectWallet}
                                checkNetwork={props.checkNetwork} noMetamask={props.noMetamask}
                />
            </div>
            {(modalState !== 0) && <ForgeModal/>}
            {(forgeProcessModalState !== 0) && <ForgeProcess/>}
            {(forgeCongratsModalState !== 0) && <ForgeCongrats/>}
            {forgeEmailModalStatus && <MailComponent />}
            {forgeEmailConfirmStatus && <EmailConfirm />}
        </>
    )
}