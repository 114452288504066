import React from 'react'
import "./..//AngelRound.css";
import ProgressBar from "@ramonak/react-progress-bar";
import {ProgressSBC} from "./ProgressSBC";

export const End = (props: any) => {

    const progress = (sold: number, total: number) => {
        if (sold < total) {
            return Number(((sold / total) * 100).toFixed(2));
        } else {
            return 100;
        }
    }

    return (
        <div className="w-full flex flex-col justify-center">
            <div className='flex flex-col items-center'>
                <h1 className="text-2xl sm:text-2xl text-white nulshock-text font-bold uppercase text-center mt-5 sm:mt-2 w-8/12">
                    {props.currentAccount && Number(props.promiseData[17]) > 0 ? 'Thank you for your Participation!' : 'Presale has ended'}
                </h1>
                {props.currentAccount && Number(props.promiseData[17]) < 1 ?
                    <h2 className="text-2xl sm:text-2xl simple-text font-semibold text-center mt-5 sm:mt-2 w-full">You haven't participated in the Presale</h2> : <></>
                }
                <ProgressSBC promiseData={props.promiseData}/>
                {Number(props.promiseData[17]) > 0 && props.currentAccount ?
                    <a className="check-vesting-btn top-6 right-6 px-10 mt-7 uppercase nulshock-text" href={'/vesting'}>
                        Check Vesting
                    </a> : ''}

            </div>
        </div>
    );
}