import React, {CSSProperties, useEffect, useRef, useState} from "react";

import goIcon from "../../../assets/goIcon.svg";
import {ScaleLoader} from "react-spinners";
import polygonIcon from "./../../../assets/Polygon.png";
import {StatusButton} from "./StatusButton";
import {SwitchNetwork} from "./switchNetwork";
import {WhiteList} from "./WhiteList";
import {Timer} from "./Timer";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    zIndex: 99,
    position: "absolute"
};

export const RightSide = (props: any) => {

    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#fff");

    useEffect(() => {
        if (props.promiseData.length > 0) {
            if (props.currentAccount) {
                if (props.promiseData[16] !== undefined) {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
    }, [props.promiseData])

    return (
        <div
            className={`${
                props.isMediumScreen ? "col-span-12" : "col-span-5"
            } right-col relative flex items-center justify-center py-40 sm:py-0 ${
                props.isSmallScreen ? "px-5" : "px-6 sm:px-10 md:px-20"
            }`}
        >
            {
                loading ?<div className="flex items-center justify-center"> <ScaleLoader color={color} loading={loading} cssOverride={override} height={50} width={7}
                                                                          radius={40} margin={4}/> </div>:
                    <>
                        <StatusButton presaleStart={props.presaleStart} isEnded={props.isEnded} currentTime={props.currentTime} promiseData={props.promiseData}/>
                        <div className="w-full">
                            {!props.currentAccount && (
                                <>
                                    <Timer currentTime={props.currentTime} promiseData={props.promiseData} presaleStart={props.presaleStart}/>
                                    <h1 className="text-lg sm:text-lg nulshock-text text-white font-bold uppercase text-center mt-16 sm:mt-10">
                                        To be able to participate in phase #1, please connect your wallet
                                    </h1>
                                </>
                            )}

                            {(props.currentAccount && (props.checkNetwork === 1 ?
                                        (props.checkWhitelist || props.promiseData[16] === true ?
                                            <div>
                                                <Timer currentTime={props.currentTime} promiseData={props.promiseData} presaleStart={props.presaleStart}/>
                                                <h1 className="text-lg sm:text-xl simple-text nulshock-text font-bold uppercase text-center mt-16 sm:mt-10">
                                                    Congratulations, your wallet address is on the{" "}
                                                    <span className="text-white">whitelist.</span>
                                                </h1>
                                                <h1 className="text-lg sm:text-xl simple-text font-bold text-center mt-10 sm:mt-8">
                                                    Comeback later to purchase SBC when
                                                    <br/> the <span className="text-white">presale</span> is{" "}
                                                    <span className="live">live.</span>
                                                </h1>
                                            </div>
                                            :
                                            <>
                                                <WhiteList add_whitelist={props.add_whitelist} whitelistStatus={props.whitelistStatus} currentTime={props.currentTime} promiseData={props.promiseData} presaleStart={props.presaleStart}/>
                                            </>) :
                                        <SwitchNetwork switchNetwork={props.switchNetwork}/>
                                )
                            )}
                        </div>
                    </>
            }

        </div>
    );
}