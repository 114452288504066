import {Alchemy, Network} from "alchemy-sdk";
import {useAppDispatch, useAppSelector} from "../hooks";
import {
    setApprovePartStatus,
    setApprovePartStop, setDiscountPassBalance,
    setFetchNFTStatus,
    setForgeCongratsModalState,
    setForgeData,
    setForgeFactoryData,
    setForgeProcessModalState,
    setForgeStatus,
    setForgeStop,
    setOwnedNFTs,
    setTransactionHash
} from "../redux/reducer/forge";
import {
    forgeContract_addr,
    forgeFactory_addr,
    mint721_addr,
    mintPass_addr,
    rpc_url
} from "../Components/contractAddress";
import ForgeAbi from "../utils/ForgeAbi.json";
import PartsAbi from "../utils/ERC721Abi.json";
import ForgeFactoryAbi from "../utils/ForgeFactoryAbi.json";
import MintPassAbi from "../utils/mintPassAbi.json";
import {ethers} from "ethers";
import {show} from "../redux/reducer/notification";

let PartPortal;
let forgePortal;
let forgeFactoryPortal;
let MintPassPortal;
export const useWeb3Functions = () => {
    const ethereum = window.ethereum;
    const dispatch = useAppDispatch();
    const address = useAppSelector(state => state.wallet.walletAddress);
    const forgeContractAddress = forgeContract_addr;
    const forgeFactoryAddress = forgeFactory_addr;
    const mint721Address = mint721_addr;
    const mintPassAddress = mintPass_addr;
    const config = {
        apiKey: 'Yf_fSDXqzEOoJBDZkIJ5axEr58rwfFCa',
        network: Network.MATIC_MAINNET,
        // apiKey: 'SV67IrEbte92wr9pbhLhn7xN5q8A7NDq',
        // network: Network.ETH_GOERLI

    }
    const alchemy = new Alchemy(config);
    const FetchNFTs = async () => {
        let ownedNFTs = [];

        if (address) {
            try {
                let amounts = await alchemy.nft.getNftsForOwner(address);
                ownedNFTs = ownedNFTs.concat(amounts.ownedNfts);
                while (amounts.pageKey) {
                    amounts = await alchemy.nft.getNftsForOwner(address, {pageKey: amounts.pageKey});
                    ownedNFTs = ownedNFTs.concat(amounts.ownedNfts);
                }

                dispatch(setFetchNFTStatus(false));
                dispatch(setOwnedNFTs(ownedNFTs));
            } catch (e) {
                console.log("owner-issue", e);
            }
        }
    }

    const partsApproveFunc = async () => {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            PartPortal = new ethers.Contract(mint721Address, PartsAbi, signer);
            try {
                let approveStatus = await PartPortal.isApprovedForAll(address, forgeContractAddress);
                if (approveStatus) {
                    dispatch(setApprovePartStatus(approveStatus));
                } else {
                    let approve = await PartPortal.setApprovalForAll(forgeContractAddress, true);
                    approve = await approve.wait();
                    dispatch(setApprovePartStatus(approve));
                }
            } catch (e) {
                dispatch(setApprovePartStatus(false));
                dispatch(setApprovePartStop(true));
                dispatch(show({title: "Approve Failed", body: '', type: 'error'}));
                console.log(e);
            }
        }
    }

    const approvePassFunc = async () => {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            PartPortal = new ethers.Contract(mintPassAddress, MintPassAbi, signer);
            try {
                let approveStatus = await PartPortal.isApprovedForAll(address, forgeFactoryAddress);
                if (approveStatus) {
                    dispatch(setApprovePartStatus(approveStatus));
                } else {
                    let approve = await PartPortal.setApprovalForAll(forgeFactoryAddress, true);
                    approve = await approve.wait();
                    dispatch(setApprovePartStatus(approve));
                }
            } catch (e) {
                dispatch(setApprovePartStatus(false));
                dispatch(setApprovePartStop(true));
                dispatch(show({title: "Approve Failed", body: '', type: 'error'}));
                console.log(e);
            }
        }
    }

    const forgeMintPass = async (tokenIds) => {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            forgePortal = new ethers.Contract(forgeContractAddress, ForgeAbi, signer);
            try {
                let forgeMint = await forgePortal.forgeMintPass(tokenIds);
                forgeMint = await forgeMint.wait();
                dispatch(setTransactionHash(forgeMint.transactionHash));
                dispatch(setForgeStatus(true));
                dispatch(show({title: "Forge 1 MintPass!", body: '', type: 'success'}));
                dispatch(setForgeProcessModalState(0));
                dispatch(setForgeCongratsModalState(1));
            } catch (e) {
                dispatch(setForgeStatus(false));
                dispatch(setForgeStop(true));
                dispatch(show({title: "Mint Pass Forge Error", body: '', type: 'error'}));
                console.log(e);
            }
        }
    }

    const forgeFreeClaim = async (tokenIds) => {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            forgePortal = new ethers.Contract(forgeContractAddress, ForgeAbi, signer);
            try {
                let forgeClaim = await forgePortal.forgeFreeClaim(tokenIds);
                forgeClaim = await forgeClaim.wait();
                dispatch(setTransactionHash(forgeClaim.transactionHash));
                dispatch(setForgeStatus(true));
                dispatch(show({title: "Forge 1 FreeClaim!", body: '', type: 'success'}));
                dispatch(setForgeProcessModalState(0));
                dispatch(setForgeCongratsModalState(2));
            } catch (e) {
                dispatch(setForgeStatus(false));
                dispatch(setForgeStop(true));
                dispatch(show({title: "Free Claim Pass Forge Error", body: '', type: 'error'}));
                console.log(e);
            }
        }
    }

    const forgeDiscount = async (burnId, burnAmount, mintId, mintAmount) => {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            forgeFactoryPortal = new ethers.Contract(forgeFactoryAddress, ForgeFactoryAbi, signer);

            try {
                let forgeDiscountClaim = await forgeFactoryPortal.forge(burnId, burnAmount, mintId, mintAmount);
                forgeDiscountClaim = await forgeDiscountClaim.wait();
                dispatch(setTransactionHash(forgeDiscountClaim.transactionHash));
                dispatch(setForgeStatus(true));
                dispatch(show({title: "Forge 1 Discount Pass!", body: '', type: 'success'}));
                dispatch(setForgeProcessModalState(0));
                dispatch(setForgeCongratsModalState(3));
            } catch (e) {
                dispatch(setForgeStatus(false));
                dispatch(setForgeStop(true));
                dispatch(show({title: "Discount Pass Forge Error", body: '', type: 'error'}));
                console.log(e);
            }
        }
    }

    const getForgeData = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(rpc_url);
            forgePortal = new ethers.Contract(forgeContractAddress, ForgeAbi, provider);
            const promises = [];
            promises.push(forgePortal.mintPassParts());
            promises.push(forgePortal.freeClaimParts());
            promises.push(forgePortal.forgeStartTime());
            promises.push(forgePortal.paused());

            let temp = [];
            let responses = await Promise.all(promises);
            responses.forEach((response) => {
                temp.push(response);
            });

            temp[0] = Number(temp[0]);
            temp[1] = Number(temp[1]);
            temp[2] = Number(temp[2]);
            temp[3] = Number(temp[3]);
            dispatch(setForgeData(temp));
        } catch (e) {
            console.log("data fetch error", e);
        }
    }

    const getDiscountPassBalance = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(rpc_url);
            MintPassPortal = new ethers.Contract(mintPassAddress, MintPassAbi, provider);
            const discountBalance = await MintPassPortal.balanceOf(forgeFactoryAddress, 2);
            dispatch(setDiscountPassBalance(Number(discountBalance)));

        } catch (e) {
            console.log("data fetch error", e);
        }
    }

    const getForgeFactoryData = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(rpc_url);
            forgeFactoryPortal = new ethers.Contract(forgeFactoryAddress, ForgeFactoryAbi, provider);
            const promises = [];
            promises.push(forgeFactoryPortal.burnTokenId());
            promises.push(forgeFactoryPortal.burnTokenAmount());
            promises.push(forgeFactoryPortal.mintTokenId());
            promises.push(forgeFactoryPortal.mintTokenAmount());

            let temp = [];
            let responses = await Promise.all(promises);
            responses.forEach((response) => {
                temp.push(response);
            });

            temp[0] = Number(temp[0]);
            temp[1] = Number(temp[1]);
            temp[2] = Number(temp[2]);
            temp[3] = Number(temp[3]);

            dispatch(setForgeFactoryData(temp))
        } catch (e) {
            console.log("data fetch error", e);
        }
    }

    return {
        FetchNFTs,
        forgeMintPass,
        forgeFreeClaim,
        partsApproveFunc,
        getForgeData,
        getForgeFactoryData,
        forgeDiscount,
        approvePassFunc,
        getDiscountPassBalance
    }
}
