import ProgressBar from "@ramonak/react-progress-bar";
import React, {CSSProperties, useEffect, useState} from "react";
import {ProgressLine} from "../../SubComponents/ProgressLine";
import "../Vesting.css";
import {ScaleLoader} from "react-spinners";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    zIndex: 99,
    position: "absolute"
};

export const VestedProgress = (props: any) => {
    const [totalValue, setTotalValue] = useState<any>(0);
    const [firstValue, setFirstValue] = useState(0);
    const [secondValue, setSecondValue] = useState(0);
    const [thirdValue, setThirdValue] = useState(0);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#fff");

    useEffect(() => {
        if (props.promiseData[12]) {
            // setThirdValue(props.promiseData[13])
            setFirstValue(props.promiseData[19])
            setSecondValue(props.promiseData[12])
            setTotalValue(Number(props.promiseData[17])*Number(props.promiseData[6]) )
            setThirdValue(props.promiseData[13])
        }

        if (props.promiseData.length > 0) {
            if (props.currentAccount) {
                if (props.promiseData[16] !== undefined) {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }

    }, [props.promiseData])

    const handle1stPercentage = () => {
        let c = (firstValue * 100) / totalValue;
        return c.toString();
    };
    const handle2ndPercentage = () => {
        let c = (secondValue * 100) / totalValue;
        return c.toString();
    };
    const handle3rdPercentage = () => {
        let c = (thirdValue * 100) / totalValue;
        return c.toString();
    };
    return (
        <div
            className={`col-span-5 bg_block relative flex items-center w-full justify-center h-52 px-4 sm:px-20`}
        >
            <div className="mt-4 w-full">
                {
                    loading ? (<div className="flex items-center justify-center">
                            <ScaleLoader color={color} loading={loading} cssOverride={override} height={50} width={7}
                                         radius={40}
                                         margin={4}/>
                        </div>) :
                        <ProgressLine
                            label="Multiple parts"
                            currentAccount={props.currentAccount}
                            checkNetwork={props.checkNetwork}
                            visualParts={[
                                {
                                    percentage: `${handle1stPercentage()}%`,
                                    color: "#424E98",
                                    value: firstValue,
                                },
                                {
                                    percentage: `${handle2ndPercentage()}%`,
                                    color: "#3674DD",
                                    value: secondValue,
                                },
                                {
                                    percentage: `${handle3rdPercentage()}%`,
                                    color: "#212845",
                                    value: thirdValue,
                                },
                            ]}
                        />}
            </div>
        </div>
    );
};
