import React, {useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import forge, {setApprovePartStop, setForgeProcessModalState, setForgeStop} from "../../redux/reducer/forge";
import checkIcon from "../../assets/check-icon.png";
import freeClaimImg from "../../assets/freeclaim-gif.gif";
import mintPassImg from "../../assets/mintpass-gif.gif";
import discountPassImg from '../../assets/Discount_Pass.gif';
import {useWeb3Functions} from "../../services/web3Functions";

export const ForgeProcess = () => {
    const web3Functions = useWeb3Functions();
    const dispatch = useAppDispatch();
    const refArea = useRef();
    const forgeProcessModalState = useAppSelector(state => state.forge.forgeProcessModalState);
    const approvePartStatus = useAppSelector(state => state.forge.approvePartStatus);
    const approveStopStatus = useAppSelector(state => state.forge.approvePartStop);
    const forgeClaimStatus = useAppSelector(state => state.forge.forgeStatus);
    const forgeStopStatus = useAppSelector(state => state.forge.forgeStop);
    const selectedItems = useAppSelector(state => state.forge.selectedItems);
    const selectedItemsUri = useAppSelector(state => state.forge.selectedItemsUri);
    const getForgeFactoryData = useAppSelector(state => state.forge.getForgeFactoryData);
    const burnTokenId = getForgeFactoryData[0] ? getForgeFactoryData[0] : 0;
    const burnTokenAmount = getForgeFactoryData[1] ? getForgeFactoryData[1] : 5;
    const mintTokenId = getForgeFactoryData[2] ? getForgeFactoryData[2] : 2;
    const mintTokenAmount = getForgeFactoryData[3] ? getForgeFactoryData[3] : 1;
    const closeEvent = () => {
        dispatch(setForgeProcessModalState(0));
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
            >
                <div
                    className="absolute top-[20%] sm:max-w-[528px] sm:min-h-[415px] sm:w-auto w-11/12 mx-auto max-w-xl"
                    ref={refArea}>
                    {/*content*/}
                    <div
                        className="border-0  shadow-lg relative flex flex-col w-full  outline-none focus:outline-none bg-modal rounded-[15px]"
                    >
                        {/*header*/}
                        <div
                            className="flex flex-col items-start text-center p-3 pt-0 rounded-t">
                            <button
                                className="p-1 ml-auto mt-0 border-0 text-black float-right text-2xl outline-none focus:outline-none"
                                onClick={() => closeEvent()}
                            >
                                <span className="simple-text font-bold h-6 w-6 text-2xl">×</span>
                            </button>
                            <div className="w-full text-center">
                                <h3 className="text-3xl poppin-text text-white text-center">
                                    Complete Forge
                                </h3>
                            </div>
                        </div>
                        {/*body*/}
                        <div className="relative p-8 pt-2 flex-auto">
                            <div className="grid grid-cols-5 sm:gap-4 gap-3">
                                <div>
                                    <img
                                        src={forgeProcessModalState === 1 ? mintPassImg : forgeProcessModalState === 2 ? freeClaimImg : discountPassImg}
                                        alt={"First Edition Box"} className={"w-20 rounded-md"}/>
                                </div>
                                <div className={"col-span-3 flex flex-col justify-center poppin-text"}>
                                    <div className={"simple-text sm:text-base text-base"}>Spinblade Assets</div>
                                    <div
                                        className={"text-white sm:text-base text-base"}>{forgeProcessModalState === 1 ? 'Mint Pass' : forgeProcessModalState === 2 ? 'Free Claim Pass' : 'Discount Pass'}</div>
                                    <div
                                        className={"simple-text sm:text-base text-sm"}>Quantity: 1
                                    </div>
                                </div>
                            </div>
                            <div className="hr-2 my-3"></div>
                            <div className={"flex flex-col justify-start gap-3"}>

                                <div className={"flex flex-col justify-start simple-text poppin-text"}>
                                    <div className={"text-white text-xl flex flex-row gap-2 mt-3"}>
                                        <div className={'loading-icon'}>
                                            {!approvePartStatus && approveStopStatus ?
                                                <>
                                                    <span className="animate-spin-1"></span>
                                                    <span className={"loading-number  text-base"}>1</span>
                                                </> :
                                                <>
                                                    <span className="animate-spin"></span>
                                                    <span className={"loading-number  text-base"}>1</span>
                                                </>}
                                            {approvePartStatus && <>
                                                <span className="animate-spin-2"></span>
                                                <img src={checkIcon} className={"loading-check text-base"}/>
                                            </>}
                                            {/*<span className="animate-spin-1"></span>*/}
                                            {/*<span className="animate-spin-2"></span>*/}
                                        </div>
                                        Approve {forgeProcessModalState === 3 ? 'Passes' : 'Stickers'}
                                    </div>
                                    {!approvePartStatus &&
                                    <div className={"sub-element ml-8"}>
                                        <div className={"text-sm"}>You'll be asked to approve the use of <b
                                            className={'text-white'}>{forgeProcessModalState === 3 ? 'Passes' : 'Stickers'}</b> from
                                            your wallet. You only need to do this once
                                        </div>
                                        <button
                                            className={`p-4 mt-2 simple-text waiting-btn outline-none focus:outline-none ${approveStopStatus ? 'bg-blue w-40' : 'bg-[#36383F] w-48'}`}
                                            onClick={approveStopStatus && forgeProcessModalState === 1 ? (() => {
                                                web3Functions.partsApproveFunc();
                                                dispatch(setApprovePartStop(false));
                                            }) : approveStopStatus && forgeProcessModalState === 2 ? (() => {
                                                web3Functions.partsApproveFunc();
                                                dispatch(setApprovePartStop(false));
                                            }) : (() => {
                                                web3Functions.approvePassFunc();
                                                dispatch(setApprovePartStop(false));
                                            })
                                            }
                                            disabled={!approveStopStatus}
                                        > {approveStopStatus ? 'Continue' : 'Waiting for approval...'}
                                        </button>
                                    </div>
                                    }
                                </div>

                                <div className={"flex flex-col justify-start simple-text poppin-text"}>
                                    <div className={"text-white text-xl flex flex-row gap-2 mt-3"}>
                                        <div className={'loading-icon'}>
                                            {approvePartStatus ? forgeClaimStatus ? <>
                                                    <span className="animate-spin-2"></span>
                                                    <img src={checkIcon} className={"loading-check text-base"}/>
                                                </> : !forgeStopStatus ? <>
                                                    <span className="animate-spin"></span>
                                                    <span className={"loading-number  text-base"}>2</span>
                                                </> :
                                                <>
                                                    <span className="animate-spin-1"></span>
                                                    <span className={"loading-number  text-base"}>2</span>
                                                </> :
                                                <>
                                                    <span className="animate-spin-1"></span>
                                                    <span className={"loading-number  text-base"}>2</span>
                                                </>
                                            }
                                            {/*<span className="animate-spin"></span>*/}
                                            {/*<span className="animate-spin-2"></span>*/}
                                            {/*<img src={checkIcon} className={"loading-check text-base"}/>*/}
                                        </div>
                                        Confirm Forge
                                    </div>
                                    {(approvePartStatus && !forgeClaimStatus) &&
                                    <div className={"sub-element ml-8 "}>
                                        <div className={"text-sm"}>You'll be asked to approve this <b
                                            className={'text-white'}>forge</b> from your wallet.
                                        </div>
                                        <button
                                            className={`p-4 mt-2 simple-text waiting-btn outline-none focus:outline-none ${forgeStopStatus ? 'bg-blue w-40' : 'bg-[#36383F] w-44'}`}
                                            onClick={forgeStopStatus && forgeProcessModalState === 1 ? (() => {
                                                web3Functions.forgeMintPass(selectedItems);
                                                dispatch(setForgeStop(false));
                                            }) : forgeStopStatus && forgeProcessModalState === 2 ? (() => {
                                                web3Functions.forgeFreeClaim(selectedItems);
                                                dispatch(setForgeStop(false));
                                            }) : (() => {
                                                web3Functions.forgeDiscount(burnTokenId, selectedItems.length, mintTokenId, mintTokenAmount);
                                                dispatch(setForgeStop(false));
                                            })
                                            }
                                            disabled={!forgeStopStatus}
                                        > {forgeStopStatus ? 'Continue' : 'Waiting for Forge...'}
                                        </button>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}