import React, {CSSProperties, useEffect, useRef, useState} from "react";
import firstEditionGif from "../../../assets/first-edition-gif.gif";
import maticIcon from "../../../assets/Polygon.png";
import checkIcon from "../../../assets/check-icon.png";
import NumberFormat from "react-number-format";
import useOutsideClick from "./useOutsideClick";


export const PurchaseModal = (props: any) => {
    const [usdcPending, setUsdcPending] = useState(false);
    const [passPending, setPassPending] = useState(false);
    const ref: any = useRef();

    useOutsideClick(ref, () => {

        if (props.pendingModal) {
            console.log("here");
            // props.setPendingModal(false);
        }
    });

    useEffect(() => {
        if (props.mintType === -1) {
            // if (props.approveUSDCCheck === 1) {
            setUsdcPending(true);
            if (!props.mintSensor) {
                props.mintNFTFunc(props.nftAmounts, props.mintType)
            }
            // }
            setPassPending(true);
        } else {
            if (Object.keys(props.nftsByCollection).length > 0) {
                if (props.discountPercent > 0) {
                    // if (props.approveUSDCCheck === 1) {
                    setUsdcPending(true);
                    // if (props.approvePassCheck === 0) {
                    //     console.log("here")
                    //     props.approve_MintPass();
                    // }
                    // }
                    if (props.approvePassCheck === 2) {
                        setPassPending(true);
                        props.mintNFTFunc(props.nftAmounts, props.mintType)
                    }
                    if (props.approvePassCheck === 1) {
                        setPassPending(true);
                        if (!props.mintSensor) {
                            // props.mintNFTFunc(props.nftAmounts, props.mintType)
                        }
                    }
                } else {
                    if (props.approvePassCheck === 2) {
                        setPassPending(true);
                        props.mintNFTFunc(props.nftAmounts, props.mintType)
                    }

                    if (props.approvePassCheck === 1) {
                        setPassPending(true);
                        if (!props.mintSensor) {
                            // props.mintNFTFunc(props.nftAmounts, props.mintType)
                        }
                    }
                    setUsdcPending(true);
                }
            }
        }
    }, [props.mintType,props.approvePassCheck])

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
            >
                <div
                    className="relative sm:w-auto w-11/12 mx-auto my-6 max-w-xl">
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none bg-modal rounded-2xl"
                    >
                        {/*header*/}
                        <div
                            className="flex flex-col items-start text-center p-3 pt-0 rounded-t">
                            <button
                                className="p-1 ml-auto mt-0 border-0 text-black float-right text-2xl outline-none focus:outline-none"
                                onClick={() => props.setPendingModal(false)}
                            >
                                <span className="simple-text font-bold h-6 w-6 text-2xl">×</span>
                            </button>
                            <div className="w-full text-center">
                                <h3 className="text-3xl poppin-text text-white text-center">
                                    Complete Purchase
                                </h3>
                            </div>
                        </div>
                        {/*body*/}
                        <div className="relative p-8 pt-4 flex-auto">
                            <div className="grid grid-cols-5 sm:gap-4 gap-3">
                                <div>
                                    <img src={firstEditionGif} alt={"First Edition Box"} className={"w-28 rounded-md"}/>
                                </div>
                                <div className={"col-span-3 flex flex-col justify-center poppin-text"}>
                                    <div className={"simple-text sm:text-xl text-base"}>Spinblade Assets</div>
                                    <div className={"text-white sm:text-xl text-base"}>First Edition Box</div>
                                    <div
                                        className={"simple-text sm:text-xl text-base"}>Quantity: {props.nftAmounts}</div>
                                </div>
                                <div className={"col-span-1 flex flex-col justify-start poppin-text text-right"}>
                                    <div className={"simple-text sm:text-xl text-base"}>Price</div>
                                    <div
                                        className={"text-white sm:text-xl text-base flex flex-row justify-end align-center"}>
                                        <img
                                            src={maticIcon} alt="dollarIcon" className={"w-6 h-6"}/>&nbsp;
                                        {props.discountPercent === 0 ? "Free" : (
                                            <NumberFormat
                                                value={props.mintType === -1 ? props.nftPrice : props.discountedPrice}
                                                displayType={'text'}
                                                prefix={'$'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="hr-2 my-3"></div>
                            <div className={"flex flex-col justify-start gap-3"}>
                                {props.mintType !== -1 && (
                                    <div className={"flex flex-col justify-start simple-text poppin-text"}>
                                        <div className={"text-white text-xl flex flex-row gap-2 mt-3"}>
                                            <div className={'loading-icon'}>
                                                {usdcPending && !passPending && props.approvePassStatus && props.rejectStatus !== 2 ? (
                                                    <span className="animate-spin"></span>) : (
                                                    <span className="animate-spin-1"></span>)}
                                                {passPending && (<span className="animate-spin-2"></span>)}
                                                {!passPending ? (
                                                    <span
                                                        className={"loading-number  text-base"}>{props.mintType === 1 ? 1 : 1}</span>
                                                ) : (<img src={checkIcon} className={"loading-check text-base"}/>)}
                                            </div>
                                            Approve Spinblade Pass
                                        </div>
                                        {usdcPending && !passPending && (props.approvePassStatus || props.rejectStatus === 2) && (
                                            <div className={"sub-element ml-8"}>
                                                <div className={"text-sm"}>You'll be asked to approve the use of <b
                                                    className={'text-white'}>Spinblade Pass</b> from your wallet. You
                                                    only need to do this once
                                                </div>
                                                <button
                                                    className={`p-4 mt-2 simple-text waiting-btn outline-none focus:outline-none ${props.rejectStatus === 2 && 'bg-blue w-40'}`}
                                                    onClick={() => props.mintNFTFunc(props.nftAmounts, props.mintType)}
                                                    disabled={props.rejectStatus !== 2}
                                                > {props.rejectStatus === 2 ? 'Continue' : 'Waiting for approval...'}
                                                </button>
                                            </div>
                                        )}

                                    </div>
                                )}

                                <div className={"flex flex-col justify-start simple-text poppin-text"}>
                                    <div className={"text-white text-xl flex flex-row gap-2 mt-3"}>
                                        <div className={'loading-icon'}>
                                            {usdcPending && passPending && props.rejectStatus !== 3 ? props.mintStatus ? (
                                                <span className="animate-spin"></span>) : (
                                                <span className="animate-spin-2"></span>) : (
                                                <span className="animate-spin-1"></span>)}
                                            {!usdcPending || !passPending || (props.mintStatus || props.rejectStatus === 3) ? (
                                                <span
                                                    className={"loading-number  text-base"}>{props.mintType === -1 ? 1 : 2}</span>
                                            ) : (<img src={checkIcon} className={"loading-check text-base"}/>)}
                                        </div>
                                        Confirm Purchase
                                    </div>
                                    {usdcPending && passPending && (props.mintStatus || props.rejectStatus === 3) && (
                                        <div className={"sub-element ml-8 "}>
                                            <div className={"text-sm"}>You'll be asked to approve this <b
                                                className={'text-white'}>Purchase</b> from your wallet.
                                            </div>
                                            <button
                                                className={`p-4 mt-2 simple-text waiting-btn outline-none focus:outline-none ${props.rejectStatus === 3 && 'bg-blue w-40'}`}
                                                onClick={() => props.mintNFTFunc(props.nftAmounts, props.mintType)}
                                                disabled={props.rejectStatus !== 3}
                                            > {props.rejectStatus === 3 ? 'Continue' : 'Waiting for approval...'}
                                            </button>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}