import React, {useState, useEffect} from 'react'
import {Container, useMediaQuery} from "@mui/material";
import "./AngelRound.css";
import {LeftSide} from "./SubComponents/LeftSide";
import {RightSide} from "./SubComponents/RightSide";
import {Live} from "./SubComponents/Live";


export const AngelRound = (props: any) => {
    let isMediumScreen = useMediaQuery("(max-width:980px)");
    let isSmallScreen = useMediaQuery("(max-width:500px)");
    const [currentTime, setCurrentTime] = useState(new Date().getTime());
    let [loading, setLoading] = useState(true);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().getTime())
            const current = new Date().getTime();
            const endTime = Math.floor(new Date(props.promiseData[3]).getTime());
            if (current == endTime) {
                props.getContractData();
                setLoading(true);
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        setLoading(true);
        props.getContractData();
    }, [props.currentAccount]);

    return (
        <div className="py-40">
            <Container maxWidth={"lg"}>
                <div className={`${
                    isMediumScreen ? "gap-y-6" : "gap-x-6 lg:gap-x-12 "
                } grid grid-cols-8 `}
                >
                    <LeftSide currentAccount={props.currentAccount} promiseData={props.promiseData}
                              presaleStart={props.presaleStart}
                              isEnded={props.isEnded} add_whitelist={props.add_whitelist}
                              isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen}></LeftSide>

                    {(Number(props.presaleStart) < Number(currentTime)) ?
                        <Live currentAccount={props.currentAccount} promiseData={props.promiseData}
                              orderStatus={props.orderStatus} getContractData={props.getContractData}
                              presaleStart={props.presaleStart} isSmallScreen={isSmallScreen}
                              isMediumScreen={isMediumScreen} checkNetwork={props.checkNetwork}
                              isEnded={props.isEnded} add_whitelist={props.add_whitelist}
                              switchNetwork={props.switchNetwork}
                              approve_USDC={props.approve_USDC} approveCheck={props.approveCheck}
                              approveStatus={props.approveStatus}
                              buy_SBC={props.buy_SBC} withdraw_SBC={props.withdraw_SBC} currentTime={currentTime}
                              sbcPurchaseModal={props.sbcPurchaseModal} setSbcPurchaseModal={props.setSbcPurchaseModal}
                              sbcCongratsModal={props.sbcCongratsModal} setSbcCongratsModal={props.setSbcCongratsModal}
                              SBC_Purchase_Func={props.SBC_Purchase_Func} rejectStatus={props.rejectStatus}
                              purchaseSensor={props.purchaseSensor}
                              transactionHash={props.transactionHash}
                        ></Live> :
                        <RightSide currentAccount={props.currentAccount} promiseData={props.promiseData}
                                   whitelistStatus={props.whitelistStatus}
                                   presaleStart={props.presaleStart} checkNetwork={props.checkNetwork}
                                   checkWhitelist={props.checkWhitelist}
                                   isEnded={props.isEnded} add_whitelist={props.add_whitelist}
                                   switchNetwork={props.switchNetwork}
                                   isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen}
                                   currentTime={currentTime}></RightSide>
                    }

                </div>
            </Container>
        </div>
    );
};