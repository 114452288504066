import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../hooks";

export const ForgeTimer = () => {
    const [currentTime, setCurrentTime] = useState(new Date().getTime());
    const getForgeData = useAppSelector(state => state.forge.getForgeData);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().getTime())
        }, 1000)

        return () => clearInterval(interval)
    }, [])
    const seconds: any = Math.floor((getForgeData[2] * 1000 - currentTime) / 1000);
    let timeDays
    let timeHours
    let timeMinutes
    let timeSeconds

    if (seconds > 0) {
        // Calculating the days, hours, minutes and seconds left
        timeDays = Math.floor(seconds / (60 * 60 * 24))
        timeHours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60))
        timeMinutes = Math.floor((seconds % (60 * 60)) / 60)
        timeSeconds = Math.floor(seconds % 60)

        timeDays = timeDays < 10 ? `0${timeDays}` : timeDays
        timeHours = timeHours < 10 ? `0${timeHours}` : timeHours
        timeMinutes = timeMinutes < 10 ? `0${timeMinutes}` : timeMinutes
        timeSeconds = timeSeconds < 10 ? `0${timeSeconds}` : timeSeconds
    } else {
        timeDays = '00'
        timeHours = '00'
        timeMinutes = '00'
        timeSeconds = '00'
    }

    return (<>
        <div className={'flex text-[#454545] text-[20px]'}>
            Forging starts in
        </div>
        <div
            className="flex flex-row items-center justify-center flex-wrap sm:flex-nowrap gap-y-5 sm:gap-y-0 mt-2 mb-5">
            <div className="flex flex-col items-center gap-y-2 mx-1">
                <div className="time-block-forge">
                    <h1 className="text-xl sm:text-2xl nulshock-text text-white font-bold uppercase text-center">
                        {timeDays}
                    </h1>
                </div>
                <span
                    className="text-[9px] font-semibold simple-text nulshock-text">Days</span>
            </div>
            <h1 className="text-2xl sm:text-4xl simple-text font-bold uppercase text-center -mt-6">
                :
            </h1>
            <div className="flex flex-col items-center gap-y-2 mx-1">
                <div className="time-block-forge">
                    <h1 className="text-xl sm:text-2xl nulshock-text text-white font-bold uppercase text-center">
                        {timeHours}
                    </h1>
                </div>
                <span
                    className="text-[9px] font-semibold simple-text nulshock-text">Hours</span>
            </div>
            <h1 className="text-2xl sm:text-4xl simple-text font-bold uppercase text-center -mt-6">
                :
            </h1>
            <div className="flex flex-col items-center gap-y-2 mx-1">
                <div className="time-block-forge">
                    <h1 className="text-xl sm:text-2xl nulshock-text text-white font-bold uppercase text-center">
                        {timeMinutes}
                    </h1>
                </div>
                <span
                    className="text-[9px] font-semibold simple-text nulshock-text">Minutes</span>
            </div>
            <h1 className="text-2xl sm:text-4xl simple-text font-bold uppercase text-center -mt-6">
                :
            </h1>
            <div className="flex flex-col items-center gap-y-2 mx-1">
                <div className="time-block-forge">
                    <h1 className="text-xl sm:text-2xl nulshock-text text-white font-bold uppercase text-center">
                        {timeSeconds}
                    </h1>
                </div>
                <span
                    className="text-[9px] font-semibold simple-text nulshock-text">Seconds</span>
            </div>
        </div>
    </>)
}