import goIcon from './../../../assets/goIcon.svg'
import React, {useState} from "react";
import {SyncLoader} from "react-spinners";
import {Timer} from './Timer';

export const WhiteList = (props: any) => {
    let [color, setColor] = useState("#fff");
  return (
      <div className='flex flex-col items-center gap-y-8'>
          <Timer currentTime={props.currentTime} promiseData={props.promiseData} presaleStart={props.presaleStart}/>
          <h1 className="text-lg sm:text-xl nulshock-text simple-text font-bold uppercase text-center  sm:mt-2">
              To be able to participate in this round, <br/> you need to complete additional {" "}
              <span className="text-white">Steps.</span>
          </h1>
          <button
              className='join-btn px-8 py-3 nulshock-text font-semibold uppercase flex items-center gap-x-3 justify-center'
              onClick={props.add_whitelist} disabled={props.whitelistStatus}>
              <img src={goIcon} alt="goIcon" className='w-5'/>
              {!props.whitelistStatus?<span>Join WhiteList</span> : <span className="flex">Joining ... &nbsp;<SyncLoader color={color} loading={props.orderStatus} size={10}/></span>}
          </button>
      </div>
  );
};
