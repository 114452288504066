import {Navbar} from "../Components/Navbar/Navbar";
import {AssetsComponent} from "../Components/AssetsComponent/AssetsComponent";

export const Assets = (props: any) => {

    return (
        <div >
            <Navbar currentAccount={props.currentAccount}
                    setCurrentAccount={props.setCurrentAccount}
                    setCheckNetwork={props.setCheckNetwork}
                    connectWallet={props.connectWallet}
                    promiseData={props.promiseData}
                    switchNetwork={props.switchNetwork} checkNetwork={props.checkNetwork}
                    disconnectWallet={props.disconnectWallet}/>
            <AssetsComponent currentAccount={props.currentAccount} connectWallet={props.connectWallet}
                             switchNetwork={props.switchNetwork} disconnectWallet={props.disconnectWallet}
                             checkNetwork={props.checkNetwork} noMetamask={props.noMetamask}
            />
        </div>
    )
}