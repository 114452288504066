import React, {useState, useEffect, CSSProperties} from 'react'
import "./..//AngelRound.css";
import calenderIcon from "./../../../assets/calenderIcon.svg";
import angleIcon from "./../../../assets/sbc-unit.png";
import arrowDown from "./../../../assets/arrowDown.svg";
import dollarIcon from "./../../../assets/usdc-unit.png";
import ExchangeIcon from "./../../../assets/ExchangeIcon.svg";
import NumberFormat from "react-number-format";
import ReactTooltip from "react-tooltip";

import {End} from "./End";
import {SwitchNetwork} from "./switchNetwork";
import {StatusButton} from "./StatusButton";

import {ClockLoader, DotLoader, FadeLoader, HashLoader, RiseLoader, ScaleLoader, SyncLoader} from "react-spinners";
import {Timer} from "./Timer";
import {ProgressSBC} from "./ProgressSBC";

import {show} from "../../../redux/reducer/notification";
import {useAppDispatch} from "../../../hooks";
import {PurchaseModal} from "./PurchaseModal";
import {CongratsModal} from "./CongratsModal";


const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    zIndex: 99,
    position: "absolute"
};

export const Live = (props: any) => {

    const [fromAmount, setFromAmount] = useState(0);
    const [toAmount, setToAmount] = useState(0);
    const [rate, setRate] = useState(0);
    const [amountCheck, setAmountCheck] = useState(0);
    let [loading, setLoading] = useState(true);

    let [color, setColor] = useState("#fff");
    const dispatch = useAppDispatch()

    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0');
    }
    const leftDays = () => {
        const leftDay = (Date.parse(props.promiseData[3]) - (Date.now())) / 86400000;

        if (leftDay >= 1 && leftDay < 30) {
            return `${leftDay.toFixed(0)} day(s)`;
        } else if (0 < leftDay && leftDay < 1) {
            const milliseconds = (Date.parse(props.promiseData[3]) - (Date.now()))
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;
            minutes = minutes % 60;
            hours = hours % 24;
            if (hours >= 1) {
                return `${padTo2Digits(hours)} hour(s)`;
            }
            if (minutes >= 1 && hours < 1) {
                return `${padTo2Digits(minutes)} min(s)`;
            }
            if (seconds > 0 && minutes < 1) {
                return `${padTo2Digits(seconds)} sec(s)`;
            }

        } else {
            props.getContractData();
            setLoading(true);
            return 0;
        }
    }

    const maxInput = () => {
        const balance = props.promiseData[14];
        if ((25000 - props.promiseData[17]) * props.promiseData[6] > props.promiseData[0] - props.promiseData[1]) {
            if (balance * props.promiseData[6] > props.promiseData[0] - props.promiseData[1]) {
                setFromAmount((props.promiseData[0] - props.promiseData[1]) / props.promiseData[6])
                setToAmount(props.promiseData[0] - props.promiseData[1])
            } else {
                setFromAmount(balance)
                setToAmount(balance * props.promiseData[6])
            }
        } else {
            if (balance > 25000 - props.promiseData[17]) {
                setFromAmount(25000 - props.promiseData[17])
                setToAmount((25000 - props.promiseData[17]) * props.promiseData[6])
            } else {
                setFromAmount(balance)
                setToAmount(balance * props.promiseData[6])
            }
        }
        if (25000 - props.promiseData[17] === 0) {
            dispatch(show({
                title: "You already bought max amount SBC",
                body: props.currentAccount,
                type: 'warning'
            }))
        } else {
            dispatch(show({
                title: "Buying amount has been updated according to presale conditions",
                body: props.currentAccount,
                type: 'success'
            }))
        }

    }

    const showRate = (rate: any) => {
        rate === 0 ? setRate(1) : setRate(0)
    }

    const adjustAmount = (remainingAmount: number) => {
        setFromAmount(remainingAmount)
        setToAmount(remainingAmount * props.promiseData[6])
        dispatch(show({
            title: "Buying amount has been updated according to presale conditions",
            body: props.currentAccount,
            type: 'success'
        }))
    }

    const maxAmount = (maxAmount: number) => {
        if (maxAmount < props.promiseData[14] * props.promiseData[6]) {
            setFromAmount(maxAmount / props.promiseData[6])
            setToAmount(maxAmount)
        } else {
            setFromAmount(props.promiseData[14])
            setToAmount(props.promiseData[14] * props.promiseData[6])
        }
    }
    useEffect(() => {

        if (!fromAmount) {
            setAmountCheck(0)
        }
        if (fromAmount >= 250 && fromAmount <= 25000 - props.promiseData[17]) {
            setAmountCheck(2)
        }
        if (fromAmount > 0 && fromAmount < 250) {
            setAmountCheck(1)
        }
        if (fromAmount > Number(props.promiseData[14])) {
            setAmountCheck(3)
        }
        if (fromAmount > 25000 - props.promiseData[17] && fromAmount < Number(props.promiseData[14])) {
            setAmountCheck(4)
        }
        if (fromAmount * props.promiseData[6] > props.promiseData[0] - props.promiseData[1]) {
            setAmountCheck(5);
        }
    }, [fromAmount]);

    useEffect(() => {
        if (props.promiseData.length > 0) {
            if (props.currentAccount) {
                if (props.promiseData[16] !== undefined) {
                    setLoading(false);
                    if (!props.approveCheck) {
                        setFromAmount(0);
                        setToAmount(0);
                    }
                }
            } else {
                setLoading(false);
            }
        }
    }, [props.promiseData])

    return (
        <div
            className={`${
                props.isMediumScreen ? "col-span-12" : "col-span-5"
            } right-col relative flex justify-center py-8 sm:py-6 ${
                props.isSmallScreen ? "px-5" : " px-10 xl:px-20"
            }`}
        >
            {
                loading ? (<div className="flex items-center justify-center">
                        <ScaleLoader color={color} loading={loading} cssOverride={override} height={50} width={7}
                                     radius={40}
                                     margin={4}/>
                    </div>)
                    // : props.checkNetwork === 1 ? props.currentAccount ? props.promiseData[16] ? !props.isEnded ?
                    : Math.floor(new Date(props.promiseData[3]).getTime()) > props.currentTime ? props.currentAccount ? props.checkNetwork === 1 ? props.promiseData[16] ?

                    <>
                        <StatusButton presaleStart={props.presaleStart} isEnded={props.isEnded}
                                      currentTime={props.currentTime} promiseData={props.promiseData}/>
                        <div className="w-full">
                            <div className="flex items-center gap-x-3">
                                <img src={calenderIcon} alt="calenderIcon" className="w-6"/>
                                <p className="days-text text-lg font-semibold border-dotted border-b border-white"
                                   data-tip=""
                                   data-for="endDay">
                                    <b>{leftDays()}</b> left
                                </p>
                                <ReactTooltip id="endDay" place="bottom"
                                              effect="solid">{props.promiseData[3]}</ReactTooltip>
                            </div>
                            <ProgressSBC promiseData={props.promiseData}/>
                            <div className="amount-div px-6 w-full py-2 mt-6">
                                <div className="flex items-center justify-between">
                                    <span className="simple-text-3 text-lg">From</span>
                                    <span className="simple-text-3 text-lg">Balance:  <NumberFormat
                                        value={props.promiseData[14]}
                                        displayType={'text'}
                                        thousandSeparator={true}/></span>
                                </div>
                                <div className="flex items-center justify-between mt-2">
                                    <input
                                        type={"number"}
                                        placeholder="0.0"
                                        value={fromAmount > 0 ? fromAmount : ''} onChange={(e: any) => {
                                        setToAmount(e.target.value * props.promiseData[6]);
                                        setFromAmount(e.target.value);
                                    }}
                                        className="bg-transparent input w-28  sm:w-60 outline-none shadow-none text-white p-0 text-2xl font-bold py-1"
                                        id="usdc-input"
                                    />
                                    <div className="flex items-center gap-x-3 sm:gap-x-5">
                                        <button className="max-btn px-3 nulshock-text" onClick={maxInput}>MAX</button>
                                        <img src={dollarIcon} alt="dollarIcon" className="w-6 sm:w-8"/>
                                        <h1 className="text-white text-lg sm:text-2xl">USDC</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center my-5">
                                <img src={arrowDown} alt="arrowDown" className=""/>
                            </div>
                            <div className="amount-div px-6 w-full py-2">
                                <div className="flex items-center justify-between">
                                    <span className="simple-text-3 text-lg">To</span>
                                    <span className="simple-text-3 text-lg">
              Available: <NumberFormat value={Number(props.promiseData[0]) - Number(props.promiseData[1])}
                                       displayType={'text'} thousandSeparator={true}/>
            </span>
                                </div>
                                <div className="flex items-center justify-between mt-2">
                                    <input
                                        type={"number"}
                                        placeholder="0.0"
                                        value={toAmount > 0 ? toAmount : ''} onChange={(e: any) => {
                                        setFromAmount(e.target.value / props.promiseData[6]);
                                        setToAmount(e.target.value);
                                    }}
                                        className="bg-transparent input w-28 sm:w-60 outline-none shadow-none text-white p-0 text-2xl font-bold py-1"
                                    />
                                    <div className="flex items-center gap-x-3 sm:gap-x-5">
                                        <img src={angleIcon} alt="angleIcon" className="w-6 sm:w-8"/>
                                        <h1 className="text-white text-lg sm:text-2xl pl-3 pr-2"> SBC</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between mt-4 px-5">
                                <span className="simple-text-3 text-md sm:text-lg">Price</span>
                                <div className="flex items-center gap-x-2">
                                    {rate === 0 ? <span className="simple-text-3 text-md sm:text-lg">
                          {Math.round(props.promiseData[6]).toFixed(2)} SBC per USDC
                        </span> : <span className="simple-text-3 text-md sm:text-lg">
                          {1 / props.promiseData[6]} USDC per SBC
                        </span>}
                                    <button onClick={() => {
                                        showRate(rate)
                                    }}>
                                        <img src={ExchangeIcon} alt="ExchangeIcon"/>
                                    </button>
                                </div>
                            </div>

                            {amountCheck === 0 && (
                                <button className="enter-btn w-full mt-6 py-4">Enter an amount</button>)}
                            {amountCheck === 1 && (
                                // <button className="insufficient-btn w-full mt-6 py-4">Insufficient balance</button>
                                <button className="min-btn w-full mt-6 py-4">Min. Purchase is
                                    ${Math.round(props.promiseData[7]).toFixed(2)} USDC</button>
                            )}
                            {(amountCheck === 2) && (
                                <>
                                    <div className="w-full flex justify-between">
                                        {/*<button*/}
                                        {/*    className={`approve-btn w-6/12 mt-6 py-4 mr-2 ${localStorage.getItem("approveCheck") === "checked" ? "hidden" : ""}`}*/}
                                        {/*    onClick={() => props.approve_USDC()}*/}
                                        {/*    disabled={props.approveStatus}>{props.approveStatus ? "Pending.." : "Approve"}  &nbsp;*/}
                                        {/*    <SyncLoader color={color} loading={props.approveStatus} size={10}/>*/}
                                        {/*</button>*/}
                                        <button
                                            className={`complete-btn  mt-6 py-4 ml-2 w-full`}
                                            onClick={() => props.SBC_Purchase_Func(fromAmount)}>
                                            Complete Order
                                        </button>
                                    </div>
                                    {/*<div*/}
                                    {/*    className={`w-full flex justify-center ${localStorage.getItem("approveCheck") === "checked" ? "hidden" : ""}`}>*/}
                                    {/*    <img src={processIcon} alt="processIcon" className="w-6/12 mt-5"/>*/}
                                    {/*</div>*/}
                                </>
                            )}
                            {amountCheck === 3 && (
                                <button className="insufficient-btn w-full mt-6 py-4">Insufficient balance</button>)}
                            {/*{amountCheck === 4 && (<button className="min-btn w-full mt-6 py-4">Max. Purchase is*/}
                            {/*    ${Math.round(props.promiseData[7] * 100).toFixed(2)} USDC</button>)}*/}
                            {amountCheck === 4 && (<button className="remaining-btn w-full mt-6 py-4"
                                                           onClick={() => adjustAmount(props.promiseData[7] * 100 - props.promiseData[17])}>Remaining
                                allocation is
                                ${Math.round(props.promiseData[7] * 100 - props.promiseData[17]).toFixed(0)} USDC</button>)}
                            {amountCheck === 5 && (
                                <span className="max-remaining-btn w-full mt-6 py-4">Not Enough SBC Set&nbsp;
                                    <button
                                        onClick={() => maxAmount((props.promiseData[0] - props.promiseData[1]) < (props.promiseData[7] * 100 - props.promiseData[17]) * props.promiseData[6] ? (props.promiseData[0] - props.promiseData[1]) : (props.promiseData[7] * 100 - props.promiseData[17]) * props.promiseData[6])}>MAX</button>
                                    &nbsp;availability</span>)}
                        </div>
                    </> //presale live status
                    :
                    <>
                        <StatusButton presaleStart={props.presaleStart} isEnded={props.isEnded}
                                      promiseData={props.promiseData}
                                      currentTime={props.currentTime}/>
                        <div className="w-full flex flex-col justify-center">
                            <div className='flex flex-col items-center'>
                                <Timer currentTime={props.currentTime} promiseData={props.promiseData}
                                       presaleStart={props.presaleStart}/>
                                <ProgressSBC promiseData={props.promiseData}/>
                                <h1 className="text-2xl sm:text-2xl simple-text font-bold text-center mt-16 sm:mt-10">
                                    This wallet address is not eligible to <br/> participate in {" "}
                                    <span className="text-white">This Round.</span>
                                </h1>
                            </div>
                        </div>
                    </> //whitelist check invalid status
                    :
                    <SwitchNetwork switchNetwork={props.switchNetwork}/> // wrong network status
                    :
                    <div className="w-full flex flex-col justify-center">
                        <StatusButton presaleStart={props.presaleStart} isEnded={props.isEnded}
                                      currentTime={props.currentTime} promiseData={props.promiseData}/>
                        <div className='flex flex-col items-center'>
                            <Timer currentTime={props.currentTime} promiseData={props.promiseData}
                                   presaleStart={props.presaleStart}/>
                            <ProgressSBC promiseData={props.promiseData}/>
                        </div>
                    </div> // disconnected status
                    :
                    <>
                        <StatusButton presaleStart={props.presaleStart} isEnded={props.isEnded}
                                      currentTime={props.currentTime} promiseData={props.promiseData}/>
                        <End promiseData={props.promiseData} currentAccount={props.currentAccount}/>
                    </> // presale ended status
            }
            {props.sbcPurchaseModal && (
                <PurchaseModal setSbcPurchaseModal={props.setSbcPurchaseModal} sbcPurchaseModal={props.sbcPurchaseModal}
                               SBC_Purchase_Func={props.SBC_Purchase_Func} fromAmount={fromAmount} toAmount={toAmount}
                               approveCheck={props.approveCheck} approveStatus={props.approveStatus}
                               rejectStatus={props.rejectStatus} orderStatus={props.orderStatus} purchaseSensor={props.purchaseSensor}
                />
            )}
            {props.sbcCongratsModal && (
                <CongratsModal setSbcCongratsModal={props.setSbcCongratsModal} toAmount={toAmount}
                               sbcCongratsModal={props.sbcCongratsModal} transactionHash={props.transactionHash}/>
            )}
        </div>
    );
}
