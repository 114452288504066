import React, {useEffect, useState} from "react";
import openseaIcon1 from '../../../assets/OpenSea-1.png';
import openseaIcon from '../../../assets/OpenSea.png';
import freeClaimCard from '../../../assets/freeclaim-gif.gif';
import mintPassCard from '../../../assets/mintpass-gif.gif';
import targetIcon from '../../../assets/target.png';
import {Timer} from "./Timer";
import {chainID, firstEdition_addr, mintPass_addr} from "../../contractAddress";
import {useMediaQuery} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {useWeb3Functions} from "../../../services/web3Functions";
import {setFetchNFTStatus} from "../../../redux/reducer/forge";
import {setWalletAddress} from "../../../redux/reducer/wallet";
import {NFTCard} from "./NFTCard";
import bellIcon from "../../../assets/notification.svg";
import {NotifyComponent} from "./NotifyComponent";

export const RightSide = (props: any) => {
    const dispatch = useAppDispatch();
    let [loading, setLoading] = useState(true);
    const [cardStatus, setCardStatus] = useState(-1);
    let isLargeScreen = useMediaQuery("(min-width:1536px)");
    const web3Functions = useWeb3Functions();
    const address = useAppSelector(state => state.wallet.walletAddress);
    const ownedNFTs = useAppSelector(state => state.forge.ownedNFTs);
    const mintAddr = mintPass_addr;

    const nftsByCollection = ownedNFTs.filter((nft: any) => {
        const collectionAddr = nft.contract.address;
        return mintAddr.toUpperCase() === collectionAddr.toUpperCase();
    }).sort((a: any, b: any) => a.tokenId < b.tokenId ? -1 : a.tokenId > b.tokenId ? 1 : 0);

    useEffect(() => {
        // setLoading(true);
        props.getMintData();
    }, [props.currentAccount]);

    useEffect(() => {
        if (address != null) {
            dispatch(setFetchNFTStatus(true));
            web3Functions.FetchNFTs();
        }
    }, [address])

    useEffect(() => {
        if (props.noMetamask) {
            setLoading(false);
        }
    }, [props.noMetamask])

    useEffect(() => {
        if (props.mintData.length > 0) {
            if (props.currentAccount) {
                // if (props.mintData[10] >= 0) {
                //     const mintPassAmount = props.mintData[10];
                //     if (mintPassAmount !== undefined) {
                //         setTimeout(() => {
                //             setLoading(false);
                //         },2000);
                //     }
                // }
                if (Number(props.mintData[0]) > 0) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000);
                }
            } else {
                if (Number(props.mintData[0]) > 0) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000);
                }
            }
        }
    }, [props.mintData])

    useEffect(() => {
        setCardStatus(props.mintType);
    }, [props.mintType])

    return (
        <div
            className={`col-span-12 lg:col-span-5 relative flex flex-col sm:py-0`}
        >
            {loading ? (<div className={`hidden sm:block bg_block text-white w-full justify-center text-center p-5`}>
                <div className={`animation-custom`}>
                    <div className={`bg-skeleton w-full h-56 sm:h-48 2xl:h-56 rounded-lg`}></div>
                </div>
            </div>) : (
                props.isEnded && Number(props.mintData[0]) > 0 ? (
                    <div className={`relative`}>
                        <NotifyComponent/>
                        <div
                            className={`col-span-4 bg_block relative flex flex-col items-center w-full justify-center h-56 sm:h-48 2xl:h-56 px-4 xl:px-12 2xl:px-20 py-2 drops-right-top`}
                        >
                             <span
                                 className="nulshock-text text-white font-bold text-2xl uppercase"> Drop #{props.mintData[5]} Has ended!</span>
                            <span
                                className="poppin-text simple-text text-sm text-xl mt-2">Stay tuned for the next drop.</span>
                            <a className={`opensea-btn poppin-text text-white py-1 px-4 mt-3`}
                               href={`${chainID == '0x89' ? 'https://opensea.io/collection/spinblade-pass' : 'https://testnets.opensea.io/assets/goerli/' + firstEdition_addr + '/0'}`}
                               target="_blank"><img src={openseaIcon1} alt={`openseaIcon`}/>&nbsp;Opensea</a>
                        </div>
                    </div>
                ) : !props.isEnded && Number(props.mintData[0]) > 0 ?
                    <div className={`relative hidden sm:block`}>
                        {props.currentTime < Date.parse(props.mintData[7]) && (
                            <NotifyComponent/>
                        )}
                        <div
                            className={`col-span-5 bg_block relative flex items-center w-full justify-center h-56 sm:h-48 2xl:h-56 px-4 sm:px-12 2xl:px-20 drops-right-top`}
                        >
                            <Timer mintData={props.mintData} currentTime={props.currentTime}
                                   publicPeriod={props.publicPeriod}
                                   privatePeriod={props.privatePeriod} beforeStart={props.beforeStart}
                                   getMintData={props.getMintData}/>
                        </div>

                    </div>
                    :
                    Number(props.mintData[0]) < 1 &&
                    <div
                        className={`col-span-5 bg_block relative flex flex-col items-center w-full justify-center h-56 px-4 sm:px-20 py-2 drops-right-top`}
                    >
                    <span
                        className="nulshock-text text-white font-bold text-2xl uppercase"> Drop #{props.mintData[5]} Has been sold out!</span>
                        <span
                            className="poppin-text simple-text text-sm text-xl mt-2">Stay tuned for the next drop.</span>
                        <a className={`opensea-btn poppin-text text-white py-1 px-4 mt-3`}
                           href={`${chainID == '0x89' ? 'https://opensea.io/collection/spinblade-pass' : 'https://testnets.opensea.io/assets/goerli/' + firstEdition_addr + '/0'}`}
                           target="_blank"><img src={openseaIcon1} alt={`openseaIcon`}/>&nbsp;Opensea</a>
                    </div>
            )
            }
            <div
                className={`${
                    props.isMediumScreen ? "col-span-12" : "col-span-5"
                }  relative flex flex-col items-start w-full mt-4 2xl:mt-8 py-1`}
            >
                <div className={`text-white text-left flex flex-row poppin-text text-xl 2xl:text-2xl mb-2 2xl:mb-5`}>Spinblade Pass &nbsp;
                    <img src={openseaIcon} alt={`OpenSeaIcon`} className="w-6 h-6 mt-1"/>
                </div>
                <div className={`flex flex-row poppin-text w-full`}>

                    {loading ? (<div className={`nft-card text-white justify-center text-center p-3 m-3`}>
                        <div className={`animation-custom`}>
                            <div className={`bg-skeleton w-full h-32 2xl:h-48 rounded-lg`}></div>
                            <div className={`bg-skeleton text-xs rounded-lg h-4 w-10/12 mt-4 2xl:mt-5 m-auto`}></div>
                        </div>
                    </div>) : (
                        <>
                            {!props.currentAccount && (
                                <div className={`flex flex-col justify-center text-center mt-5 sm:mt-10 w-full`}>
                                    <span className={`simple-text poppin-text text-xl`}>Connect your wallet to be able to see your assets.</span>
                                </div>
                            )}

                            {props.currentAccount && props.checkNetwork !== 1 && (
                                <div className={`flex flex-col justify-center text-center mt-5 sm:mt-10 w-full`}>
                                    <span
                                        className={`simple-text poppin-text text-xl`}>Switch network to {chainID == '0x89' ? 'Polygon' : 'Rinkeby Testnet'} to be able to <br/> see your assets.</span>
                                </div>
                            )}
                            {props.currentAccount && props.checkNetwork === 1 && (
                                <>
                                    {Object.keys(nftsByCollection).length < 1 && ((props.publicPeriod || props.isEnded) ?
                                            <div
                                                className={`flex flex-col justify-center text-center mt-5 sm:mt-10 w-full`}>
                                                <span className={`simple-text poppin-text text-xl`}> You don't have any Spinblade pass on your wallet. <br/> If you wish to buy one, you can check <br/></span>
                                                <a className={`flex flex-row justify-center text-blue poppin-text text-xl secondary-link mt-3`}
                                                   href={`${chainID == '0x89' ? 'https://opensea.io/collection/spinblade-pass' : 'https://testnets.opensea.io/assets/goerli/' + firstEdition_addr + '/0'}`}
                                                   target="_blank">secondary marketplaces &nbsp;<img src={targetIcon}
                                                                                                     alt={`targetIcon`}/></a>
                                            </div>
                                            : (props.privatePeriod || props.beforeStart) && (
                                            <div
                                                className={`flex flex-col justify-center text-center mt-5 sm:mt-10 w-full`}>
                                                <span className={`simple-text poppin-text text-xl`}> Early Access is for Spinblade Pass owners only. <br/> You need to have at least one Spinblade Pass <br/> to be able to purchase before the public sale.<br/></span>
                                                <a className={`flex flex-row justify-center text-blue poppin-text text-xl secondary-link mt-3`}
                                                   href={`${chainID == '0x89' ? 'https://opensea.io/collection/spinblade-pass' : 'https://testnets.opensea.io/assets/goerli/' + firstEdition_addr + '/0'}`}
                                                   target="_blank">Get Pass &nbsp;<img src={targetIcon}
                                                                                       alt={`targetIcon`}/></a>
                                            </div>
                                        )
                                    )}
                                    <div className={'flex flex-col gap-y-3 justify-between'}>
                                        <div className={'flex flex-row grid grid-cols-2 md:grid-cols-3'}>
                                            {Object.keys(nftsByCollection).length > 0 &&
                                            (nftsByCollection.map((nft: any, index: any) => (
                                                <NFTCard nft={nft} key={index} cardStatus={cardStatus}
                                                         setCardStatus={setCardStatus} mintType={props.mintType}
                                                         mintTypeUpdate={props.mintTypeUpdate}/>
                                            )))
                                            }
                                        </div>
                                        {Object.keys(nftsByCollection).length > 0 && (
                                            <span className={'pl-4 text-md 2xl:text-lg simple-text poppin-text'}>Select one of your passes</span>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )
                    }
                </div>
            </div>
        </div>
    );
}