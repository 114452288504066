import React from "react";

export const NFTCard = (props: any) => {
    return <>
        <div
            className={`nft-card ${props.mintType == props.nft.tokenId && 'selected-card'} text-white justify-center text-center p-3 m-3`}
            onClick={() => {
                props.mintType != props.nft.tokenId ? props.mintTypeUpdate(props.nft.tokenId) : props.mintTypeUpdate(-1)
            }}
            onMouseOver={() => {
                props.setCardStatus(props.nft.tokenId)
            }}
            onMouseLeave={() => props.setCardStatus(-1)}
        >
            <span
                className={`text-white pass-amount text-xs sm:text-xl`}>x{props.nft.balance}</span>
            <span
                className={`asset-card-1-2 object-fill object-center rounded-lg block`} style={{backgroundImage: `url(${props.nft.rawMetadata?.image})`}}> </span>
            {props.cardStatus == props.nft.tokenId ? (
                <span className="text-white poppin-text use-btn text-sm 2xl:text-base w-full mt-2 py-1 flex items-center">{props.mintType == props.nft.tokenId ?'Remove': 'Use'}</span>) : (
                <p className={`text-xs pt-4 block truncate`}>{props.nft.title}</p>)}
        </div>
    </>
}