import React from 'react'
import {LeftSide} from "./SubComponents/LeftSide";
import "./Drops.css"
import {RightSide} from "./SubComponents/RightSide";
import {Container, useMediaQuery} from "@mui/material";
import {NotifyComponent} from "./SubComponents/NotifyComponent";

export const DropsComponent = (props: any) => {
    let isLargeScreen = useMediaQuery("(min-width:1536px)")
    let isMediumScreen = useMediaQuery("(max-width:980px)");
    let isSmallScreen = useMediaQuery("(max-width:500px)");
    return (
        <div className="pt-24 sm:pt-40">
            <Container >
                <div className={`gap-y-6 xl:gap-y-0 md:gap-x-4 lg:gap-x-8 2xl:gap-x-12 grid grid-cols-8 2xl:max-w-[1200px] max-w-[950px] mx-auto`}
                >
                    <LeftSide currentAccount={props.currentAccount} connectWallet={props.connectWallet}
                              switchNetwork={props.switchNetwork} currentTime={props.currentTime}
                              isEnded={props.isEnded}
                              isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} mintNFT={props.mintNFT}
                              checkNetwork={props.checkNetwork} mintData={props.mintData}
                              mintNFTFunc={props.mintNFTFunc}
                              approveUSDCStatus={props.approveUSDCStatus} approve_USDC_Drop={props.approve_USDC_Drop}
                              approveUSDCCheck={props.approveUSDCCheck} approvePassCheck={props.approvePassCheck}
                              mintType={props.mintType} getMintData={props.getMintData}
                              mintStatus={props.mintStatus} approve_MintPass={props.approve_MintPass}
                              approvePassStatus={props.approvePassStatus}
                              publicPeriod={props.publicPeriod} privatePeriod={props.privatePeriod}
                              beforeStart={props.beforeStart} pendingModal={props.pendingModal}
                              setPendingModal={props.setPendingModal} congratsModal={props.congratsModal}
                              setCongratsModal={props.setCongratsModal} rejectStatus={props.rejectStatus} transactionHash={props.transactionHash}
                              mintSensor={props.mintSensor} setMintType={props.setMintType} noMetamask={props.noMetamask}
                    />
                    <RightSide currentAccount={props.currentAccount} checkNetwork={props.checkNetwork}
                                isEnded={props.isEnded} currentTime={props.currentTime}
                                isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} mintData={props.mintData}
                                mintType={props.mintType} mintTypeUpdate={props.mintTypeUpdate}
                                mintStatus={props.mintStatus} approvePassStatus={props.approvePassStatus}
                                getMintData={props.getMintData}
                                publicPeriod={props.publicPeriod} privatePeriod={props.privatePeriod}
                                beforeStart={props.beforeStart} noMetamask={props.noMetamask}
                    />
                </div>
            </Container>
        </div>
    );
}

