import checkIcon from "../../assets/tick-square.svg";
import React from "react";
import {useAppSelector} from "../../hooks";

export const ForgeNFT = (props) => {
    const selectedItems = useAppSelector(state => state.forge.selectedItems);
    const selectedItemsUri = useAppSelector(state => state.forge.selectedItemsUri);
    const modalState = useAppSelector(state => state.forge.forgeModalState);
    // const imageStyle = {
    //     backgroundImage: url(props.nft.tokenImage)
    // }
    return <div
        className={`nft-part relative rounded-xl bg-[#1F1822] ${(modalState === 2 && !selectedItems.includes(props.nft.tokenId.toString()) && selectedItemsUri.includes(props.nft.tokenName)) ? 'text-[#2a2a2a]': 'text-white'} ${(selectedItems.length > 0 && selectedItems.includes(props.nft.tokenId.toString())) && 'border border-[#A60C53]'} p-3 sm:w-full w-11/12 text-center`}
        onMouseOver={(modalState === 2 && !selectedItems.includes(props.nft.tokenId.toString()) && selectedItemsUri.includes(props.nft.tokenName)) ? () => {} : () => props.overPart(props.nft.tokenId)} onMouseLeave={() => props.leavePart()}
        onClick={(modalState === 2 && !selectedItems.includes(props.nft.tokenId.toString()) && selectedItemsUri.includes(props.nft.tokenName)) ? () => {} :() => props.addItem(props.nft.tokenId, props.nft.tokenType, props.nft.tokenName)}
    >
        {/*<img src={props.nft.tokenImage} className={'w-full sm:h-24 h-32 rounded-lg object-fill object-center'} alt={'nft part'}/>*/}
        <span className={`w-full sm:h-24 sticker-cart h-32 rounded-lg object-fill object-center bg-[#2D2730]`} style={(modalState === 2 && !selectedItems.includes(props.nft.tokenId.toString()) && selectedItemsUri.includes(props.nft.tokenName)) ? {backgroundImage: `url(${props.nft.tokenImage})`, opacity: 0.07} : {backgroundImage: `url(${props.nft.tokenImage})`}}></span>
        <span
            className={`absolute nft-id-badge bg-[#1F1822] top-0 left-0 px-2 py-1 rounded-xl text-sm`}>#{props.nft.tokenId}</span>
        {(selectedItems.length > 0 && selectedItems.includes(props.nft.tokenId.toString())) &&
        <img src={checkIcon} alt={'selected icon'} className={'absolute top-1 right-1'}/>
        }
        {props.partStatus === props.nft.tokenId &&
        <button className={'items-center text-base py-0.5 mt-3 forge-btn w-full'}>
            {(selectedItems.length > 0 && selectedItems.includes(props.nft.tokenId.toString())) ? 'Remove' : 'Add'}
        </button>
        }

        {props.partStatus !== props.nft.tokenId &&
        <span className={'text-base text-center py-0.5 mt-3 block truncate'}>{props.nft.tokenName}</span>
        }
    </div>
}