import React, {useEffect, useRef} from "react";
import firstEditionGif from "../../assets/first-edition-gif.gif";
import Confetti from "react-confetti";
import {chainID} from "../contractAddress";
import useOutsideClick from "../Drops/SubComponents/useOutsideClick";
import {
    setApprovePartStatus,
    setFetchNFTStatus,
    setForgeCongratsModalState, setForgeStatus,
    setSelectedItems,
    setSelectedItemsUri
} from "../../redux/reducer/forge";
import {useAppDispatch, useAppSelector} from "../../hooks";
import mintPassImg from '../../assets/mintpass-gif.gif';
import freeClaimImg from '../../assets/freeclaim-gif.gif';
import discountPassImg from '../../assets/Discount_Pass.gif';
import {useWeb3Functions} from "../../services/web3Functions";

export const ForgeCongrats = (props: any) => {
    const refArea: any = useRef();
    const dispatch = useAppDispatch();
    const web3Functions = useWeb3Functions();

    const transactionHash = useAppSelector(state => state.forge.transactionHash);
    const modalState = useAppSelector(state => state.forge.forgeCongratsModalState);

    const closeEvent = () => {
        dispatch(setForgeCongratsModalState(0));
    }

    useOutsideClick(refArea, () => {
        // dispatch(setForgeCongratsModalState(0));
    });

    useEffect(() => {
        dispatch(setFetchNFTStatus(true));
        web3Functions.FetchNFTs();
        web3Functions.getForgeData();
        dispatch(setSelectedItems([]));
        dispatch(setSelectedItemsUri([]));
        dispatch(setApprovePartStatus(false));
        dispatch(setForgeStatus(false));
    }, [modalState]);

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative relative sm:w-auto sm:mx-auto mx-2 my-6 max-w-md my-6 mx-auto">
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none bg-modal rounded-[15px]"
                        ref={refArea}>
                        {/*header*/}
                        <div
                            className="flex flex-col items-start text-center p-3 pt-0 rounded-t">
                            <button
                                className="p-1 ml-auto mt-0 border-0 text-black float-right text-2xl outline-none focus:outline-none"
                                onClick={() => {
                                    closeEvent();
                                }}
                            >
                                <span className="simple-text font-bold h-6 w-6 text-2xl">×</span>
                            </button>
                            <div className="w-full text-center">
                                <h3 className="sm:text-4xl text-3xl poppin-text text-white text-center uppercase">
                                    Congratulations
                                </h3>
                            </div>
                        </div>
                        {/*body*/}
                        <div className="relative p-8 pt-4 flex-auto poppin-text">
                            <div className={"simple-text sm:text-base text-sm text-center mb-4"}>
                                You successfully forged 1
                                Spinblade {modalState == 1 ? 'Mint Pass' : modalState == 2 ? 'Free Claim Pass' : 'Discount Pass'}
                            </div>
                            <div className={"w-full flex justify-center"}>
                                <img src={modalState == 1 ? mintPassImg : modalState == 2 ? freeClaimImg : discountPassImg}
                                     alt={"Pass Images"} className={"w-[320px] 2xl:w-full rounded-md 2xl:rounded-xl"}/>
                            </div>
                            <div className={"flex flex-row justify-center poppin-text"}>
                                <a
                                    className="text-white poppin-text view-item-btn w-7/12 mt-5 py-2 flex items-center"
                                    href={"/assets"}>View Item</a>
                            </div>
                            <div className={"flex flex-col justify-center poppin-text text-center gap-3 mt-3"}>
                                <p className={"simple-text sm:text-base text-sm"}>You can check the transaction
                                    on &nbsp;
                                    {chainID == '0x89' ? 'polygonscan' : 'etherscan'}</p>
                                <a className={"text-white sm:text-base text-sm text-center underline decoration-1"}
                                   href={`${chainID == '0x89' ? 'https://polygonscan.com/tx/' + transactionHash : 'https://goerli.etherscan.io/tx/' + transactionHash}`}
                                   target={'_blank'}>{transactionHash.slice(0, 10) + "..." + transactionHash.slice(-10)}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Confetti recycle={false} numberOfPieces={500}/>
            </div>
        </>
    );
}