import React, {CSSProperties, useEffect, useState} from "react";
import "./ProgressLine.css";
import exp from "constants";
import NumberFormat from "react-number-format";

export const ProgressLine = ({
                                 // @ts-ignore
                                 label,
                                 backgroundColor = "#212845",
                                 currentAccount = "",
                                 checkNetwork = 0,
                                 // expected format for visual parts
                                 visualParts = [
                                     {
                                         percentage: "0%",
                                         color: "#212845",
                                         value: 0
                                     },
                                 ]
                             }) => {

    // Starting values needed for the animation
    // Mapped by "visualParts" so it can work with multiple values dynamically
    // It's an array of percentage widths
    const [widths, setWidths] = useState(
        visualParts.map(() => {
            return 0;
        })
    );


    useEffect(() => {
        requestAnimationFrame(() => {
            setWidths(
                visualParts.map((item: any) => {
                    return item.percentage;
                })
            );
        });
    }, [visualParts]);


    return (
        <>
            <div className="flex items-center mb-1">
                {visualParts.map((item, index) => (
                    <div
                        key={index + 1}
                        className={`${
                            index === 1 ? "invisible" : "visible"
                        } flex flex-col gap-y-2`}
                        style={{
                            width: widths[index],
                        }}
                    >
                        {currentAccount && checkNetwork === 1 && item.value > 0 &&
                        <>
                            <div className={`${index === 2 ? "justify-end" : "justify-start"} flex items-center`}>
                                <p className="text-xs text-white">
                                    <span className="text-zinc-600 text-sm">
                                        {index === 0 && "Vested"} {index === 2 && "Vesting"}
                                    </span>
                                    <br/> {index === 0 && <NumberFormat value={item.value} displayType={'text'}
                                                                        thousandSeparator={true} suffix={'SBC'}/>}
                                    {index === 2 && <NumberFormat value={item.value} displayType={'text'}
                                                                  thousandSeparator={true} suffix={'vSBC'}/>}
                                </p>
                            </div>
                            <div
                                className={`h-4 rounded-tr-xl rounded-tl-xl border-t border-l border-r border-zinc-600`}
                            ></div>
                        </>
                        }
                    </div>
                ))}
            </div>
            <div
                className="flex items-center w-full"
                // to change the background color dynamically
                style={{
                    backgroundColor,
                    height: "1rem",
                    borderRadius: "50px",
                    overflow: "hidden",
                }}
            >
                {visualParts.map((item, index) => {
                    return (
                        <div
                            key={index + 1}
                            style={{
                                width: widths[index],
                                backgroundColor: item.color,
                            }}
                            className="h-full"
                        ></div>
                    );
                })}
            </div>
            <div className="flex items-center mt-1">
                {visualParts.map((item, index) => (
                    <div
                        key={index + 1}
                        className={`${
                            index === 1 ? "visible" : "invisible"
                        } flex flex-col gap-y-2`}
                        style={{
                            width: widths[index],
                        }}
                    >
                        {currentAccount &&  checkNetwork === 1 && item.value > 0  &&
                        <>
                            <div
                                className={` h-4 rounded-br-xl rounded-bl-xl border-b border-l border-r border-zinc-600`}
                            ></div>
                            <div className="flex items-center justify-end">
                                <p className="text-xs text-white">
                                    <span className="text-zinc-600 text-sm">Claimable</span>
                                    <br/> <NumberFormat value={item.value} displayType={'text'}
                                                        thousandSeparator={true}/>
                                </p>
                            </div>
                        </>
                        }
                    </div>
                ))}
            </div>
        </>
    );
};

