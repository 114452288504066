import React from 'react'
import angleIcon from "../../../assets/angleIcon.svg";
import NumberFormat from 'react-number-format';

export const LeftSide = (props: any) => {
    const unit = 86400;

    return (
        <div
            className={`${
                props.isMediumScreen ? "col-span-12" : "col-span-3"
            } left-col px-8 pt-6 pb-8`}
        >

            <>
                <div className="flex items-center gap-x-6 pb-3">
                    <img src={angleIcon} alt="angleIcon" className="w-14"/>
                    <h1 className="text-white nulshock-text uppercase font-bold text-xl leading-5">
                        <span className="simple-text nulshock-text font-bold text-lg uppercase">
                          Phase #1
                        </span>
                        <br/>
                        Angel Round
                    </h1>
                </div>
                <div className="hr my-4"></div>
                <p className="font-semibold text-sm">
                    <span className="sub-heading uppercase text-xs nulshock-text">on sale</span>
                    <br/>
                    <span className="text-white uppercase text-xs nulshock-text"> <NumberFormat
                        value={props.promiseData[0]} displayType={'text'} thousandSeparator={true}/> SBC</span>
                    <br/>
                    <span className="text-xs font-normal simple-text">
                {(props.promiseData[0] / 1000000000) * 100}% of total supply
              </span>
                    <br/>
                </p>
                <div className="hr my-4"></div>
                <p className="font-semibold text-sm">
                    <span className="sub-heading uppercase text-xs nulshock-text">SBC/USDC</span>
                    <br/>
                    <span
                        className="text-white uppercase text-xs nulshock-text">1 USDC = {props.promiseData[6]} SBC</span>
                    <br/>
                    <span className="text-xs font-normal simple-text">
                1 SBC = <NumberFormat value={(!isNaN(1 / props.promiseData[6])) ? 1 / props.promiseData[6] : 0}
                                      displayType={'text'} thousandSeparator={true}/> USDC
              </span>
                    <br/>
                </p>
                <div className="hr my-4"></div>
                <div className="flex flex-col gap-y-1">
                    <h4 className="sub-heading uppercase nulshock-text text-sm">Terms</h4>
                    <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Phase start:
                </span>
                        <span className="text-xs font-normal simple-text-2">
                  {props.promiseData[2]}
                </span>
                    </div>
                    <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Phase end:
                </span>
                        <span className="text-xs font-normal simple-text-2">
                  {props.promiseData[3]}
                </span>
                    </div>
                    <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Funds to raise:
                </span>
                        <span className="text-xs font-normal simple-text-2">
                  <NumberFormat value={props.promiseData[0] * 0.0125} displayType={'text'} thousandSeparator={true}
                                prefix={'$'}/> USDC
                </span>
                    </div>
                    <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Min. Ticket size:
                </span>
                        <span className="text-xs font-normal simple-text-2">
                  <NumberFormat value={props.promiseData[7]} displayType={'text'} thousandSeparator={true}
                                prefix={'$'}/> USDC
                </span>
                    </div>
                    <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  TGE* date:
                </span>
                        <span className="text-xs font-normal simple-text-2">{props.promiseData[4]}</span>
                    </div>
                    <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  TGE Release:
                </span>
                        <span className="text-xs font-normal simple-text-2"><NumberFormat
                            value={props.promiseData[9]} displayType={'text'} thousandSeparator={true}
                            suffix={'%'}/></span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="text-xs font-normal simple-text">Cliff :</span>
                        <span className="text-xs font-normal simple-text-2">
                  {Number(props.promiseData[8]) > 23 ? Number(props.promiseData[8]) / 24 : Number(props.promiseData[8])} {unit / 3600 === 1 ? 'hour(s)' : unit / 3600 === 24 ? 'day(s)' : 'month(s)'}
                </span>
                    </div>
                    <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Vesting Period:
                </span>
                        <span className="text-xs font-normal simple-text-2">
                  {props.promiseData[10]} {unit / 3600 === 1 ? 'hour(s)' : unit / 3600 === 24 ? 'day(s)' : 'month(s)'}
                </span>
                    </div>
                    <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Vesting Type:
                </span>
                        <span
                            className="text-xs font-normal simple-text-2"> MLV**({props.promiseData[9] ? ((100 - Number(props.promiseData[9])) / Number(props.promiseData[10])).toFixed(1) : 0}% per {unit / 3600 === 1 ? 'hour' : unit / 3600 === 24 ? 'day' : 'month'})
                </span>
                    </div>
                </div>
                <div className="mt-12">
                    <p className="text-xs font-normal simple-text">
                        * Token Generation Event
                    </p>
                    <p className="text-xs font-normal simple-text mt-2">
                        * * Monthly Linear Vesting
                    </p>
                </div>
            </>
        </div>
    );
}