import React, {useRef} from "react";
import firstEditionGif from "../../../assets/first-edition-gif.gif";
import useOutsideClick from "../../Drops/SubComponents/useOutsideClick";
import sbcIcon from "../../../assets/SBC-black.png";
import Confetti from "react-confetti";
import {chainID} from "../../contractAddress";
import NumberFormat from "react-number-format";

export const CongratsModal = (props: any) => {
    const refArea: any = useRef();

    const closeEvent = () => {
        props.setSbcCongratsModal(false);
    }

    useOutsideClick(refArea, () => {
        if (props.sbcCongratsModal) {
            props.setSbcCongratsModal(false);
        }
    });

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative relative sm:w-auto sm:mx-auto mx-2 my-6 max-w-md mx-auto ">
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none bg-modal rounded-2xl"
                        ref={refArea}>
                        {/*header*/}
                        <div
                            className="flex flex-col items-start text-center p-3 pt-0 rounded-t">
                            <button
                                className="p-1 ml-auto mt-0 border-0 text-black float-right text-2xl outline-none focus:outline-none"
                                onClick={() => {
                                    closeEvent();
                                }}
                            >
                                <span className="simple-text font-bold h-6 w-6 text-2xl">×</span>
                            </button>
                            <div className="w-full text-center">
                                <h3 className="sm:text-4xl text-3xl poppin-text text-white text-center uppercase">
                                    Congratulations
                                </h3>
                            </div>
                        </div>
                        {/*body*/}
                        <div className="relative p-8 pt-4 flex-auto poppin-text">
                            <div className={"simple-text sm:text-base text-sm text-center mb-4"}>
                                You successfully Complete your order
                            </div>

                            <div className={"text-white sm:text-2xl text-xl flex flex-row justify-center items-center poppin-text gap-2"}>
                                <img
                                    src={sbcIcon} alt="dollarIcon" className={"w-8 h-8"}/>&nbsp;
                                <NumberFormat
                                    value={props.toAmount}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}/> vSBC
                            </div>
                            <div className={"flex flex-row justify-center poppin-text"}>
                                <button
                                    className="text-white poppin-text view-item-btn w-7/12 mt-5 py-2 flex items-center"
                                    onClick={() => {
                                        closeEvent();
                                    }}>Continue</button>
                            </div>
                            <div className={"flex flex-col justify-center poppin-text text-center gap-3 mt-3"}>
                                <p className={"simple-text sm:text-base text-sm"}>You can check the transaction on
                                    {chainID == '0x89' ? 'Polygonscan' : 'etherscan'}</p>
                                <a className={"text-white sm:text-base text-sm text-center underline decoration-1"}
                                   href={`${chainID == '0x89' ? 'https://polygonscan.com/tx/' + props.transactionHash : 'https://rinkeby.etherscan.io/tx/' + props.transactionHash}`}
                                   target={'_blank'}>{props.transactionHash.slice(0, 10) + "..." + props.transactionHash.slice(-10)}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Confetti recycle={false} numberOfPieces={500}/>
            </div>
            <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}