const usdc_addr = (process.env.REACT_APP_USDC_ADDR as string);
const sbc_addr = (process.env.REACT_APP_SBC_ADDR as string);
const presale_addr = (process.env.REACT_APP_PRESALE_ADDR as string);
const vesting_addr = (process.env.REACT_APP_VESTING_ADDR as string);
const multiSigAdmin_addr = (process.env.REACT_APP_MULTI_ADDR as string);
const mintPass_addr = (process.env.REACT_APP_MINT_PASS_ADDR as string);
const firstEdition_addr = (process.env.REACT_APP_FIRST_EDITION_ADDR as string);
const forgeContract_addr = (process.env.REACT_APP_FORGE_ADDR as string);
const forgeFactory_addr = (process.env.REACT_APP_FORGE_DISCOUNT_ADDR as string);
const mint721_addr = (process.env.REACT_APP_ERC721_ADDR as string);
const rpc_url = (process.env.REACT_APP_RPC as string);
const chainID = (process.env.REACT_APP_CHAIN as string);
const alchemy_key = (process.env.REACT_APP_ALCHEMY_ID as string);

export {
    usdc_addr,
    sbc_addr,
    presale_addr,
    vesting_addr,
    multiSigAdmin_addr,
    mintPass_addr,
    firstEdition_addr,
    forgeContract_addr,
    forgeFactory_addr,
    mint721_addr,
    rpc_url,
    chainID,
    alchemy_key
}