import React, {useEffect, useRef, useState} from "react";
import dollarIcon from "../../../assets/usdc-unit.png";
import sbcIcon from "../../../assets/SBC-black.png";
import checkIcon from "../../../assets/check-icon.png";
import NumberFormat from "react-number-format";
import useOutsideClick from "../../Drops/SubComponents/useOutsideClick";


export const PurchaseModal = (props: any) => {
    const [usdcPending, setUsdcPending] = useState(false);
    const ref: any = useRef();

    useOutsideClick(ref, () => {

        if (props.sbcPurchaseModal) {
            console.log("here");
            // props.setSbcPurchaseModal(false);
        }
    });

    useEffect(() => {
        if (props.approveCheck === 1) {
            setUsdcPending(true);
            if (!props.purchaseSensor) {
                props.SBC_Purchase_Func(props.fromAmount)
            }
        }
    }, [props.approveCheck])

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
            >
                <div
                    className="relative sm:w-auto w-11/12 mx-auto my-6 max-w-xl">
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none bg-modal rounded-2xl"
                    >
                        {/*header*/}
                        <div
                            className="flex flex-col items-start text-center p-3 pt-0 rounded-t">
                            <button
                                className="p-1 ml-auto mt-0 border-0 text-black float-right text-2xl outline-none focus:outline-none"
                                onClick={() => props.setSbcPurchaseModal(false)}
                            >
                                <span className="simple-text font-bold h-6 w-6 text-2xl">×</span>
                            </button>
                            <div className="w-full text-center">
                                <h3 className="text-3xl poppin-text text-white text-center">
                                    Complete Order
                                </h3>
                            </div>
                        </div>
                        {/*body*/}
                        <div className="relative p-8 pt-4 flex-auto">
                            <div className="grid grid-cols-5 sm:gap-4 gap-3">
                                <div className={"col-span-4 flex flex-col justify-center poppin-text"}>
                                    <div className={"simple-text sm:text-lg text-base"}>Vesting Spinblade Coin ( vSBC )</div>
                                    <div
                                        className={"text-white sm:text-xl text-base flex flex-row justify-start items-center"}>
                                        <img
                                            src={sbcIcon} alt="dollarIcon" className={"w-6 h-6"}/>&nbsp;
                                        <NumberFormat
                                            value={props.toAmount}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}/>
                                    </div>
                                </div>
                                <div className={"col-span-1 flex flex-col justify-start poppin-text text-right"}>
                                    <div className={"simple-text sm:text-xl text-base"}>Price</div>
                                    <div
                                        className={"text-white sm:text-xl text-base flex flex-row justify-end items-center"}>
                                        <img
                                            src={dollarIcon} alt="dollarIcon" className={"w-6 h-6"}/>&nbsp;
                                        <NumberFormat
                                            value={props.fromAmount}
                                            displayType={'text'}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}/>
                                    </div>
                                </div>
                            </div>

                            <div className="hr-2 my-4"></div>
                            <div className={"flex flex-col justify-start gap-3"}>

                                <div className={"flex flex-col justify-start simple-text poppin-text"}>
                                    <div className={"text-white text-xl flex flex-row gap-2 mt-3"}>
                                        <div className={'loading-icon'}>
                                            {!usdcPending && props.approveStatus && props.rejectStatus !== 1 ? (
                                                <span className="animate-spin"></span>) : (
                                                <span className="animate-spin-1"></span>)}
                                            {usdcPending && (<span className="animate-spin-2"></span>)}
                                            {!usdcPending ? (
                                                <span className={"loading-number  text-base"}>1</span>
                                            ) : (<img src={checkIcon} className={"loading-check text-base"}/>)}
                                        </div>
                                        Approve Currency
                                    </div>
                                    {!usdcPending && (props.approveStatus || props.rejectStatus === 1) && (
                                        <div className={"sub-element ml-8 "}>
                                            <div className={"text-sm"}>You'll be asked to approve the use of <b
                                                className={'text-white'}>USDC</b> from your wallet. <br/>You only need
                                                to
                                                do this once.
                                            </div>
                                            <button
                                                className={`p-4 mt-2 simple-text waiting-btn outline-none focus:outline-none ${props.rejectStatus === 1 && 'bg-blue w-40'}`}
                                                onClick={() => props.SBC_Purchase_Func(props.fromAmount)}
                                                disabled={props.rejectStatus !== 1}
                                            > {props.rejectStatus === 1 ? 'Continue' : 'Waiting for approval...'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className={"flex flex-col justify-start simple-text poppin-text"}>
                                    <div className={"text-white text-xl flex flex-row gap-2 mt-3"}>
                                        <div className={'loading-icon'}>
                                            {usdcPending && props.rejectStatus !== 2 ? props.orderStatus ? (
                                                <span className="animate-spin"></span>) : (
                                                <span className="animate-spin-2"></span>) : (
                                                <span className="animate-spin-1"></span>)}
                                            {!usdcPending || (props.orderStatus || props.rejectStatus === 2) ? (
                                                <span
                                                    className={"loading-number  text-base"}>2</span>
                                            ) : (<img src={checkIcon} className={"loading-check text-base"}/>)}
                                        </div>
                                        Confirm Order
                                    </div>
                                    {usdcPending && (props.orderStatus || props.rejectStatus === 2) && (
                                        <div className={"sub-element ml-8 "}>
                                            <div className={"text-sm"}>You'll be asked to approve this <b
                                                className={'text-white'}>order</b> from your wallet.
                                            </div>
                                            <button
                                                className={`p-4 mt-2 simple-text waiting-btn outline-none focus:outline-none ${props.rejectStatus === 2 && 'bg-blue w-40'}`}
                                                onClick={() => props.SBC_Purchase_Func(props.fromAmount)}
                                                disabled={props.rejectStatus !== 2}
                                            > {props.rejectStatus === 2 ? 'Continue' : 'Waiting for approval...'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}