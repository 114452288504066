import React from "react";

export const Timer = (props: any) => {
    let seconds: any;
    if (props.beforeStart) {
        if (props.currentTime < Date.parse(props.mintData[7])) {
            seconds = (Date.parse(props.mintData[7]) - Number(props.currentTime)) / 1000;
        }
        if (props.currentTime > Date.parse(props.mintData[7]) + props.mintData[9] && props.currentTime < Date.parse(props.mintData[6])) {
            seconds = (Date.parse(props.mintData[6]) - Number(props.currentTime)) / 1000;
        }
    }
    if (props.privatePeriod) {
        seconds = (Date.parse(props.mintData[7]) + props.mintData[9] * 1000 - Number(props.currentTime)) / 1000;
    }
    if (props.publicPeriod) {
        seconds = (Date.parse(props.mintData[6]) + props.mintData[8] * 1000 - Number(props.currentTime)) / 1000;
    }
    // if (props.currentTime > Date.parse(props.mintData[6]) + Date.parse(props.mintData[8])) {
    //     seconds = 0;
    // }
    let timeDays
    let timeHours
    let timeMinutes
    let timeSeconds

    if (seconds > 0) {
        // Calculating the days, hours, minutes and seconds left
        timeDays = Math.floor(seconds / (60 * 60 * 24))
        timeHours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60))
        timeMinutes = Math.floor((seconds % (60 * 60)) / 60)
        timeSeconds = Math.floor(seconds % 60)

        timeDays = timeDays < 10 ? `0${timeDays}` : timeDays
        timeHours = timeHours < 10 ? `0${timeHours}` : timeHours
        timeMinutes = timeMinutes < 10 ? `0${timeMinutes}` : timeMinutes
        timeSeconds = timeSeconds < 10 ? `0${timeSeconds}` : timeSeconds
    } else {
        timeDays = '00'
        timeHours = '00'
        timeMinutes = '00'
        timeSeconds = '00'
    }

    return (
        <div className={`mt-2 mb-2`}>
            <h1 className="text-sm 2xl:text-base text-white text-center font-bold uppercase">
                                    <span
                                        className="simple-text nulshock-text text-xs">Drop #{props.mintData[5]} {(props.beforeStart && Date.parse(props.mintData[7]) > props.currentTime) ?
                                        "early access starts " : (props.beforeStart && Date.parse(props.mintData[6]) > props.currentTime) ?
                                            "Public Sale starts " : props.privatePeriod ? 'early access ends' : props.publicPeriod && 'Public Sale ends'} on {' '}
                                    </span>
                <span
                    className=" text-white nulshock-text 2xl:text-xs text-[0.65rem]">{Date.parse(props.mintData[7]) > props.currentTime ? props.mintData[7] : props.privatePeriod ? new Date(Date.parse(props.mintData[7]) + props.mintData[9] * 1000).toUTCString().replace('GMT', 'UTC') : props.publicPeriod ? new Date(Date.parse(props.mintData[6]) + props.mintData[8] * 1000).toUTCString().replace('GMT', 'UTC') : props.mintData[6]}</span>
            </h1>
            <div className="flex items-center justify-center flex-wrap sm:flex-nowrap gap-y-3 sm:gap-y-0 mt-1 sm:mt-4">
                <div className="flex flex-col items-center gap-y-2 mr-3">
                    <div className="time-block-drop">
                        <h1 className="text-xl sm:text-2xl 2xl:text-4xl nulshock-text text-white font-bold uppercase text-center">
                            {timeDays}
                        </h1>
                    </div>
                    <span
                        className="text-[11px] text-xs font-semibold simple-text nulshock-text">Days</span>
                </div>
                <div className="flex flex-col items-center gap-y-2 mr-1">
                    <div className="time-block-drop">
                        <h1 className="text-xl sm:text-2xl  2xl:text-4xl nulshock-text text-white font-bold uppercase text-center">
                            {timeHours}
                        </h1>
                    </div>
                    <span
                        className="text-[11px] text-xs font-semibold simple-text nulshock-text">Hours</span>
                </div>
                <h1 className="text-2xl  2xl:text-4xl simple-text font-bold uppercase text-center -mt-10">
                    :
                </h1>
                <div className="flex flex-col items-center gap-y-2 mx-1">
                    <div className="time-block-drop">
                        <h1 className="text-xl sm:text-2xl  2xl:text-4xl nulshock-text text-white font-bold uppercase text-center">
                            {timeMinutes}
                        </h1>
                    </div>
                    <span
                        className="text-[11px] text-xs font-semibold simple-text nulshock-text">Minutes</span>
                </div>
                <h1 className="text-2xl  2xl:text-4xl simple-text font-bold uppercase text-center -mt-10">
                    :
                </h1>
                <div className="flex flex-col items-center gap-y-2 ml-1">
                    <div className="time-block-drop">
                        <h1 className="text-xl sm:text-2xl  2xl:text-4xl nulshock-text text-white font-bold uppercase text-center">
                            {timeSeconds}
                        </h1>
                    </div>
                    <span
                        className="text-[11px] text-xs font-semibold simple-text nulshock-text">Seconds</span>
                </div>
            </div>
        </div>

    )
}