import React, {useEffect, useState} from "react";
import {Container} from "@mui/material";
import "./ForgeComponent.css";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {setFetchNFTStatus, setForgeEmailModalStatus, setModalState} from "../../redux/reducer/forge";
import {useWeb3Functions} from "../../services/web3Functions";
import forgeImg from "../../assets/forge-mintpass.svg";
import {show} from "../../redux/reducer/notification";
import {setWalletAddress} from "../../redux/reducer/wallet";
import {ForgeTimer} from "./ForgeTimer";

export const ForgeComponent = (props: any) => {
    const dispatch = useAppDispatch();
    let [loading, setLoading] = useState(false);
    const [cardStatus, setCardStatus] = useState(0);
    const [currentTime, setCurrentTime] = useState(new Date().getTime());

    const modalState = useAppSelector(state => state.forge.forgeModalState);
    const fetchNFTStatus = useAppSelector(state => state.forge.fetchNFTStatus);
    const getForgeData = useAppSelector(state => state.forge.getForgeData);
    const discountBalance = useAppSelector(state => state.forge.discountPassBalance);
    const web3Functions = useWeb3Functions();
    const fetchUrl = window.location.pathname;
    const queryParameters = new URLSearchParams(window.location.search);
    const emailModal = queryParameters.get("emailModal");

    const openForgeModal = (Index: number) => {

        dispatch(setWalletAddress(props.currentAccount));
        if (props.currentAccount && props.checkNetwork === 1) {
            if (getForgeData[3] || getForgeData[2] >= currentTime / 1000) {
                // dispatch(show({
                //     title: "Forge not started yet!",
                //     type: 'error'
                // }));

                dispatch(setForgeEmailModalStatus(true));
            } else {
                if (getForgeData.length > 0) {
                    dispatch(setModalState(Index));
                } else {
                    return;
                }
            }
        } else if (props.currentAccount && props.checkNetwork !== 1) {
            dispatch(show({
                title: "Please change network!",
                type: 'error'
            }));
        } else {
            dispatch(show({
                title: "Please connect your wallet!",
                type: 'error'
            }))
        }
    }

    const changeOriginalCard = () => {
        setCardStatus(0);
    }

    const changeOverCard = (Index: number) => {
        setCardStatus(Index);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().getTime())
        }, 1000)

        return () => clearInterval(interval)
    }, [])


    useEffect(() => {
        if (props.noMetamask) {
            setLoading(false);
        }
    }, [props.noMetamask]);

    useEffect(() => {
        if (emailModal === "true") {
            dispatch(setForgeEmailModalStatus(true));
        } else {
            dispatch(setForgeEmailModalStatus(false));
        }
    }, [emailModal]);

    useEffect(() => {
        dispatch(setFetchNFTStatus(true));
        web3Functions.FetchNFTs();
        web3Functions.getForgeData();
    }, [modalState]);

    useEffect(() => {
        web3Functions.getDiscountPassBalance();
    },)

    return (
        <div className="">
            <Container maxWidth={"lg"}>
                {/*<span className={`px-4 text-white nulshock-text text-2xl`}>Forge</span>*/}
                <div
                    className={`poppin-text w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-1 sm:gap-2 md:gap-3 ${modalState && 'hidden'}`}>

                    <>
                        <div
                            className={`flex flex-col justify-center items-center text-center mt-5 ${getForgeData[2] >= currentTime / 1000 ? 'sm:mt-20' : 'sm:mt-36'} w-full col-span-full col-span-full`}>

                            {!getForgeData[3] && getForgeData[2] >= currentTime / 1000 && (
                                <ForgeTimer/>
                            )}
                            {getForgeData[3] &&
                            <div className={'flex text-[#A60C53] text-[20px] mt-10 mb-10 p-2 rounded-md'}>
                                PAUSED
                            </div>
                            }
                            <div className={`flex flex-col justify-center text-center w-full sm:w-5/12 mt-16 sm:mt-0`}>
                                    <span
                                        className={`text-white text-base sm:text-xl border-b-2 border-[#A60C53] pb-3`}> Select the Pass you would like to FORGE</span>
                            </div>
                            <div
                                className={`grid grid-cols-2 sm:flex sm:flex-row justify-center items-center text-center xl:gap-x-6 gap-x-2 w-full`}>
                                <div
                                    className={`flex flex-col mint-card px-3 py-4 mt-9 items-center ${modalState === 1 && 'border-t-2 border-[#A60C53]'}`}
                                    onClick={() => openForgeModal(1)} onMouseOver={() => changeOverCard(1)}
                                    onMouseLeave={changeOriginalCard}>
                                    <span
                                        className={`sm:w-[10.5rem] w-[9rem] mint-pass-cart sm:h-48 h-44 rounded-2xl bg-[#2D2730] object-fill object-center`}></span>
                                    {cardStatus === 1 &&
                                    <span
                                        className={'text-white items-center text-lg py-1 mt-3 forge-btn w-full'}><img
                                        src={forgeImg} alt={'forge image'} className={'w-7'}/> Forge</span>
                                    }
                                    {cardStatus !== 1 &&
                                    <span className={'text-white text-lg mt-5'}>Mint Pass</span>}

                                </div>
                                <div
                                    className={`flex flex-col mint-card px-3 py-4 mt-9 items-center ${modalState === 2 && 'border-t-2 border-[#A60C53]'}`}
                                    onClick={() => openForgeModal(2)} onMouseOver={() => changeOverCard(2)}
                                    onMouseLeave={changeOriginalCard}>
                                    <span
                                        className={`sm:w-[10.5rem] w-[9rem] free-claim-cart sm:h-48 h-44 rounded-2xl bg-[#2D2730] object-fill object-center`}></span>
                                    {cardStatus === 2 &&
                                    <span
                                        className={'text-white items-center text-lg py-1 mt-3 forge-btn w-full'}><img
                                        src={forgeImg} alt={'forge image'} className={'w-7'}/> Forge</span>
                                    }
                                    {cardStatus !== 2 &&
                                    <span className={'text-white text-lg mt-5'}>Free Claim</span>
                                    }
                                </div>
                                <div
                                    className={`flex flex-col mint-card px-3 py-4 mt-9 items-center ${modalState === 3 && 'border-t-2 border-[#A60C53]'} relative`}
                                    onClick={() => {
                                        discountBalance > 0 ? openForgeModal(3) :
                                            dispatch(show({
                                                title: "You cannot forge this item because it has been sold out!",
                                                type: 'error'
                                            }));
                                    }}
                                    onMouseOver={() => discountBalance > 0 && changeOverCard(3)}
                                    onMouseLeave={() => discountBalance > 0 && changeOriginalCard}>
                                    <span
                                        className={`${discountBalance == -1 ? 'hidden' : discountBalance > 0 ? 'block text-white py-2 px-4' : 'text-red py-2 px-2'} text-lg absolute right-0 top-0 rounded-bl-lg rounded-tr-2xl bg-[#1F1822] `}>{discountBalance > 0 ? discountBalance : 'Sold Out'}</span>
                                    <span
                                        className={`sm:w-[10.5rem] w-[9rem] discount-pass-cart sm:h-48 h-44 rounded-2xl bg-[#2D2730] object-fill object-center`}></span>
                                    {cardStatus === 3 &&
                                    <span
                                        className={'text-white items-center text-lg py-1 mt-3 forge-btn w-full'}><img
                                        src={forgeImg} alt={'forge image'} className={'w-7'}/>Forge</span>
                                    }
                                    {cardStatus !== 3 &&
                                    <span className={'text-white text-lg mt-5'}>Discount Pass</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                </div>

            </Container>
        </div>
    );
}