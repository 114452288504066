import React from 'react';
import {createSlice} from '@reduxjs/toolkit'

interface walletState {
    connected: any[],
    walletAddress: string,
    balance: number
}

const initialState = {
    connected: [],
    walletAddress: '',
    balance: 0
} as walletState

const walletSlice = createSlice({
    name: 'wallet connect',
    initialState,
    reducers: {
        setConnectEvent(state, action) {
            state.connected = action.payload
        },
        setWalletAddress(state, action) {
            state.walletAddress = action.payload
        },
        setBalance(state, action) {
            state.balance = action.payload;
        }
    }
})

export const {
    setConnectEvent,
    setWalletAddress,
    setBalance
} = walletSlice.actions

export default walletSlice.reducer