import {configureStore} from '@reduxjs/toolkit'

import notificationReducer from '../redux/reducer/notification'
import walletReducer from '../redux/reducer/wallet';
import forgeReducer from '../redux/reducer/forge';

const reducer = {
    notification: notificationReducer,
    wallet: walletReducer,
    forge: forgeReducer
}

const preloadedState = {
    notification: {
        show: false
    }
}

export const store = configureStore({
    reducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch