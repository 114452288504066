import React, {useRef, useState} from "react";
import useOutsideClick from "../Drops/SubComponents/useOutsideClick";
import {useAppDispatch} from "../../hooks";
import {setForgeEmailConfirmStatus, setForgeEmailModalStatus} from "../../redux/reducer/forge";
import noteIcon from "../../assets/document-text.svg";
import bellIcon from "../../assets/notification.svg";
import smsIcon from "../../assets/sms.svg";
import smsActiveIcon from "../../assets/sms-active.svg";
import ReCAPTCHA from "react-google-recaptcha";
import {show} from "../../redux/reducer/notification";

export const MailComponent = () => {
    const dispatch = useAppDispatch();
    const refArea = useRef();
    const recaptchaRef = useRef(null);
    const [emailValue, setEmailValue] = useState('');
    const [inputStatus, setInputStatus] = useState(false);
    const url = new URL(
        "https://api.sender.net/v2/subscribers"
    );
    const myToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDU4YjAwNDJkZmJiMTUwNTNhYzM4OGJkNDg3M2I1ZTg4N2M5ZWYzY2RiYzljZWFmNDFiZDk4NTU4YTlmNzgwMWQ4OTIzZThmY2Q5YjcwYmYiLCJpYXQiOiIxNjc0NjU4NTAwLjU1MjAxNyIsIm5iZiI6IjE2NzQ2NTg1MDAuNTUyMDIxIiwiZXhwIjoiNDgyODI1ODUwMC41NDg1NDAiLCJzdWIiOiI3NzA0MTciLCJzY29wZXMiOltdfQ.EEVgzXiZBIjeoBjvSTflJB4Ac2kSQqS66jOzVwoQr-bRQDXZDVNJ4-zYMttPHVv0m7cjH3iU-BeuY8Qj-RlcsA"
    let headers = {
        "Authorization": "Bearer " + myToken,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = recaptchaRef.current.getValue();
        recaptchaRef.current.reset();
        let data = {
            "email": emailValue,
            "groups": ["aK8gLz"]
        }

        if (token !== '') {
            fetch(url, {
                method: "POST",
                headers, body: JSON.stringify(data)
            }).then((response) => {
                response.json();
                console.log(response);
                dispatch(setForgeEmailConfirmStatus(true));
                dispatch(setForgeEmailModalStatus(false));
            });
        } else {
            dispatch(show({
                title: "Please verify you are not robot!",
                type: 'error'
            }));
        }

    }
    const closeEvent = () => {
        dispatch(setForgeEmailModalStatus(false));
    }

    const checkInput = (value) => {
        if (value === '') {
            setInputStatus(false);
        } else {
            setInputStatus(true);
        }
    }

    useOutsideClick(refArea, () => {
        // dispatch(setForgeCongratsModalState(0));
    });

    return <>
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="relative relative sm:w-auto sm:mx-auto mx-2 my-6 max-w-xl my-6 mx-auto top-[-7%]">
                {/*content*/}
                <div
                    className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none bg-modal rounded-[15px]"
                    ref={refArea}>
                    {/*header*/}
                    <div
                        className="flex flex-col items-start text-center p-3 pt-0 rounded-t">
                        <button
                            className="p-1 ml-auto mt-0 border-0 text-black float-right text-2xl outline-none focus:outline-none"
                            onClick={() => {
                                closeEvent();
                            }}
                        >
                            <span className="simple-text font-bold h-6 w-6 text-2xl">×</span>
                        </button>
                        <div className="w-full text-center">
                            <h3 className="flex flex-row justify-center sm:text-[30px] items-center text-xl poppin-text text-white gap-x-2 text-center">
                                <img src={noteIcon} alt={'note-icon'} className={`w-8`}/> Subscribe to our newsletter!
                            </h3>
                        </div>
                    </div>
                    {/*body*/}
                    <form onSubmit={handleSubmit}>
                        <div className="relative p-5 sm:p-8 pt-1 flex-auto poppin-text">
                            <div
                                className={"simple-text sm:text-[16px] text-[14px] text-start mb-4 border-t-2 border-t-[#443E46] pt-4"}>
                                Don't miss the forging event! Subscribe to our newsletter to get notified about recent
                                updates, upcoming events, and more!
                            </div>
                            <div
                                className={'flex flex-row justify-center items-center bg-[#2D2231] rounded-2xl gap-x-2 px-2 py-3'}>
                                <img src={inputStatus ? smsActiveIcon : smsIcon} alt={'sms-icon'} className={'w-8'}/>
                                <input
                                    type={"email"}
                                    placeholder="e-mail"
                                    value={emailValue} onChange={(e) => {
                                    setEmailValue(e.target.value);
                                    checkInput(e.target.value);
                                }}
                                    className="bg-transparent input w-full outline-none shadow-none text-white p-0 text-xl font-semibold py-1"
                                    id="email-address"
                                    required={true}
                                />
                            </div>
                            <div className={"flex flex-row gap-x-2 justify-center items-center poppin-text mt-5"}>
                                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={recaptchaRef}
                                           badge={"inline"}/>
                                <button type={"submit"}
                                        className="flex flex-row justify-center items-center text-white poppin-text bg-blue rounded-2xl py-4 px-3 text-[26px]">
                                    <img src={bellIcon} alt={'notification'} className={`w-8`}/> Notify Me
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}