import React, {CSSProperties, useEffect, useState} from "react";
import {ScaleLoader} from "react-spinners";
import {VestedProgress} from "./VestedProgress";
import {Vesting} from "../../../Pages/Vesting";
import {VestedTable} from "./VestedTable";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    zIndex: 99,
    position: "absolute"
};


export const RightSide = (props: any) => {
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#fff");

    useEffect(() => {
        if (props.promiseData.length > 0) {
            if (props.currentAccount) {
                if (props.promiseData[16] !== undefined) {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
    }, [props.promiseData])


    return (
        <div
            className={`col-span-12 xl:col-span-5  relative flex flex-col sm:py-0`}
        >
            <VestedProgress currentAccount={props.currentAccount} checkNetwork={props.checkNetwork}
                            isMediumScreen={props.isMediumScreen} isSmallScreen={props.isSmallScreen}
                            promiseData={props.promiseData}/>
            <VestedTable currentAccount={props.currentAccount} checkNetwork={props.checkNetwork}
                         isMediumScreen={props.isMediumScreen} isSmallScreen={props.isSmallScreen}
                         promiseData={props.promiseData}/>
        </div>
    );
}