import React, {CSSProperties, useEffect, useState} from 'react'
import angleIcon from "../../../assets/angleIcon.svg";
import metaMaskIcon from "../../../assets/metamask-icon.png";
import NumberFormat from 'react-number-format';
import {VestingTimer} from './VestedTimer';
import "../Vesting.css"
import {useAppDispatch} from "../../../hooks";
import {show} from "../../../redux/reducer/notification";
import polygonIcon from "../../../assets/Polygon.png";
import {ScaleLoader, SyncLoader} from "react-spinners";
import {sbc_addr} from '../../contractAddress';

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    zIndex: 99,
    position: "relative"
};


export const LeftSide = (props: any) => {
    let [color, setColor] = useState("#fff");
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.promiseData.length > 0) {
            if (props.currentAccount) {
                if (props.promiseData[16] !== undefined) {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }

    }, [props.promiseData])

    const unit = 86400;
    const nextPayment = () => {
        if (props.promiseData[4]) {
            const TGETime = new Date(props.promiseData[4]).getTime() / 1000;
            const cliffPeriod = props.promiseData[8];
            const vestingPeriod = Number(props.promiseData[10])
            const currentTime = Number(props.currentTime) / 1000;

            if (currentTime - TGETime - cliffPeriod * unit < 0) {
                return new Date(Number(TGETime + cliffPeriod * unit) * 1000).toUTCString().replace("GMT", 'UTC');
            } else if (currentTime - TGETime - cliffPeriod * unit - vestingPeriod * unit > 0) {
                return new Date(Number(TGETime + cliffPeriod * unit + vestingPeriod * unit) * 1000).toUTCString().replace("GMT", "UTC");
            } else {

                for (let i = 1; i < vestingPeriod + 1; i++) {
                    if (currentTime - TGETime - cliffPeriod * unit - i * unit < 0) {
                        return new Date(Number(TGETime + cliffPeriod * unit + i * unit) * 1000).toUTCString().replace("GMT", "UTC")
                    }
                }
            }
        } else {
            return "";
        }
    }
    const endTime = () => {
        if (props.promiseData[4]) {
            const TGETime = new Date(props.promiseData[4]).getTime() / 1000;
            const cliffPeriod = props.promiseData[8];
            const vestingPeriod = props.promiseData[10];
            const result = new Date(Number(TGETime + cliffPeriod * unit + vestingPeriod * unit) * 1000);
            return result.toUTCString().replace("GMT", "UTC");
        } else {
            return "";
        }
    }
    const mlvPercent = () => {
        if (props.promiseData[4]) {
            const TGEUnlock = props.promiseData[9];
            const vestingPeriod = props.promiseData[10];
            const result = (100 - TGEUnlock) / vestingPeriod;
            return result.toFixed(1);
        } else {
            return 0;
        }
    }
    useEffect(() => {
        nextPayment();
    }, [])
    const dispatch = useAppDispatch();
    const add_SBC = async () => {
        const {ethereum} = (window as any);
        if (ethereum) {
            try {
                // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                const wasAdded = await ethereum.request({
                    method: "wallet_watchAsset",
                    params: {
                        type: "ERC20",
                        options: {
                            address: sbc_addr,
                            symbol: "SBC",
                            decimals: "18",
                        },
                    },
                });

                if (wasAdded) {
                    dispatch(show({title: "SBC Token Imported!", body: sbc_addr, type: "success"}))
                }
            } catch (error) {
                // TODO: find a way to handle when the user rejects transaction or it fails
            }
        }
    }

    return (
        <div
            className={`col-span-12 xl:col-span-3 bg_block px-8 pt-6 pb-8`}
        >

            <>
                <div className="flex items-center gap-x-6 pb-3">
                    <img src={angleIcon} alt="angleIcon" className="w-14"/>
                    <h1 className="text-white nulshock-text uppercase font-bold text-xl leading-5">
                <span className="simple-text nulshock-text font-bold text-lg uppercase">
                  Vesting
                </span>
                        <br/>
                        Details
                    </h1>

                </div>
                <div className="hr my-4"></div>
                {loading ? (<div className="flex items-center justify-center sm:h-5/6">
                    <ScaleLoader color={color} loading={loading} cssOverride={override} height={50} width={7}
                                 radius={40}
                                 margin={4}/>
                </div>) : (<>
                        {!props.currentAccount && (
                            <>
                                <div className="flex flex-col gap-y-1 text-center justify-evenly lg:h-full">
                                    <p className="text-2xl sm:text-2xl  simple-text text-center font-semibold px-6">Connect
                                        your wallet to view your vesting progress.</p>
                                    <p className="font-semibold text-sm">
                                        <br/>
                                        <button
                                            className="text-white nulshock-text vesting-connect-btn w-full mt-6 py-6 flex items-center justify-between flex-col"
                                            onClick={props.connectWallet}>
                                            Connect Wallet
                                        </button>
                                    </p>
                                </div>
                            </>
                        )}

                        {
                            props.checkNetwork !== 1 && (
                                <>
                                    <div className="flex flex-col gap-y-1 text-center justify-evenly lg:h-full">
                                        <div className='flex flex-col items-center px-3'>
                                            <img src={polygonIcon} alt="PolygonIcon" className="polygon-icon"/>
                                            <h1 className="text-lg sm:text-2xl simple-text font-semibold text-center mt-16 sm:mt-10">
                                                Switch your network to
                                                <button
                                                    className='switchNetwork-btn1 font-semibold'
                                                    onClick={() => props.switchNetwork()}>
                                                    Polygon
                                                </button> to view your vesting progress.
                                            </h1>
                                        </div>
                                        <p className="font-semibold text-sm">
                                            <br/>
                                            <button
                                                className="nulshock-text vesting-switch-btn w-full mt-3 py-5 flex items-center justify-between flex-col"
                                                onClick={props.switchNetwork}>
                                                Switch Network
                                            </button>
                                        </p>
                                    </div>
                                </>
                            )
                        }

                        {!props.isEnded && props.currentAccount && props.checkNetwork === 1 && (
                            <>
                                <div className="flex flex-col gap-y-1 text-center justify-start lg:h-full  py-10">
                                    <div
                                        className="text-2xl sm:text-2xl  simple-text text-center font-semibold sm:mt-20">
                                        <VestingTimer currentTime={props.currentTime} promiseData={props.promiseData}
                                                      presaleStart={props.presaleStart}/>
                                        <br/>
                                        Come back after the <br/> Presale <span className="live">ended</span>.
                                    </div>
                                </div>
                            </>
                        )}
                        {(props.isEnded && props.currentAccount && (props.checkNetwork === 1 &&
                                <>{Number(props.promiseData[17]) > 0 ? <>
                                    <div className="flex flex-col gap-y-1">
                                        <h4 className="sub-heading uppercase nulshock-text text-sm">Terms</h4>
                                        <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  TGE* Date :
                </span>
                                            <span className="text-xs font-normal simple-text-2">
                  {props.promiseData[4]}
                </span>
                                        </div>
                                        <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Cliff Until :
                </span>
                                            <span className="text-xs font-normal simple-text-2">
                            {props.promiseData[4] ? new Date(Number(new Date(props.promiseData[4]).getTime()) + Number(props.promiseData[8] * unit * 1000)).toUTCString().replace('GMT', 'UTC') : ""}
                </span>
                                        </div>
                                        <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  End Date :
                </span>
                                            <span className="text-xs font-normal simple-text-2">
                                        {endTime()}
                </span>
                                        </div>
                                        <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Next Payment :
                </span>
                                            <span className="text-xs font-normal simple-text-2">
                                        {nextPayment()}
                </span>
                                        </div>
                                        <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Vesting Type :
                </span>
                                            <span
                                                className="text-xs font-normal simple-text-2">MLV** ({mlvPercent()}% per {unit / 3600 === 1 ? 'hour' : unit / 3600 === 24 ? 'day' : 'month'})</span>
                                        </div>
                                        <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  TGE Release :
                </span>
                                            <span className="text-xs font-normal simple-text-2"><NumberFormat
                                                value={props.promiseData[9]} displayType={'text'}
                                                thousandSeparator={true}
                                                suffix={'%'}/></span>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <span className="text-xs font-normal simple-text">Total Vesting :</span>
                                            <span className="text-xs font-normal simple-text-2">
                  <NumberFormat value={props.promiseData[17] * props.promiseData[6]} displayType={'text'}
                                thousandSeparator={true}/> vSBC
                </span>
                                        </div>
                                        <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Already Vested :
                </span>
                                            <span className="text-xs font-normal simple-text-2">
                  <NumberFormat value={props.promiseData[19]} displayType={'text'} thousandSeparator={true}/> SBC
                </span>
                                        </div>
                                        <div className="flex items-center justify-between">
                <span className="text-xs font-normal simple-text">
                  Claimable :
                </span>
                                            <span className="text-xs font-normal simple-text-2"> <NumberFormat
                                                value={props.promiseData[12]}
                                                displayType={'text'}
                                                thousandSeparator={true}/> SBC
                </span>
                                        </div>
                                    </div>
                                    <div className="hr my-4"></div>
                                </> : <>
                                    <>
                                        <div className="flex flex-col gap-y-1 text-center justify-evenly lg:h-full">
                                            <p className="text-2xl sm:text-2xl  simple-text text-center font-semibold px-6">You
                                                don't have<br/> any vSBC(vesting).</p>
                                            <p className="font-semibold text-sm">
                                                <br/>
                                                <button
                                                    className="claim-btn-disabled w-full mt-6 py-7 flex items-center justify-between flex-col"
                                                >Not Available
                                                    <span className="font-normal">

                        </span>
                                                </button>

                                                <br/>
                                                <button
                                                    className="text-base font-normal simple-text flex justify-center m-auto"
                                                    onClick={add_SBC}>
                                                    <img src={metaMaskIcon} alt="angleIcon"
                                                         className="w-6 mb-1"/> &nbsp;&nbsp; Add SBC
                                                    token to
                                                    your MetaMask wallet
                                                </button>
                                            </p>
                                        </div>
                                    </>
                                </>
                                }
                                    {Number(props.promiseData[17]) > 0 &&
                                    <>
                                        <p className="font-semibold text-sm">
                                            <br/>
                                            {Number(props.promiseData[12]) !== 0 ?
                                                <button
                                                    className="claim-btn w-full mt-6 py-4 flex items-center justify-between flex-col"
                                                    onClick={props.withdraw_SBC} disabled={props.claimStatus}>Claim
                                                    <span className="font-normal">
                                            {!props.claimStatus ? <><NumberFormat
                                                    value={props.promiseData[12] ? props.promiseData[12] : 0}
                                                    displayType={'text'}
                                                    thousandSeparator={true}/> SBC </> :
                                                <SyncLoader color={color} loading={props.claimStatus} size={10}/>}

                        </span>
                                                </button> :
                                                <button
                                                    className="claim-btn-disabled w-full mt-6 py-7 flex items-center justify-between flex-col"
                                                >Not Available
                                                    <span className="font-normal">

                        </span>
                                                </button>
                                            }

                                            <br/>
                                            <button
                                                className="text-base font-normal simple-text flex justify-center m-auto"
                                                onClick={add_SBC}>
                                                <img src={metaMaskIcon} alt="angleIcon"
                                                     className="w-6 mb-1"/> &nbsp;&nbsp; Add
                                                SBC
                                                token to
                                                metamask wallet
                                            </button>
                                            <br/>
                                        </p>
                                        <div className="mt-12">
                                            <p className="text-xs font-normal simple-text">
                                                * Token Generation Event
                                            </p>
                                            <p className="text-xs font-normal simple-text mt-2">
                                                * * Monthly Linear Vesting
                                            </p>
                                        </div>
                                    </>
                                    }
                                </>)
                        )}
                    </>
                )}
            </>
        </div>
    );
}