import leftIcon from "../../assets/Arrow - Left 3.svg";
import Img404 from "../../assets/404.svg";
export const NotFoundComponent = () => {
    return <>
        <div className={'py-36 sm:py-28 2xl:py-32 itmes-center'}>
            <div className={'image-section pb-8 2xl:pb-16 text-center flex flex-row justify-center'}>
                <img src={Img404} alt={'404-text'} className={`xl:w-6/12 w-8/12`}/>
            </div>
            <div className={`text-white text-center text-xl xl:text-[50px] 2xl:text-[75px] font-bold  pb-8 2xl:pb-12 poppin-text`}>
                Oops! We couldn't find that page.
            </div>
            <div className={'text-center flex justify-center'}>
                <a href={'/'} className={'bg-[#A10B4E] text-white text-lg sm:text-[20px] 2xl:text-[30px] rounded-xl items-center py-2 px-5 flex flex-row gap-x-2 poppin-text'}><img src={leftIcon} alt={'left-arrow'} className={'w-6 sm:w-10'} /> Go Back to Home Page </a>
            </div>
        </div>
    </>
}