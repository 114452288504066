import React from "react";

export const Timer = (props :any) => {
    
    const seconds: any = props.presaleStart > props.currentTime ? ((Math.floor(new Date(props.promiseData[2]).getTime())) - (props.currentTime)) / 1000 : (Math.floor(new Date(props.promiseData[3]).getTime()) - (props.currentTime)) / 1000
    let timeDays
    let timeHours
    let timeMinutes
    let timeSeconds

    if (seconds > 0) {
        // Calculating the days, hours, minutes and seconds left
        timeDays = Math.floor(seconds / (60 * 60 * 24))
        timeHours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60))
        timeMinutes = Math.floor((seconds % (60 * 60)) / 60)
        timeSeconds = Math.floor(seconds % 60)

        timeDays = timeDays < 10 ? `0${timeDays}` : timeDays
        timeHours = timeHours < 10 ? `0${timeHours}` : timeHours
        timeMinutes = timeMinutes < 10 ? `0${timeMinutes}` : timeMinutes
        timeSeconds = timeSeconds < 10 ? `0${timeSeconds}` : timeSeconds
    } else {
        timeDays = '00'
        timeHours = '00'
        timeMinutes = '00'
        timeSeconds = '00'
    }
    
    return (
        <div>
            <h1 className="text-base sm:text-base text-white text-center font-bold uppercase">
                                    <span
                                        className="simple-text nulshock-text text-sm">Phase #1 {(props.presaleStart) > props.currentTime  ?
                                        "starts ":
                                        "ends"} on {' '}
                                    </span>
                <span
                    className=" text-white nulshock-text text-sm">{(props.presaleStart) > props.currentTime ? <>{props.promiseData[2]}</> : <>{props.promiseData[3]}</>}</span>
            </h1>
            <div className="flex items-center justify-center flex-wrap sm:flex-nowrap gap-y-5 sm:gap-y-0 mt-10 sm:mt-4">
                <div className="flex flex-col items-center gap-y-2 mr-3">
                    <div className="time-block">
                        <h1 className="text-2xl sm:text-4xl nulshock-text text-white font-bold uppercase text-center">
                            {timeDays}
                        </h1>
                    </div>
                    <span
                        className="text-xs font-semibold simple-text nulshock-text">Days</span>
                </div>
                <div className="flex flex-col items-center gap-y-2 mr-1">
                    <div className="time-block">
                        <h1 className="text-2xl sm:text-4xl nulshock-text text-white font-bold uppercase text-center">
                            {timeHours}
                        </h1>
                    </div>
                    <span
                        className="text-xs font-semibold simple-text nulshock-text">Hours</span>
                </div>
                <h1 className="text-2xl sm:text-4xl simple-text font-bold uppercase text-center -mt-10">
                    :
                </h1>
                <div className="flex flex-col items-center gap-y-2 mx-1">
                    <div className="time-block">
                        <h1 className="text-2xl sm:text-4xl nulshock-text text-white font-bold uppercase text-center">
                            {timeMinutes}
                        </h1>
                    </div>
                    <span
                        className="text-xs font-semibold simple-text nulshock-text">Minutes</span>
                </div>
                <h1 className="text-2xl sm:text-4xl simple-text font-bold uppercase text-center -mt-10">
                    :
                </h1>
                <div className="flex flex-col items-center gap-y-2 ml-1">
                    <div className="time-block">
                        <h1 className="text-2xl sm:text-4xl nulshock-text text-white font-bold uppercase text-center">
                            {timeSeconds}
                        </h1>
                    </div>
                    <span
                        className="text-xs font-semibold simple-text nulshock-text">Seconds</span>
                </div>
            </div>
        </div>

    )
}