import React, {useEffect, useState} from "react";
import {Container} from "@mui/material";
import targetIcon from "../../assets/target.png";
import {ethers} from "ethers";
import {firstEdition_addr, mintPass_addr, multiSigAdmin_addr, rpc_url, chainID} from "../contractAddress";
import firstEdition_abi from "../../utils/firstEditionAbi.json";
import mintPass_abi from "../../utils/mintPassAbi.json";
import openseaIcon1 from "../../assets/OpenSea-1.png";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useWeb3Functions} from "../../services/web3Functions";
import {setWalletAddress} from "../../redux/reducer/wallet";
import {setFetchNFTStatus} from "../../redux/reducer/forge";
import "../ForgeComponent/ForgeComponent.css"

let firstEditionBox: any;
let mintPassPortal: any;

export const AssetsComponent = (props: any) => {
    const dispatch = useAppDispatch();
    let [loading, setLoading] = useState(true);
    const web3Functions = useWeb3Functions();
    const [assetsData, setAssetsData] = useState([]);
    const [cardStatus, setCardStatus] = useState(-1);
    const [assetsType, setAssetsType] = useState(1);
    const [stickerStatus, setStickerStatus] = useState(-1);
    const ownedNFTs = useAppSelector(state => state.forge.ownedNFTs);
    const fetchNFTStatus = useAppSelector(state => state.forge.fetchNFTStatus);
    const address = useAppSelector(state => state.wallet.walletAddress);
    const mintAddr = (process.env.REACT_APP_ERC721_ADDR as string);
    const mintPassAddr = mintPass_addr;

    const nftsByCollection = ownedNFTs.filter((nft: any) => {
        const collectionAddr = nft.contract.address;
        return mintAddr.toUpperCase() === collectionAddr.toUpperCase();
    }).map((nft: any) => {
        return {
            tokenId: nft.tokenId ? nft.tokenId : '',
            tokenType: nft.tokenType ? nft.tokenType : '',
            tokenName: nft.rawMetadata.name ? nft.rawMetadata.name : '',
            tokenImage: nft.rawMetadata.image ? nft.rawMetadata.image : '',
            tokenTitle: nft.title ? nft.title : '',
            tokenUri: nft.tokenUri ? nft.tokenUri.raw : '',
            tokenTraitType: nft.rawMetadata.attributes[0] ? nft.rawMetadata.attributes[0].trait_type : '',
            tokenTraitValue: nft.rawMetadata.attributes[0] ? nft.rawMetadata.attributes[0].value : ''
        }
    }).sort((a: any, b: any) => a.tokenId < b.tokenId ? -1 : a.tokenId > b.tokenId ? 1 : 0);

    const nftsOfMintPass = ownedNFTs.filter((nft: any) => {
        const collectionAddr = nft.contract.address;
        return mintPassAddr.toUpperCase() === collectionAddr.toUpperCase();
    }).map((nft: any) => {
        return {nft}
    }).sort((a: any, b: any) => a.tokenId < b.tokenId ? -1 : a.tokenId > b.tokenId ? 1 : 0);

    const changeOverCard = (cardId: number) => {
        setCardStatus(cardId);
    }
    const changeOriginalCard = () => {
        setCardStatus(-1)
    }

    const getAssetsData = async () => {
        const {ethereum} = (window as any);

        if (ethereum) {
            setLoading(true);
            const provider = new ethers.providers.JsonRpcProvider(rpc_url);
            const signer = provider.getSigner(props.currentAccount ? props.currentAccount : multiSigAdmin_addr);
            mintPassPortal = new ethers.Contract(mintPass_addr, mintPass_abi, signer);
            firstEditionBox = new ethers.Contract(firstEdition_addr, firstEdition_abi, signer);

            const promises = [];
            promises.push(firstEditionBox.availableNFTs());
            if (props.currentAccount !== "") {
                promises.push(mintPassPortal.balanceOf(props.currentAccount, 0));
                promises.push(mintPassPortal.balanceOf(props.currentAccount, 1));
                promises.push(firstEditionBox.amountsNFT(props.currentAccount));
            }
            let temp: any = [];
            Promise.all(promises).then(responses => {
                responses.forEach((response, index) => {
                    temp.push(response);
                })
                // console.log(temp[0])
                temp[0] = Number(temp[0].amount);
                if (temp.length > 1) {
                    temp[1] = Number(temp[1]);
                    temp[2] = Number(temp[2]);
                    temp[3] = Number(temp[3]);
                }
                setAssetsData(temp);
            })
        }
    }

    const showTab = (index: number) => {
        setAssetsType(index);
    }

    const overSticker = (tokenId: number) => {
        setStickerStatus(tokenId);
    }

    const leaveSticker = () => {
        setStickerStatus(-1);
    }

    useEffect(() => {
        dispatch(setWalletAddress(props.currentAccount));
        getAssetsData();
        setAssetsType(1);
    }, [props.currentAccount]);

    useEffect(() => {
        if (props.noMetamask) {
            setLoading(false);
        }
    }, [props.noMetamask]);

    useEffect(() => {

        if (assetsType === 2) {
            dispatch(setFetchNFTStatus(true));
            web3Functions.FetchNFTs();
        }
    }, [assetsType]);

    useEffect(() => {
        getAssetsData();
    }, [])

    useEffect(() => {
        web3Functions.FetchNFTs();
    }, [address])

    useEffect(() => {
        if (assetsData.length > 0) {
            if (props.currentAccount) {
                if (assetsData.length > 1) {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } else {
            setLoading(true);
        }
    }, [assetsData])

    return (
        <div className="py-40">
            <Container maxWidth={"lg"}>
                <button className={`px-4 ${assetsType === 1 ? 'text-white' : 'text-[#454545]'} nulshock-text text-2xl`}
                        onClick={() => showTab(1)}>Assets
                </button>
                <button className={`px-4 ${assetsType === 2 ? 'text-white' : 'text-[#454545]'} nulshock-text text-2xl `}
                        onClick={() => showTab(2)}>Stickers
                </button>

                <div
                    className={`poppin-text w-full grid 2xl:grid-cols-5 xl:grid-cols-6 md:grid-cols-5 sm:grid-cols-3 grid-cols-2 justify-items-center 2xl:gap-2`}>
                    {props.currentAccount && props.checkNetwork === 1 && (loading ? (
                            <>
                                <div className={`nft-card text-white justify-center text-center p-3 m-3`}>
                                    <div className={`animation-custom`}>
                                        <div
                                            className={`bg-skeleton w-full 2xl:h-48 h-32 rounded-lg mb-4`}></div>
                                        <div
                                            className={`bg-skeleton text-xs rounded-lg h-4  w-10/12 mt-5 m-auto`}></div>
                                    </div>
                                </div>
                                <div className={`nft-card text-white justify-center text-center p-3 m-3`}>
                                    <div className={`animation-custom`}>
                                        <div
                                            className={`bg-skeleton w-full 2xl:h-48 h-32 rounded-lg mb-4`}></div>
                                        <div
                                            className={`bg-skeleton text-xs rounded-lg h-4  w-10/12 mt-5 m-auto`}></div>
                                    </div>
                                </div>
                                <div className={`nft-card text-white justify-center text-center p-3 m-3`}>
                                    <div className={`animation-custom`}>
                                        <div
                                            className={`bg-skeleton w-full 2xl:h-48 h-32 rounded-lg mb-4`}></div>
                                        <div
                                            className={`bg-skeleton text-xs rounded-lg h-4  w-10/12 mt-5 m-auto`}></div>
                                    </div>
                                </div>
                            </>
                        ) : (assetsType == 2 ?
                                <>
                                    {fetchNFTStatus ?
                                        <>
                                            <div className={`nft-card text-white justify-center text-center p-3 m-3`}>
                                                <div className={`animation-custom`}>
                                                    <div
                                                        className={`bg-skeleton w-full 2xl:h-48 h-32 rounded-lg mb-4`}></div>
                                                    <div
                                                        className={`bg-skeleton text-xs rounded-lg h-4  w-10/12 mt-5 m-auto`}></div>
                                                </div>
                                            </div>
                                            <div className={`nft-card text-white justify-center text-center p-3 m-3`}>
                                                <div className={`animation-custom`}>
                                                    <div
                                                        className={`bg-skeleton w-full 2xl:h-48 h-32 rounded-lg mb-4`}></div>
                                                    <div
                                                        className={`bg-skeleton text-xs rounded-lg h-4  w-10/12 mt-5 m-auto`}></div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        Object.keys(nftsByCollection).length > 0 ?
                                            nftsByCollection.map((nft: any, index: any) => (
                                                <div key={index}>
                                                    <a href={`${chainID == '0x89' ? 'https://opensea.io/assets/matic/' + mintAddr + '/' + nft.tokenId : 'https://testnets.opensea.io/assets/goerli/' + mintAddr + '/' + nft.tokenId}`}
                                                       target="_blank"
                                                       className={`nft-card text-white justify-center text-center p-3 m-3`}
                                                       onMouseOver={() => overSticker(nft.tokenId)}
                                                       onMouseLeave={() => leaveSticker()}
                                                    >
                                                    <span
                                                        className={`text-white pass-amount text-xs sm:text-xl`}>#{nft.tokenId}</span>
                                                        <span
                                                            className={`w-full ${stickerStatus === nft.tokenId ? 'sticker-cart-asset-1' : 'sticker-cart-asset-2'} bg-[#2D2730] object-fill object-center rounded-lg block mb-4`}
                                                            style={{backgroundImage: `url(${nft.tokenImage})`}}></span>
                                                        {stickerStatus !== nft.tokenId && (
                                                            <span
                                                                className={`text-sm py-2`}>{nft.tokenName}</span>)}
                                                        {stickerStatus === nft.tokenId && (
                                                            <span
                                                                className={`opensea-btn poppin-text text-white py-0 2xl:py-2 px-1 2xl:px-3 mt-0 2xl:mt-3 flex flex-row justify-center`}>
                                                            <img src={openseaIcon1} alt={`openseaIcon`}/>
                                                            <span className={`text-sm 2xl:text-lg`}>Opensea</span>
                                                        </span>)}
                                                    </a>
                                                </div>
                                            ))
                                            : <div
                                                className="poppin-text w-full mt-10 text-[#767575] text-center col-span-full text-lg">
                                                You don't own any Stickers to forge a Spinblade Pass.
                                                <p className={`flex sm:flex-row flex-col justify-center simple-text poppin-text text-lg mt-3`}>You
                                                    can check &nbsp;
                                                    <a className={`flex flex-row text-center text-blue poppin-text text-lg secondary-link `}
                                                       href={'https://opensea.io/collection/spinblade-stickers'}
                                                       target={'_blank'}
                                                    >secondary marketplaces &nbsp;
                                                        <img src={targetIcon} alt={`targetIcon`}/>
                                                    </a>
                                                </p>
                                            </div>
                                    }
                                </> :
                                <>
                                    {assetsData?.[3] > 0 && (
                                        <a
                                            href={`${chainID == '0x89' ? 'https://opensea.io/assets/matic/' + firstEdition_addr + '/0' : 'https://testnets.opensea.io/assets/goerli/' + firstEdition_addr + '/0'}`}
                                            target="_blank"
                                            className={`nft-card text-white justify-center text-center p-3 m-3`}
                                            onMouseOver={() => changeOverCard(3)} onMouseLeave={changeOriginalCard}>
                                    <span
                                        className={`text-white pass-amount text-xl`}>x{assetsData[3] ? assetsData[3] : 0}</span>
                                            <span
                                                className={`${cardStatus === 3 ? 'asset-card-3-1' : 'asset-card-3-2'} w-full object-fill object-center rounded-lg block mb-4`}> </span>
                                            {cardStatus !== 3 && (
                                                <span
                                                    className={`text-base 2xl:text-lg truncate block`}>First Edition Box</span>)}
                                            {cardStatus === 3 && (
                                                <span
                                                    className={`opensea-btn poppin-text text-white py-0 2xl:py-2 px-1 2xl:px-3 mt-0 2xl:mt-3 flex flex-row justify-center`}>
                                                    <img src={openseaIcon1} alt={`openseaIcon`}/>
                                                    <span className={`text-sm 2xl:text-lg`}>Opensea</span>
                                                </span>
                                            )}
                                        </a>
                                    )}

                                    {Object.keys(nftsOfMintPass).length > 0 && (
                                        nftsOfMintPass.map((nft: any, index: any) => (
                                            <div key={index}>
                                                <a href={`${chainID == '0x89' ? 'https://opensea.io/assets/matic/' + mintPass_addr + '/' + nft.nft.tokenId : 'https://testnets.opensea.io/assets/goerli/' + mintPass_addr + '/' + nft.nft.tokenId}`}
                                                   target="_blank"
                                                   className={`nft-card text-white justify-center text-center p-3 m-3`}
                                                   onMouseOver={() => changeOverCard(nft.nft.tokenId)}
                                                   onMouseLeave={changeOriginalCard}>
                                    <span
                                        className={`text-white pass-amount text-xl`}>x{nft.nft.balance}</span>
                                                    <span
                                                        className={`${cardStatus === nft.nft.tokenId ? 'asset-card-2-1' : 'asset-card-2-2'} w-full object-fill object-center rounded-lg block mb-4`}
                                                        style={{backgroundImage: `url(${nft.nft.rawMetadata?.image})`}}> </span>
                                                    {cardStatus !== nft.nft.tokenId && (
                                                        <span
                                                            className={`text-sm 2xl:text-base truncate block`}>{nft.nft.title}</span>)}
                                                    {cardStatus === nft.nft.tokenId && (
                                                        <span
                                                            className={`opensea-btn poppin-text text-white py-0 2xl:py-2 px-1 2xl:px-3 mt-0 2xl:mt-3 flex flex-row justify-center`}><img
                                                            src={openseaIcon1} alt={`openseaIcon`}/>
                                                    <span className={`text-sm 2xl:text-lg`}>Opensea</span>
                                                </span>)}
                                                </a>
                                            </div>
                                        ))
                                    )}

                                    {(assetsData[3] < 1 && Object.keys(nftsOfMintPass).length < 1) && (
                                        <div
                                            className={`flex flex-col justify-center text-center mt-10 sm:mt-20 w-full col-span-full col-span-full`}>
                                        <span
                                            className={`simple-text poppin-text text-xl`}> You don't have any assets yet. <br/> </span>
                                            <p className={`flex sm:flex-row flex-col justify-center text-center simple-text poppin-text text-xl mt-3`}>You
                                                can check &nbsp;
                                                <a className={`flex flex-row justify-center text-blue poppin-text text-xl secondary-link `}
                                                   href={`${chainID == '0x89' ? 'https://opensea.io/collection/spinblade-pass' : 'https://testnets.opensea.io/assets/goerli/' + firstEdition_addr + '/0'}`}
                                                   target="_blank">secondary marketplaces &nbsp;
                                                    <img src={targetIcon} alt={`targetIcon`}/>
                                                </a>
                                            </p>
                                        </div>
                                    )}

                                </>
                        )
                    )}
                    {props.currentAccount && props.checkNetwork !== 1 && (
                        <>
                            <div
                                className={`simple-text poppin-text sm:text-xl text-base text-center py-40 w-full col-span-full`}>
                                Switch network to {chainID === '0x89' ? 'Polygon' : 'Rinkeby Testnet'} to be able to see
                                your assets.
                            </div>
                        </>
                    )}
                    {!props.currentAccount && (<>
                        <div
                            className={`simple-text poppin-text sm:text-xl text-base text-center py-40 w-full col-span-full`}>
                            Connect your wallet to be able to see your assets.
                        </div>
                    </>)
                    }
                </div>

            </Container>
        </div>
    )
}