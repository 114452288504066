import React, {useRef} from "react";
import {useAppDispatch} from "../../hooks";
import heartIcon from "../../assets/heart.svg";
import {setForgeEmailConfirmStatus} from "../../redux/reducer/forge";

export const EmailConfirm = () => {
    const dispatch = useAppDispatch();
    const refArea = useRef();
    const closeEvent = () => {
        dispatch(setForgeEmailConfirmStatus(false));
    }

    return <>
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="relative relative sm:w-auto sm:mx-auto mx-2 my-6 max-w-xl my-6 mx-auto top-[-7%]">
                {/*content*/}
                <div
                    className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none bg-modal rounded-[15px]"
                    ref={refArea}>
                    {/*header*/}
                    <div
                        className="flex flex-col items-start text-center p-3 pb-0 rounded-t">
                        <button
                            className="p-1 ml-auto mt-0 border-0 text-black float-right text-2xl outline-none focus:outline-none"
                            onClick={() => {
                                closeEvent();
                            }}
                        >
                            <span className="simple-text font-bold h-6 w-6 text-2xl">×</span>
                        </button>
                        <div className="w-full text-center">
                            <h3 className="flex flex-row justify-center sm:text-[30px] items-center text-xl poppin-text text-white gap-x-2 text-center">
                                <img src={heartIcon} alt={'note-icon'} className={`w-8`}/> Thank you!
                            </h3>
                        </div>
                    </div>
                    {/*body*/}
                    <div className="relative p-5 sm:p-8 pt-1 flex-auto poppin-text">
                        <div
                            className={"simple-text sm:text-[16px] text-[14px] text-start mb-4 border-t-2 border-t-[#443E46] pt-4 px-5"}>
                            You've successfully subscribed to our newsletter! We will notify you when the forging event starts!
                        </div>
                        <div className={"flex flex-row gap-x-2 justify-center items-center poppin-text mt-5"}>
                            <button type={"submit"} onClick={() => {
                                closeEvent();
                            }}
                                    className="flex flex-row justify-center items-center text-white poppin-text bg-blue rounded-2xl py-2 px-9 text-[26px]">
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}