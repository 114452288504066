import React from 'react'
import "./..//AngelRound.css";
import polygonIcon from "../../../assets/Polygon.png";

export const SwitchNetwork = (props : any) => {
    return (
        <div className="w-full flex flex-col justify-center">
            <div className='flex flex-col items-center '>
                <img src={polygonIcon} alt="PolygonIcon"/>
                <h1 className="text-lg sm:text-xl nulshock-text simple-text font-bold uppercase text-center mt-16 sm:mt-10">
                    Please, <span
                    className=" ">Switch your network to </span>
                </h1>
                <button
                    className='switchNetwork-btn px-8 py-3 nulshock-text font-semibold uppercase flex items-center gap-x-3 justify-center'
                    onClick={() => props.switchNetwork()}>
                    <span>Polygon</span>
                </button>
            </div>
        </div>
    )
}