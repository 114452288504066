import {useAppDispatch} from "../hooks";
import {Navbar} from "../Components/Navbar/Navbar";
import {VestingComponent} from "../Components/Vesting/VestingComponent";

export const Vesting = (props: any) => {
    const dispatch = useAppDispatch();

    return (
        <div>
            <Navbar
                currentAccount={props.currentAccount}
                setCurrentAccount={props.setCurrentAccount}
                setCheckNetwork={props.setCheckNetwork}
                connectWallet={props.connectWallet}
                switchNetwork={props.switchNetwork}
                promiseData={props.promiseData}
                checkNetwork={props.checkNetwork}
                getContractData={props.getContractData}
                disconnectWallet={props.disconnectWallet}
            />
            <VestingComponent
                connectWallet={props.connectWallet}
                currentAccount={props.currentAccount}
                promiseData={props.promiseData}
                presaleStart={props.presaleStart}
                isEnded={props.isEnded}
                getContractData={props.getContractData}
                checkNetwork={props.checkNetwork}
                switchNetwork={props.switchNetwork}
            />
        </div>
    );
};
