import React, {useState, useEffect} from 'react'
import {LeftSide} from "./SubComponents/LeftSide";
import "./Vesting.css"
import {RightSide} from "./SubComponents/RightSide";
import {Container, useMediaQuery} from "@mui/material";
import {ethers} from "ethers";
import vesting_abi from "../../utils/VESTINGabi.json";
import BigNumber from "bignumber.js";
import {show} from "../../redux/reducer/notification";
import {useAppDispatch} from "../../hooks";
import Firebase from "./SubComponents/Firebase";

import {usdc_addr, sbc_addr, presale_addr, vesting_addr, multiSigAdmin_addr} from '../contractAddress';

let VestingPortal: any;

export const VestingComponent = (props: any) => {
    let isMediumScreen = useMediaQuery("(max-width:980px)");
    let isSmallScreen = useMediaQuery("(max-width:500px)");
    const [currentTime, setCurrentTime] = useState(new Date().getTime());
    const [claimStatus, setClaimStatus] = useState(false);
    const [claimable, setClaimable] = useState(false);
    let [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const unit = 86400;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().getTime())
            const current = new Date().getTime();
            const endTime = Math.floor(new Date(props.promiseData[3]).getTime());
            if (current == endTime) {
                props.getContractData();
                setLoading(true);
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const withdraw_SBC = async () => {
        const {ethereum} = (window as any);

        if (ethereum) {
            setClaimStatus(true)
            try {
                const provider = new ethers.providers.Web3Provider(ethereum);

                const signer = provider.getSigner();
                VestingPortal = new ethers.Contract(vesting_addr, vesting_abi, signer);

                let withdrawable_ = await VestingPortal.getWithdrawable(props.currentAccount);
                let TGEtime = await VestingPortal.getTGETime();
                let cliffPeriod = await VestingPortal.CLIFF_PERIOD();
                let vestingPeriod = await VestingPortal.VESTING_PERIOD();
                let withdrawable = new BigNumber(Number(withdrawable_)).dividedBy(10 ** 18).toFixed(0);
                if (Number(withdrawable) === 0) {
                    dispatch(show({title: "Withdraw Error!", body: "Nothing to withdraw", type: 'error'}))
                    return;
                }

                for (let i = 0; i < vestingPeriod; i++) {
                    if ((Date.now() / 1000 > TGEtime + unit / 2 && Date.now() / 1000 + unit * vestingPeriod) || (Date.now() / 1000 > TGEtime + (vestingPeriod + 0.5) * unit + i * unit && TGEtime + (vestingPeriod + 1) * unit + i * unit)) {
                        dispatch(show({
                            title: "Withdraw Error!",
                            body: "You can claim only first half time every period! Please wait until the next payment event.",
                            type: 'error'
                        }))
                        return;
                    }
                }
                let withdrawn = await VestingPortal.withdrawToken();
                await withdrawn.wait();
                const transHash = withdrawn.hash;
                Firebase.writeData(props.currentAccount, Number(withdrawable), transHash) //firebase write hook.
                await props.getContractData();
                dispatch(show({title: "Withdraw Success!", body: "$SBC Token withdraw success!", type: 'success'}))
                setClaimStatus(false);
            } catch (e: any) {
                dispatch(show({title: "Withdraw Error!", body: e.message, type: 'error'}));
                setClaimStatus(false);
            }
        }
    }

    const testFirebase = () => {
        const amount = 1252
        const address = "0x2d504E0B2363F9c5fCEC571d62476F08e36CCA0d123"
        const transHash = "0x0901a1ecdfc30dfd554c268a74677b14efca99f682f730ce398361e7a3da6e56"
        Firebase.writeData(address, amount, transHash) //firebase write hook.
    }

    return (
        <div className="py-40">
            <Container maxWidth={"lg"}>
                <div className={`gap-y-6 xl:gap-y-0 xl:gap-x-12 grid grid-cols-8 `}
                >
                    <LeftSide currentAccount={props.currentAccount} promiseData={props.promiseData}
                              presaleStart={props.presaleStart} connectWallet={props.connectWallet}
                              switchNetwork={props.switchNetwork} currentTime={currentTime}
                              isEnded={props.isEnded} add_whitelist={props.add_whitelist} withdraw_SBC={withdraw_SBC}
                              isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} testFirebase={testFirebase}
                              checkNetwork={props.checkNetwork} claimStatus={claimStatus}/>
                    <RightSide currentAccount={props.currentAccount} promiseData={props.promiseData}
                               presaleStart={props.presaleStart} checkNetwork={props.checkNetwork}
                               isEnded={props.isEnded} add_whitelist={props.add_whitelist} currentTime={currentTime}
                               isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen}/>
                </div>
            </Container>
        </div>
    );
}

