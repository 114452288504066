import {useEffect} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import goLinkIcon from "./../../../assets/goLinkIcon.svg";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import "../Vesting.css"
import {useMediaQuery} from "@mui/material";
import Firebase from "./Firebase";


function createData(time: string, amount: string, hash: string, hashOrigin: string) {
    return {time, amount, hash, hashOrigin};
}

let rows: any;
export const VestedTable = (props: any) => {
    const matches = useMediaQuery('(max-width:640px)');
    useEffect(() => {
        rows = [];
        Firebase.readData(props.currentAccount).then((res: any) => {
            if (res) {
                res.map((data: any) => {
                    rows.push(createData(new Date(data.timestamp).toUTCString().replace('GMT', 'UTC'), data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), data.hash.slice(0, 8) + '...' + data.hash.slice(-7), data.hash))
                })
            }
        })
    }, [props.promiseData[12]])
    if (rows){
        rows.sort((a: any, b: any) => (new Date(a.time) > new Date(b.time) ? -1 : 1));
    } else {
        rows = []
    }

    return (
        <div
            className={`${
                props.isMediumScreen ? "col-span-12" : "col-span-5"
            } bg_block relative flex items-start justify-center w-full mt-8 py-4`}
        >
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: "none",
                    background: "transparent",
                    height: "27rem",
                    padding: matches ? "0 1rem" : "0 3rem",
                    overflowY: "auto"
                }}
            >
                <Table
                    sx={{
                        minWidth: 500,
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "1px solid rgba(255, 255, 255, 0.16)",
                        },
                    }}
                    aria-label="custom pagination table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{color: "#7A757B", width: "40%", padding: "10px 0px"}}
                            >
                                <div className="flex items-center justify-between w-full border-r border-zinc-600 pr-2">
                                    <span>Claim Timestamp</span>
                                    <div
                                        className="flex flex-col items-center text-sm"
                                        style={{color: "#7A757B"}}
                                    >
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            className="text-white"
                                            style={{color: "#7A757B"}}
                                        />
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            className="text-white -mt-1"
                                            style={{color: "#7A757B"}}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                sx={{color: "#7A757B", width: "25%", padding: "10px 0px"}}
                            >
                                <div className="flex items-center justify-between w-full border-r border-zinc-600 px-2">
                                    <span>Amount</span>
                                    <div
                                        className="flex flex-col items-center text-sm"
                                        style={{color: "#7A757B"}}
                                    >
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            className="text-white"
                                            style={{color: "#7A757B"}}
                                        />
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            className="text-white -mt-1"
                                            style={{color: "#7A757B"}}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                sx={{color: "#7A757B", width: "35%", padding: "10px 8px"}}
                                align="left"
                            >
                                Transaction Hash
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: "#7A757B",
                                    padding: "10px 0px",
                                }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.currentAccount && props.checkNetwork === 1 && rows.length > 0 ? rows.length > 0 ? rows.map((row: any, ind: any) => (
                                <TableRow
                                    key={ind}
                                    sx={{boxShadow: "none", borderBottom: "none", border: "none"}}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{fontSize: "13px", color: "white", padding: "18px 0"}}
                                    >
                                        {row.time}
                                    </TableCell>
                                    <TableCell
                                        // style={{ width: 160 }}
                                        sx={{fontSize: "13px", color: "white", padding: "18px 8px"}}
                                        align="left"
                                    >
                                        {row.amount}
                                    </TableCell>
                                    <TableCell
                                        // style={{ width: 160 }}
                                        sx={{fontSize: "13px", color: "white", padding: "18px 8px"}}
                                        align="left"
                                    >
                                        {row.hash}
                                    </TableCell>
                                    <TableCell
                                    >
                                        <a className="flex items-center justify-end w-5"
                                           href={`https://rinkeby.etherscan.io/tx/${row.hashOrigin}`} target="_blank">
                                            <img src={goLinkIcon} alt={goLinkIcon} className="w-10"/>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow
                                sx={{boxShadow: "none", borderBottom: "none", border: "none"}}
                            >
                                <TableCell
                                    // style={{ width: 160 }}
                                    sx={{fontSize: "13px", color: "white", padding: "5em 0", border: "none!important"}}
                                    align="left"
                                >

                                </TableCell>
                                <TableCell
                                    // style={{ width: 160 }}
                                    sx={{
                                        fontSize: "16px",
                                        color: "#746F75",
                                        padding: "5em 0",
                                        border: "none!important"
                                    }}
                                    align="center"
                                >
                                    Not Available
                                </TableCell>
                            </TableRow> :
                            <TableRow
                                sx={{boxShadow: "none", borderBottom: "none", border: "none"}}
                            >
                                <TableCell
                                    // style={{ width: 160 }}
                                    sx={{fontSize: "13px", color: "white", padding: "5em 0", border: "none!important"}}
                                    align="left"
                                >

                                </TableCell>
                                <TableCell
                                    // style={{ width: 160 }}
                                    sx={{
                                        fontSize: "16px",
                                        color: "#746F75",
                                        padding: "5em 0",
                                        border: "none!important"
                                    }}
                                    align="center"
                                >
                                    Not Available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
