import bellIcon from "../../../assets/notification.svg";
import React from "react";
import {setForgeEmailModalStatus} from "../../../redux/reducer/forge";
import {useAppDispatch} from "../../../hooks";

export const NotifyComponent = () => {
    const dispatch = useAppDispatch();
    const openModal = () => {
        dispatch(setForgeEmailModalStatus(true));
    }
    return (
        <div className={`flex flex-row justify-center sm:justify-end sm:mb-3 mt-3 sm:mt-0 sm:absolute z-10 relative sm:top-[-75px] right-0`}>
            <button onClick={() => openModal()}
                className="flex flex-row justify-center w-full sm:w-auto items-center text-white poppin-text bg-blue rounded-xl 2xl:rounded-2xl py-3 px-3 text-[22px] 2xl:text-[26px]">
                <img src={bellIcon} alt={'notification'} className={`w-8`}/> Notify Me
            </button>
        </div>
    )
}