import React, {useEffect, useState} from 'react'
import './App.css'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import {Presale} from './Pages/Presale'
import {Vesting} from './Pages/Vesting'
import {Assets} from "./Pages/Assets";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {ethers} from "ethers";
import usdc_abi from "./utils/USDCabi.json";
import sbc_abi from "./utils/SBCabi.json";
import presale_abi from "./utils/PRESALEabi.json";
import vesting_abi from "./utils/VESTINGabi.json";
import BigNumber from "bignumber.js";
import {useAppDispatch} from "./hooks";
import {show} from "./redux/reducer/notification";
import {
    usdc_addr,
    sbc_addr,
    presale_addr,
    vesting_addr,
    multiSigAdmin_addr,
    rpc_url,
    chainID
} from './Components/contractAddress';
import {Drops} from "./Pages/Drops";
import {Forge} from "./Pages/Forge";
import {useMediaQuery} from "@mui/material";
import {NotFound} from "./Pages/404";

let USDCPortal: any;
let SBCPortal: any;
let PresalePortal: any;
let VestingPortal: any;

function App() {
    const dispatch = useAppDispatch();

    const [currentAccount, setCurrentAccount] = useState("");
    const [checkNetwork, setCheckNetwork] = useState(0);
    const [presaleStart, setPresaleStart] = useState(0);
    const [isEnded, setIsEnded] = useState(false);
    const [promiseData, setPromiseData] = useState([]);
    const unit = 86400;
    const [approveCheck, setApproveCheck] = useState(0);
    let [orderStatus, setOrderStatus] = useState(false);
    let [approveStatus, setApproveStatus] = useState(false);
    let [checkWhitelist, setCheckWhitelist] = useState(false);
    const [whitelistStatus, setWhitelistStatus] = useState(false);
    const [noMetamask, setNoMetamask] = useState(false);
    const [sbcPurchaseModal, setSbcPurchaseModal] = useState(false);
    const [sbcCongratsModal, setSbcCongratsModal] = useState(false);
    const [rejectStatus, setRejectStatus] = useState(0);
    const [purchaseSensor, setPurchaseSensor] = useState(false);
    const [transactionHash, setTransactionHash] = useState('');

    const checkIfWalletIsConnected = async () => {
        try {
            const {ethereum} = (window as any);

            if (!ethereum) {
                dispatch(show({title: "Make sure you have metamask!", body: currentAccount, type: "error"}));
                setNoMetamask(true);
                console.log("Make sure you have metamask!");
            } else {
                setNoMetamask(false);
            }

            const accounts = await ethereum.request({method: "eth_accounts"});
            const chainId = await ethereum.request({method: "eth_chainId"});
            const connectionStatus = localStorage.getItem("account");
            const account = accounts[0];
            if (accounts.length !== 0 && connectionStatus !== null) {
                if (chainId === chainID) {
                    setCheckNetwork(1);
                }
                setCurrentAccount(account);
            } else {
                console.log("No authorized account found");
            }
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     checkIfWalletIsConnected();
    //     const {ethereum} = (window as any);
    //     if (ethereum) {
    //         getContractData();
    //     }
    // }, []);
    //
    // useEffect(() => {
    //     const {ethereum} = (window as any);
    //     if (ethereum) {
    //         getContractData();
    //     }
    // }, [currentAccount]);

    const connectWallet = async () => {
        try {
            const {ethereum} = (window as any);

            if (!ethereum) {
                setNoMetamask(true);
                dispatch(show({title: "Please install Metamask!", body: currentAccount, type: "error"}))
                return;
            }
            const checkAccounts = await ethereum.request({method: "eth_accounts"});
            const accounts = await ethereum.request({method: "eth_requestAccounts"});
            const chainId = await ethereum.request({method: "eth_chainId"});
            if (chainId === chainID) {
                setCheckNetwork(1);
            }

            setCurrentAccount(accounts[0]);
            dispatch(show({title: "Wallet Connected!", body: currentAccount, type: "success"}))
            localStorage.setItem("account", accounts[0]);

        } catch (error) {
            console.log(error)
        }
    }

    const disconnectWallet = async () => {
        try {
            const {ethereum} = (window as any);
            await ethereum.request({
                method: "eth_requestAccounts",
                params: [{eth_accounts: {}}]
            });
            setCheckNetwork(0);
            dispatch(show({title: "Wallet disconnected!", body: "", type: "warning"}))
            setCurrentAccount('');
            localStorage.removeItem("account");
        } catch
            (error) {
            console.log(error);
        }
    }

    const switchNetwork = async () => {
        try {
            const {ethereum} = (window as any);
            const response = await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [
                    {
                        chainId: chainID, //0x89
                        // chainName: 'Rinkeby Test Network', //Mainnet
                        // rpcUrls: [rpc_url], //https://rpc-mainnet.matic.quiknode.pro
                        // blockExplorerUrls: ['https://rinkeby.etherscan.io/'],//https://polygonscan.com/
                        // nativeCurrency: {
                        //     symbol: 'ETH',
                        //     decimals: 18
                        // }
                    }]
            });
            console.log("response", response);
            setCheckNetwork(1);
            dispatch(show({title: "Wallet Switched!", body: "Ethereum Goerli Testnet", type: "success"}))
        } catch (addError) {
            console.log("network error", addError);
        }
    }

    const {ethereum} = (window as any);
    if (ethereum) {
        ethereum.on('chainChanged', (chainId: any) => {
            if (chainId !== chainID) {
                window.location.reload();
            }
        });
        ethereum.on('accountsChanged', (accounts: any) => {
            localStorage.removeItem("account");
            window.location.reload();
        });
    }

    const add_whitelist = async () => {
        const {ethereum} = (window as any);

        if (ethereum) {
            setWhitelistStatus(true);
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();

            PresalePortal = new ethers.Contract(presale_addr, presale_abi, signer);
            const checkWhitelist = await PresalePortal.whitelists(currentAccount);
            const checkPresaleStart = await PresalePortal.isPresaleGoing();

            console.log("checkwhite list", checkWhitelist);
            if (!checkWhitelist) {
                if (!checkPresaleStart) {
                    let whitelisted = await PresalePortal.addWhitelist();
                    await whitelisted.wait();
                    dispatch(show({
                        title: "Congratulation You are in Whitelist now!",
                        body: currentAccount,
                        type: 'success'
                    }))
                    if (whitelisted) {
                        console.log("here to check whitelist")
                        setCheckWhitelist(true);
                        await getContractData();
                        setWhitelistStatus(false)
                    }
                } else {
                    dispatch(show({
                        title: "You are not able to join whitelist while the presale is live!",
                        body: currentAccount,
                        type: 'error'
                    }))
                    setWhitelistStatus(false)
                }

            } else {
                dispatch(show({title: "You are already in the Whitelist!", body: currentAccount, type: 'error'}))
            }
        }
    }

    const SBC_Purchase_Func = async (amounts: number) => {
        setRejectStatus(0);
        setSbcPurchaseModal(true);
        setPurchaseSensor(false);
        if (approveCheck === 0) {
            await approve_USDC();
        }
        if (approveCheck === 1) {
            await buy_SBC(amounts);
        }
    }

    const approve_USDC = async () => {
        const {ethereum} = (window as any);

        if (ethereum) {
            setApproveStatus(true);
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();

            USDCPortal = new ethers.Contract(usdc_addr, usdc_abi, signer);
            let maxUint = new BigNumber(Number(ethers.constants.MaxUint256)).dividedBy(10 ** 6).toFixed(0);

            try {
                let approved = await USDCPortal.approve(presale_addr, maxUint);
                await approved.wait();
                if (approved) {
                    setApproveStatus(false)
                    setSbcPurchaseModal(true);
                    dispatch(show({
                        title: "USDC Approved!",
                        body: "Transaction Hash : " + approved.hash,
                        type: 'success'
                    }))
                    setApproveCheck(1);
                    localStorage.setItem("approveCheck", "checked")
                    await getContractData();
                }
            } catch (e: any) {
                dispatch(show({
                    title: "Currency Approve Rejected!",
                    body: "You can continue by clicking 'Continue' button",
                    type: 'warning'
                }));
                setRejectStatus(1);
            }

        }
    }

    const buy_SBC = async (fromAmount: number) => {
        const {ethereum} = (window as any);

        if (ethereum) {
            setOrderStatus(true);
            setPurchaseSensor(true);
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            USDCPortal = new ethers.Contract(usdc_addr, usdc_abi, signer);
            PresalePortal = new ethers.Contract(presale_addr, presale_abi, signer);
            let recipients = await PresalePortal.recipients(currentAccount);

            let balanceUSDC_ = await USDCPortal.balanceOf(currentAccount);
            let balanceUSDC = new BigNumber(Number(balanceUSDC_)).dividedBy(10 ** 6).toFixed(0);

            let amountDepositedUSDC = new BigNumber(Number(recipients.amountDepositedUSDC)).dividedBy(10 ** 6).toFixed(0);

            if (Number(fromAmount) >= 250 && Number(fromAmount) <= 25000) {
                if (Number(amountDepositedUSDC) + Number(fromAmount) > 25000) {
                    dispatch(show({
                        title: "Deposit Error!",
                        body: "Deposited Total USDC amount exceed 25000",
                        type: 'error'
                    }))
                    return;
                } else if (Number(balanceUSDC) < Number(fromAmount)) {
                    dispatch(show({
                        title: "Deposit Error!",
                        body: "Inputted amount exceed your USDC balance",
                        type: 'error'
                    }))
                    return;
                }
                try {
                    let Amount = Number(fromAmount);
                    let BigAmount = new BigNumber(Amount).multipliedBy(10 ** 6).toFixed(0);

                    let bought = await PresalePortal.deposit(BigAmount);
                    await bought.wait();
                    setTransactionHash(bought.hash);
                    await getContractData();

                    dispatch(show({
                        title: "Deposit Confirmed!",
                        body: "You bought " + Amount * Number(promiseData[6]) + " $SBC Tokens!",
                        type: 'success'
                    }))
                    setOrderStatus(false);
                    setSbcPurchaseModal(false);
                    setSbcCongratsModal(true);
                } catch (e: any) {
                    dispatch(show({
                        title: "Purchase request rejected!",
                        body: "You can continue by clicking 'Continue' button",
                        type: 'warning'
                    }));
                    setRejectStatus(2);
                }

            } else if (Number(fromAmount) > 0 && Number(fromAmount) < 250) {
                dispatch(show({
                    title: "Deposit Error!",
                    body: "USDC amount should be greater than equal to 250",
                    type: 'error'
                }))
            } else if (Number(fromAmount) > 25000) {
                dispatch(show({title: "Deposit Error!", body: "USDC amount should be less than 25000", type: 'error'}))
            } else {
                dispatch(show({title: "Deposit Error!", body: "Please input correct USDC amount", type: 'error'}))
            }
        }
    }

    const getContractData = async () => {
        const {ethereum} = (window as any);

        if (ethereum) {
            const provider = new ethers.providers.JsonRpcProvider(rpc_url);

            const signer = provider.getSigner(currentAccount ? currentAccount : multiSigAdmin_addr);

            USDCPortal = new ethers.Contract(usdc_addr, usdc_abi, signer);
            SBCPortal = new ethers.Contract(sbc_addr, sbc_abi, signer);
            PresalePortal = new ethers.Contract(presale_addr, presale_abi, signer);
            VestingPortal = new ethers.Contract(vesting_addr, vesting_abi, signer);

            if (currentAccount !== "") {
                const allowance = await USDCPortal.allowance(currentAccount, presale_addr);
                if (allowance > 0) {
                    localStorage.setItem("approveCheck", "checked");
                    setApproveCheck(1);
                } else {
                    localStorage.setItem("approveCheck", "")
                    setApproveCheck(0);
                }
            }

            const promises = [];

            promises.push(PresalePortal.TotalPresaleAmnt());
            promises.push(PresalePortal.soldSBCAmount());
            promises.push(PresalePortal.startTime());
            promises.push(PresalePortal.PERIOD());
            promises.push(VestingPortal.getTGETime());
            promises.push(USDCPortal.balanceOf(multiSigAdmin_addr));
            promises.push(PresalePortal.priceRate());
            promises.push(PresalePortal.MIN_ALLOC_USDC());
            promises.push(VestingPortal.CLIFF_PERIOD());
            promises.push(VestingPortal.TGE_UNLOCK());
            promises.push(VestingPortal.VESTING_PERIOD());

            if (currentAccount !== "") {
                promises.push(SBCPortal.balanceOf(currentAccount));
                promises.push(VestingPortal.getWithdrawable(currentAccount));
                promises.push(VestingPortal.getLocked(currentAccount));
                promises.push(USDCPortal.balanceOf(currentAccount));
                promises.push(USDCPortal.allowance(currentAccount, presale_addr));
                promises.push(PresalePortal.whitelists(currentAccount));
                promises.push(PresalePortal.recipients(currentAccount));
                promises.push(VestingPortal.getVested(currentAccount));
                promises.push(VestingPortal.recipients(currentAccount));
                promises.push(PresalePortal.recipients(currentAccount));
            }

            let temp: any = [];
            Promise.all(promises).then(responses => {
                responses.forEach((response, index) => {
                    temp.push(response);
                })
                temp[0] = new BigNumber(Number(temp[0])).dividedBy(10 ** 18).toFixed(0);
                temp[1] = new BigNumber(Number(temp[1])).dividedBy(10 ** 18).toFixed(0);
                let presaleStart_ = temp[2];
                temp[2] = new Date(presaleStart_ * 1000).toUTCString();
                temp[2] = temp[2].replace('GMT', 'UTC');
                let presaleEnd_ = presaleStart_ * 1000 + temp[3] * 1000;
                temp[3] = new Date(presaleEnd_).toUTCString().replace('GMT', 'UTC');
                let vestingStart_ = temp[4];
                let cliffUntil = vestingStart_ * 1000 + temp[8] * 1000;
                // temp[20] = new Date(cliffUntil).toUTCString().replace('GMT', 'UTC');
                temp[4] = new Date(temp[4] * 1000).toUTCString().replace('GMT', 'UTC');
                temp[5] = new BigNumber(Number(temp[5])).dividedBy(10 ** 6).toFixed(0);
                temp[6] = new BigNumber(Number(temp[6]) * 10).dividedBy(10 ** 1).toFixed(0);
                temp[7] = new BigNumber(Number(temp[7])).dividedBy(10 ** 6).toFixed(0);
                temp[8] = temp[8] / unit;
                temp[9] = new BigNumber(Number(temp[9])).dividedBy(1).toFixed(0);
                temp[10] = new BigNumber(Number(temp[10])).dividedBy(1).toFixed(0);
                if (temp.length > 11) {
                    temp[11] = new BigNumber(Number(temp[11])).dividedBy(10 ** 18).toFixed(0);
                    temp[12] = new BigNumber(Number(temp[12])).dividedBy(10 ** 18).toFixed(0);
                    temp[13] = new BigNumber(Number(temp[13])).dividedBy(10 ** 18).toFixed(0);
                    temp[14] = new BigNumber(Number(temp[14])).dividedBy(10 ** 6).toFixed(2);
                    temp[15] = new BigNumber(Number(temp[15])).dividedBy(10 ** 6).toFixed(0);
                    temp[16] = temp[16];//12
                    temp[17] = new BigNumber(Number(temp[17].amountDepositedUSDC)).dividedBy(10 ** 6).toFixed(0);
                    temp[18] = new BigNumber(Number(temp[18])).dividedBy(10 ** 18).toFixed(0);
                    temp[19] = new BigNumber(Number(temp[19].amountWithdrawn)).dividedBy(10 ** 18).toFixed(0);
                    temp[20] = new BigNumber(Number(temp[20].amountSBC)).dividedBy(10 ** 18).toFixed(0);
                }

                setPromiseData(temp);
                setPresaleStart(Number(presaleStart_) * 1000);
                setIsEnded(Date.now() >= presaleEnd_);
            })
        }
    }
    let isSmallScreen = useMediaQuery("(max-width:500px)");
    const toastStyle = () => {
        if (!isSmallScreen) {
            return {"top": "4.5em", "width": "24em"}
        } else {
            return {}
        }
    }

    return (
        <>
            <ToastContainer
                autoClose={3000}
                closeOnClick
                style={toastStyle()}
            />

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/drops"></Navigate>}></Route>
                    <Route path="/presale-0x6c260a3D7298827C9Af9C1e1C05bb6f0A586896a0x3b78185ac84db47d980081145ccb18b385c12388"
                           element={<Presale currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} connectWallet={connectWallet}
                                             switchNetwork={switchNetwork} getContractData={getContractData}
                                             presaleStart={presaleStart} isEnded={isEnded} promiseData={promiseData}
                                             checkNetwork={checkNetwork} setCheckNetwork={setCheckNetwork} disconnectWallet={disconnectWallet}
                                             whitelistStatus={whitelistStatus} orderStatus={orderStatus}
                                             approveStatus={approveStatus} rejectStatus={rejectStatus}
                                             add_whitelist={add_whitelist} buy_SBC={buy_SBC} approve_USDC={approve_USDC}
                                             approveCheck={approveCheck} checkWhitelist={checkWhitelist}
                                             sbcPurchaseModal={sbcPurchaseModal}
                                             setSbcPurchaseModal={setSbcPurchaseModal}
                                             sbcCongratsModal={sbcCongratsModal} noMetamask={noMetamask}
                                             setSbcCongratsModal={setSbcCongratsModal} transactionHash={transactionHash}
                                             SBC_Purchase_Func={SBC_Purchase_Func} purchaseSensor={purchaseSensor}
                           />}></Route>
                    <Route path="/vesting-0x6c260a3D7298827C9Af9C1e1C05bb6f0A586896a0x3b78185ac84db47d980081145ccb18b385c12388"
                           element={<Vesting currentAccount={currentAccount} connectWallet={connectWallet} setCurrentAccount={setCurrentAccount}
                                             getContractData={getContractData} setCheckNetwork={setCheckNetwork}
                                             switchNetwork={switchNetwork} presaleStart={presaleStart} isEnded={isEnded}
                                             promiseData={promiseData} checkNetwork={checkNetwork}
                                             noMetamask={noMetamask}
                                             disconnectWallet={disconnectWallet}/>}></Route>
                    <Route path="/drops"
                           element={<Drops currentAccount={currentAccount} connectWallet={connectWallet} setCurrentAccount={setCurrentAccount}
                                           switchNetwork={switchNetwork} noMetamask={noMetamask}
                                           promiseData={promiseData} checkNetwork={checkNetwork} setCheckNetwork={setCheckNetwork}
                                           disconnectWallet={disconnectWallet}/>}></Route>
                    <Route path="/assets" element={<Assets currentAccount={currentAccount} connectWallet={connectWallet} setCurrentAccount={setCurrentAccount}
                                                           switchNetwork={switchNetwork} noMetamask={noMetamask}
                                                           promiseData={promiseData} checkNetwork={checkNetwork} setCheckNetwork={setCheckNetwork}
                                                           disconnectWallet={disconnectWallet}/>}></Route>
                    <Route path="/forge" element={<Forge currentAccount={currentAccount} connectWallet={connectWallet} setCurrentAccount={setCurrentAccount}
                                                         switchNetwork={switchNetwork} noMetamask={noMetamask}
                                                         promiseData={promiseData} checkNetwork={checkNetwork} setCheckNetwork={setCheckNetwork}
                                                         disconnectWallet={disconnectWallet}/>}></Route>
                    <Route path={"*"} element={<NotFound currentAccount={currentAccount} connectWallet={connectWallet} setCurrentAccount={setCurrentAccount}
                                                         switchNetwork={switchNetwork} noMetamask={noMetamask}
                                                         promiseData={promiseData} checkNetwork={checkNetwork} setCheckNetwork={setCheckNetwork}
                                                         disconnectWallet={disconnectWallet}/>} ></Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App
