import React, {useEffect, useState} from "react";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import metaMaskIcon from "../../assets/metamask-icon.png";
import {useMediaQuery} from "@mui/material";
import walletIcon from "../../assets/walletIcon.svg";
import maticIcon from "../../assets/Polygon.png";
import NumberFormat from "react-number-format";
import usdcIcon from "../../assets/usdc-unit.png";
import exitIcon from "../../assets/interface-logout--arrow-exit-frame-leave-logout-rectangle-right.png";
import exitIconActive from "../../assets/interface-logout--arrow-exit-frame-leave-logout-rectangle-right-active.png";
import {useAppDispatch} from "../../hooks";
import {ethers} from "ethers";
import {usdc_addr} from "../contractAddress";
import usdc_abi from "../../utils/USDCabi.json";
import BigNumber from "bignumber.js";
import {setBalance} from "../../redux/reducer/wallet";

let USDCPortal;

export const WagmiButton = (props) => {
    let isSmallScreen = useMediaQuery("(max-width:450px)");

    const dispatch = useAppDispatch();

    const [buttonStatus, setButtonStatus] = useState(false);
    const [disconnectBtnStatus, setDisconnectBtnStatus] = useState(false);
    const [usdcBalance, setUsdcBalance] = useState('0.00');

    const buttonHover = () => {
        setButtonStatus(true);
    }
    const buttonOrigin = () => {
        setButtonStatus(false);
    }

    const disconnectBtnHover = () => {
        setDisconnectBtnStatus(true);
    }
    const disconnectBtnOrigin = () => {
        setDisconnectBtnStatus(false);
    }

    const usdcBalanceFetch = async () => {
        const {ethereum} = window;
        if (ethereum) {
            try {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                USDCPortal = new ethers.Contract(usdc_addr, usdc_abi, signer);
                let balanceUSDC_ = await USDCPortal.balanceOf(props.currentAccount);
                let balanceUSDC = new BigNumber(Number(balanceUSDC_)).dividedBy(10 ** 6).toFixed(2);
                setUsdcBalance(balanceUSDC);
            } catch (e) {
                console.log(e);
            }

        }
    }

    useEffect(() => {
        usdcBalanceFetch();
    }, [props.currentAccount])

    return <>
        <ConnectButton.Custom>
            {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === 'authenticated');

                return (
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                props.setCurrentAccount('')
                                return (
                                    <div className={'flex flex-col items-center btn-cover h-16'}>
                                        <div className={'connect-btn-group '}>
                                            <button
                                                onClick={openConnectModal}
                                                className={`wallet-btn nulshock-text text-white ${isSmallScreen ? "px-4" : "px-6 xl:px-8"} py-3 uppercase`}
                                            >
                                                Connect Wallet
                                            </button>
                                            {props.noMetamask && (
                                                <a href={'https://metamask.io/download/'} target={'_blank'}
                                                   className={'simple-text hover:text-white text-lg flex flex-row justify-center items-center gap-2 text-center poppin-text mt-2'}>
                                                    <img
                                                        src={metaMaskIcon} alt="dollarIcon"
                                                        className={"w-4 h-4"}/> Install
                                                    Metamask
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                );
                            }

                            if (chain.unsupported) {
                                props.setCurrentAccount(account.address)
                                props.setCheckNetwork(0)
                                return (
                                    <div className={'flex flex-col items-center btn-cover h-14 sm:h-18'}>
                                        <button
                                            onClick={openChainModal}
                                            className={`switch-btn nulshock-text ${isSmallScreen ? "px-0 " : "px-6 sm:px-10 text-sm xl:px-8"} py-3 w-full uppercase justify-center`}
                                        >
                                            Switch network
                                        </button>
                                    </div>
                                );
                            }
                            props.setCurrentAccount(account.address)
                            localStorage.setItem("account", account.address);
                            props.setCheckNetwork(1)
                            dispatch(setBalance(account.displayBalance));
                            return (
                                <div className={'flex flex-col items-center btn-cover h-16'}>
                                    <div
                                        className={`${isSmallScreen ? 'connected-btn' : 'connected-btn-sm'} w-full flex flex-col items-start gap-y-1 sm:gap-y-2`}
                                        onMouseOver={buttonHover} onMouseLeave={buttonOrigin}>
                                        <button className="flex flex-row justify-between items-center w-full"
                                                disabled={true}>
                                            <div className="flex flex-col">
                                                <p className="text-white text-sm sm:text-lg font-bold nulshock-text">
                                                    {account.displayName}
                                                </p>
                                                <div className="flex items-center gap-x-2">
                                                    <div className="green-dot rounded-full"></div>
                                                    <span
                                                        className="simple-text connected-text nulshock-text uppercase font-bold">
                                            connected
                                        </span>
                                                </div>
                                            </div>
                                            <img src={walletIcon} alt="walletIcon" className="w-9"/>
                                        </button>
                                        {buttonStatus ?
                                            <>
                                                <div className={'flex flex-col justify-center py-1 w-full gap-x-3'}>
                                                    <div
                                                        className={'flex flex-row justify-between items-center text-sm sm:text-base'}>
                                                        <div
                                                            className={'items-center flex flex-row gap-x-1 text-white py-1'}>
                                                            <img src={maticIcon}
                                                                 className={`${isSmallScreen ? 'w-5' : 'w-7'}`}
                                                                 alt={'matic-image'}/>
                                                            MATIC
                                                        </div>
                                                        <span className={'text-white'}>
                                                    <NumberFormat
                                                        value={account.displayBalance
                                                            ? ` (${account.displayBalance})`
                                                            : '0.00'}
                                                        displayType={'text'}
                                                        thousandSeparator={true}/>
                                                    </span>
                                                    </div>
                                                    <div
                                                        className={'flex flex-row justify-between items-center text-sm sm:text-base'}>
                                                        <div
                                                            className={'items-center flex flex-row gap-x-1 text-white py-1'}>
                                                            <img src={usdcIcon}
                                                                 className={`${isSmallScreen ? 'w-5' : 'w-7'}`}
                                                                 alt={'usdc-image'}/>
                                                            USDC
                                                        </div>
                                                        <span className={'text-white'}>
                                                        <NumberFormat
                                                            value={usdcBalance}
                                                            displayType={'text'}
                                                            thousandSeparator={true}/>
                                                    </span>
                                                    </div>
                                                </div>
                                                <button
                                                    className={'simple-text poppin-text flex justify-between text-sm sm:text-[18px] font-semibold items-center w-full hover:text-white gap-x-1'}
                                                    onMouseOver={disconnectBtnHover} onMouseLeave={disconnectBtnOrigin}
                                                    onClick={openAccountModal}>Disconnect Wallet
                                                    <img src={!disconnectBtnStatus ? exitIcon : exitIconActive}
                                                         className={`${isSmallScreen ? 'w-4' : 'w-5'}`}
                                                         alt={'disconnect-btn'}/>
                                                </button>
                                            </>

                                            : <></>
                                        }
                                    </div>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    </>
}