import checkIcon from "../../assets/tick-square.svg";
import React from "react";
import {useAppSelector} from "../../hooks";

export const PassCart = (props) => {
    const selectedItems = useAppSelector(state => state.forge.selectedItems);
    const selectedItemsUri = useAppSelector(state => state.forge.selectedItemsUri);
    const modalState = useAppSelector(state => state.forge.forgeModalState);
    // const imageStyle = {
    //     backgroundImage: url(props.nft.rawMetadata?.image)
    // }
    return <div
        className={`nft-part relative rounded-xl bg-[#1F1822] text-white ${(selectedItems.length > 0 && selectedItems.includes(props.index)) && 'border border-[#A60C53]'} p-3 sm:w-full w-11/12 text-center`}
        onMouseOver={() => props.overPart(props.index)} onMouseLeave={() => props.leavePart()}
        onClick={() => props.addCard(props.index)}
    >
        {/*<img src={props.nft.rawMetadata?.image} className={'w-full sm:h-24 h-32 rounded-lg object-fill object-center'} alt={'nft part'}/>*/}
        <span className={`w-full sm:h-24 sticker-cart h-32 rounded-lg object-fill object-center bg-[#2D2730]`} style={(modalState === 2 && !selectedItems.includes(props.index) && selectedItemsUri.includes(props.index)) ? {backgroundImage: `url(${props.nft.rawMetadata?.image})`, opacity: 0.07} : {backgroundImage: `url(${props.nft.rawMetadata?.image})`}}></span>
        <span
            className={`absolute nft-id-badge bg-[#1F1822] top-0 left-0 px-2 py-1 rounded-xl text-sm`}>#{props.nft.tokenId}</span>
        {(selectedItems.length > 0 && selectedItems.includes(props.index)) &&
        <img src={checkIcon} alt={'selected icon'} className={'absolute top-1 right-1'}/>
        }
        {props.partStatus === props.index &&
        <button className={'items-center text-base py-0.5 mt-3 forge-btn w-full'}>
            {(selectedItems.length > 0 && selectedItems.includes(props.index)) ? 'Remove' : 'Add'}
        </button>
        }

        {props.partStatus !== props.index &&
        <span className={'text-white text-center py-0.5 mt-3 block truncate'}>{props.nft.title}</span>
        }
    </div>
}