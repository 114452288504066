import {faBars, faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMediaQuery} from "@mui/material";
import React, {useEffect, useState} from "react";
import {NavLink, useLocation, useParams} from "react-router-dom";
import logo from "./../../assets/logo.svg";
import "./Navbar.css";
import {ethers} from "ethers";
import BigNumber from "bignumber.js";
import {WagmiButton} from "./wagmiButton";

export const Navbar = (props: any) => {
    let isExtraLargScreen = useMediaQuery("(max-width:1620px)");
    let isLargeScreen = useMediaQuery("(max-width:1100px)");
    let isSmallScreen = useMediaQuery("(max-width:450px)");
    const [showNav, setShowNav] = useState(false);
    const [maticBalance, setMaticBalance] = useState('0.00');

    const currentLocation = useLocation().pathname;

    const polygonBalance = async () => {
        const {ethereum} = (window as any);

        if (ethereum) {
            try {
                const provider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com');
                if (props.currentAccount) {
                    const balance = await provider.getBalance('0xf82618B0a8E62153B1173647bF8FBb7840905EEf');
                    const formattedBalance = new BigNumber(Number(balance)).dividedBy(10 ** 18).toFixed(2);
                    setMaticBalance(formattedBalance);
                }
            } catch (e) {
                console.log(e);
            }

        }
    }

    useEffect(() => {
        polygonBalance();
    }, [props.currentAccount])
    return (
        <div className="flex items-center h-20 w-full z-20 fixed bg-[#120a15]">
            <div className="w-full items-center">
                <div
                    className={`flex items-center justify-between ${
                        isExtraLargScreen ? "px-4 sm:px-14" : "px-32"
                    }`}
                >
                    <div className="flex items-center gap-x-4 sm:gap-x-10 xl:gap-x-16">
                        <button
                            onClick={() => {
                                setShowNav(true);
                            }}
                            className={`${
                                props.currentAccount ? "block lg:hidden" : "block lg:hidden"
                            } text-white text-lg `}
                        >
                            <FontAwesomeIcon icon={faBars}/>
                        </button>
                        <img
                            src={logo}
                            alt="logo"
                            className={`${isLargeScreen ? isSmallScreen ? "w-28" : "w-32 sm:w-40" : "w-48 xl:w-52"}`}
                        />
                        <ul
                            className={`${
                                props.currentAccount
                                    ? "hidden xl:flex items-center gap-x-8"
                                    : "hidden xl:flex items-center gap-x-8"
                            }`}
                        >
                            {/*<li className={`${isLargeScreen ? "text-sm" : ""} font-bold`}>*/}
                            {/*    <NavLink*/}
                            {/*        className={({isActive}) =>*/}
                            {/*            [*/}
                            {/*                "border-b-2 border-pink-700 uppercase",*/}
                            {/*                isActive*/}
                            {/*                    ? "border-opacity-100 text-white"*/}
                            {/*                    : "border-opacity-0 hover:border-opacity-100",*/}
                            {/*            ]*/}
                            {/*                .filter(Boolean)*/}
                            {/*                .join(" ")*/}
                            {/*        }*/}
                            {/*        to={"/presale"}*/}
                            {/*    >*/}
                            {/*        Presale*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                            {/*<li className={`${isLargeScreen ? "text-sm" : ""} font-bold`}>*/}
                            {/*    <NavLink*/}
                            {/*        className={({isActive}) =>*/}
                            {/*            [*/}
                            {/*                "border-b-2 border-pink-700 uppercase",*/}
                            {/*                isActive*/}
                            {/*                    ? "border-opacity-100 text-white"*/}
                            {/*                    : "border-opacity-0 hover:border-opacity-100",*/}
                            {/*            ]*/}
                            {/*                .filter(Boolean)*/}
                            {/*                .join(" ")*/}
                            {/*        }*/}
                            {/*        to={"/vesting"}*/}
                            {/*    >*/}
                            {/*        Vesting*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                            <li className={`${isLargeScreen ? "text-sm" : ""} font-bold`}>
                                <NavLink
                                    className={({isActive}) =>
                                        [
                                            "border-b-2 border-pink-700 uppercase",
                                            isActive
                                                ? "border-opacity-100  text-white"
                                                : "border-opacity-0 hover:border-opacity-100",
                                        ]
                                            .filter(Boolean)
                                            .join(" ")
                                    }
                                    to={"/drops"}

                                >
                                    {" "}
                                    Drops
                                </NavLink>
                            </li>
                            <li className={`${isLargeScreen ? "text-sm" : ""} font-bold`}>
                                <NavLink
                                    className={({isActive}) =>
                                        [
                                            "border-b-2 border-pink-700 uppercase",
                                            isActive
                                                ? "border-opacity-100 text-white"
                                                : "border-opacity-0 hover:border-opacity-100",
                                        ]
                                            .filter(Boolean)
                                            .join(" ")
                                    }
                                    to={"/assets"}
                                >
                                    Assets
                                </NavLink>
                            </li>
                            <li className={`${isLargeScreen ? "text-sm" : ""} font-bold`}>
                                <NavLink
                                    className={({isActive}) =>
                                        [
                                            "border-b-2 border-pink-700 uppercase",
                                            isActive
                                                ? "border-opacity-100 text-white"
                                                : "border-opacity-0 hover:border-opacity-100",
                                        ]
                                            .filter(Boolean)
                                            .join(" ")
                                    }
                                    to={"/forge"}
                                >
                                    Forge
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <WagmiButton noMetamask={props.noMetamask}
                                 currentAccount={props.currentAccount}
                                 setCurrentAccount={props.setCurrentAccount}
                                 setCheckNetwork={props.setCheckNetwork}
                                 promiseData={props.promiseData}/>
                </div>
                {/* </Container> */}
                <div
                    className={`${
                        showNav ? "top-0" : "-top-full"
                    } fixed duration-500 flex items-center justify-center small-nav z-50 w-full h-full left-0 right-0 px-10`}
                >
                    <button
                        onClick={() => {
                            setShowNav(false);
                        }}
                        className="absolute top-5 right-5"
                    >
                        <FontAwesomeIcon icon={faClose} className="text-white"/>
                    </button>
                    <ul className="flex flex-col items-center gap-y-8">
                        {/*<li className={`text-md font-bold`}>*/}
                        {/*    <NavLink*/}
                        {/*        className={({isActive}) =>*/}
                        {/*            [*/}
                        {/*                "border-b-2 border-pink-700 uppercase",*/}
                        {/*                isActive*/}
                        {/*                    ? "border-opacity-100 text-white"*/}
                        {/*                    : "border-opacity-0 hover:border-opacity-100",*/}
                        {/*            ]*/}
                        {/*                .filter(Boolean)*/}
                        {/*                .join(" ")*/}
                        {/*        }*/}
                        {/*        to={"/presale"}*/}
                        {/*    >*/}
                        {/*        Presale*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        {/*<li className={`text-md font-bold`}>*/}
                        {/*    <NavLink*/}
                        {/*        className={({isActive}) =>*/}
                        {/*            [*/}
                        {/*                "border-b-2 border-pink-700 uppercase",*/}
                        {/*                isActive*/}
                        {/*                    ? "border-opacity-100  text-white"*/}
                        {/*                    : "border-opacity-0 hover:border-opacity-100",*/}
                        {/*            ]*/}
                        {/*                .filter(Boolean)*/}
                        {/*                .join(" ")*/}
                        {/*        }*/}
                        {/*        to={"/vesting"}*/}
                        {/*    >*/}
                        {/*        Vesting*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        <li className={`text-md font-bold`}>
                            <NavLink
                                className={({isActive}) =>
                                    [
                                        "border-b-2 border-pink-700 uppercase",
                                        isActive
                                            ? "border-opacity-100  text-white"
                                            : "border-opacity-0 hover:border-opacity-100",
                                    ]
                                        .filter(Boolean)
                                        .join(" ")
                                }
                                to={"/drops"}
                            >
                                {" "}
                                Drops
                            </NavLink>
                        </li>
                        <li className={`text-md font-bold`}>
                            <NavLink
                                className={({isActive}) =>
                                    [
                                        "border-b-2 border-pink-700 uppercase",
                                        isActive
                                            ? "border-opacity-100  text-white"
                                            : "border-opacity-0 hover:border-opacity-100",
                                    ]
                                        .filter(Boolean)
                                        .join(" ")
                                }
                                to={"/assets"}
                            >
                                Assets
                            </NavLink>
                        </li>
                        <li className={`text-md font-bold`}>
                            <NavLink
                                className={({isActive}) =>
                                    [
                                        "border-b-2 border-pink-700 uppercase",
                                        isActive
                                            ? "border-opacity-100  text-white"
                                            : "border-opacity-0 hover:border-opacity-100",
                                    ]
                                        .filter(Boolean)
                                        .join(" ")
                                }
                                to={"/forge"}
                            >
                                Forge
                            </NavLink>
                        </li>
                        {/*    {(!currentLocation.includes('drops') || !currentLocation.includes('assets') || !currentLocation.includes('forge')) &&*/}
                        {/*    (*/}
                        {/*        <li>*/}
                        {/*            <div*/}
                        {/*                className="flex items-center justify-center flex-wrap sm:flex-nowrap gap-y-6 sm:gap-y-0 gap-x-10 2xl:gap-x-14">*/}
                        {/*                <div className="flex items-center gap-x-2">*/}
                        {/*                    <img src={angleIcon} alt="angleIcon" className="w-10"/>*/}
                        {/*                    <p className="font-bold text-white text-md nulshock-text">*/}
                        {/*<span className="simple-text nulshock-text text-sm font-bold">*/}
                        {/*  $SBC*/}
                        {/*</span>*/}
                        {/*                        <br/><NumberFormat value={props.promiseData[11]} displayType={'text'}*/}
                        {/*                                           thousandSeparator={true}/>*/}
                        {/*                    </p>*/}
                        {/*                </div>*/}
                        {/*                <div*/}
                        {/*                    className="flex items-center gap-x-2"*/}
                        {/*                >*/}
                        {/*                    <img src={sbcBlackIcon} alt="sbcBlackIcon" className="w-10"/>*/}
                        {/*                    <p className="font-bold text-white text-md nulshock-text">*/}
                        {/*<span className="simple-text nulshock-text text-sm font-bold">*/}
                        {/*  <small>v</small>SBC(<small>Vesting</small>)*/}
                        {/*</span>*/}
                        {/*                        <br/><NumberFormat*/}
                        {/*                        value={Number(props.promiseData[17]) * Number(props.promiseData[6]) - Number(props.promiseData[19])}*/}
                        {/*                        displayType={'text'}*/}
                        {/*                        thousandSeparator={true}/>*/}
                        {/*                    </p>*/}
                        {/*                </div>*/}
                        {/*                <div*/}
                        {/*                    className="flex items-center gap-x-2"*/}
                        {/*                >*/}
                        {/*                    <img src={boltIcon} alt="boltIcon" className="w-10"/>*/}
                        {/*                    <p className="font-bold text-white text-md nulshock-text">*/}
                        {/*<span className="simple-text nulshock-text text-sm font-bold">*/}
                        {/*  $BOLT*/}
                        {/*</span>*/}
                        {/*                        <br/><NumberFormat value={0} displayType={'text'} thousandSeparator={true}/>*/}
                        {/*                    </p>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </li>*/}
                        {/*    )}*/}
                    </ul>
                </div>
            </div>
        </div>
    );
};
